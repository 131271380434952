import React from 'react'
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import IconButton from '@mui/material/IconButton';
import PreviewIcon from '@mui/icons-material/Preview';

export default function AssetsData(props) {
	return (
		<TableRow>
			<TableCell>
				<div className="cell-title">{props.data.item_name}</div>
				{(props.data.user_inventory.inventory.search_base === 'Villages')?(
					<div className="cell-desc">{props.data.ms_village.ms_village_name}, Kec {props.data.ms_district.ms_district_name}, {props.data.ms_city.ms_city_name}, {props.data.ms_province.ms_province_name}</div>
				):(props.data.user_inventory.inventory.search_base === 'City')?(
					<div className="cell-desc">{props.data.ms_city.ms_city_name}, {props.data.ms_province.ms_province_name}</div>
				):(
					<div className="cell-desc">{props.data.social_account}</div>
				)}
				
			</TableCell>
			<TableCell>
				<div className="cell-normal">{props.data.user_inventory.user.fullname}</div>
			</TableCell>
			<TableCell>
			<div className="cell-normal">{props.data.user_inventory.inventory.inventory}</div>
				
			</TableCell>
			<TableCell>
				<div className={(props.data.status==='validate')?`active-status`:(props.data.status==='waiting')?(`waiting-status`):`not-active-status`}>
					{props.data.status}
				</div></TableCell>
			
			<TableCell>
				
				<IconButton 
					aria-label="edit" 
					size="small"
					onClick={()=> window.location.href=`asset/edit/${props.data.user_inventory_item_id}`}
				>
					<EditIcon fontSize="inherit" />
				</IconButton>

				<IconButton 
					aria-label="view" 
					size="small"
					onClick={()=>props.onViewItem(props.data.user_inventory_item_id)}
				>
					<PreviewIcon fontSize="inherit" />
				</IconButton>
					
			</TableCell>

		</TableRow>
	)
}
