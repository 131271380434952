import React, { Component } from 'react'

export default class FilterCompany extends Component {

	constructor(props) {
		super(props);
		this.state = {
			company : '',
			email: ''
		}
	}
	handleResetFilter = () => {
		this.setState({
			email : '',
			company: ''
		},()=>{
			this.props.onUpdateFilter(this.state)
		})


	}

	handleChangeFilter = (event) => {
		
		this.setState({
			[event.target.name]:event.target.value
		})
	}



	handleApplyFilter = () => {
		this.props.onUpdateFilter(this.state)
	}

	render() {
		return (
			<React.Fragment>
				<div className="btn btn-custom btn-active-white btn-flex btn-color-white btn-active-color-primary show menu-dropdown" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">
					Filter
				</div>

				<div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px " data-kt-menu="true" id="kt_menu_613570945bcd4" style={{zIndex: 105, position: "fixed", inset: "0px auto auto 0px", margin:"0px", }} data-select2-id="select2-data-kt_menu_613570945bcd4" data-popper-placement="bottom-end">
					{/* <!--begin::Header--> */}
					<div className="px-7 py-5">
						<div className="fs-5 text-dark fw-bolder">Filter Options</div>
					</div>
					{/* <!--end::Header--> */}
					{/* <!--begin::Menu separator--> */}
					<div className="separator border-gray-200"></div>
					{/* <!--end::Menu separator--> */}
						{/* <!--begin::Form--> */}
						<div className="px-7 py-5">
							<div className="mb-10">
								<label className="form-label fw-bold">Email: (like)</label>
								<input type="text" className="form-control form-control-solid" placeholder="Email" name="email" value={this.state.email} onChange={this.handleChangeFilter}/>
							</div>

							<div className="mb-10">
								<label className="form-label fw-bold">Company: (like)</label>
								<input type="text" className="form-control form-control-solid" placeholder="Company" name="company" value={this.state.company} onChange={this.handleChangeFilter}/>
							</div>

							
							
							{/* <!--begin::Actions--> */}
							<div className="d-flex justify-content-end">
								<button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" onClick={this.handleResetFilter}>Reset</button>
								<button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true" onClick={this.handleApplyFilter}>Apply</button>
							</div>
							{/* <!--end::Actions--> */}
						</div>
					{/* <!--end::Form--> */}
				</div>
			</React.Fragment>
		)
	}
}

