import React, { Component } from 'react';
import AuthToolbar from '../Layout/AuthToolbar';
import InventoriesData from './InventoriesData';
import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';
import {CRUD} from './InventoryModal';
import {CategoryModal} from './CategoryModal';
import {ProfileModal} from './ProfileModal';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {dataURLtoFile} from '../Helper/Helper';
import axios from 'axios'

const breadcumb = [
	{
		item: 'Home',
		path: '/'
	},
	{
		item: 'Settings',
		path: ''
	},
	{
		item: 'Inventories',
		path: ''
	}
];

const search_base = ['Villages','City','Social Media Account'];

export default class InventoryContent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			inventories: [],
			inventory: {
				inventory_id:'',
				inventory: '',
				inventory_app: '',
				asset_label: '',
				search_base: 'Villages',
				profile: [],
				inventory_category: []

			},
			action: "",
			showInventoryModal: false,

			inventory_category: {
				inventory_id: '',
				inventory_category_id: '',
				category: '',
				icon: null,
				changeIcon : null,
			},
			action_category: "",
			showCategoryModal: false,

			inventory_profile: {
				inventory_profile_id: '',
				inventory_id: '',
				profile: '',
				profile_name: '',
				profile_desc: ''
			},
			action_profile: '',
			showProfileModal: false,
		}
	}

	
	
	handleDelete = (id) =>{
		// console.log(id)
		Swal.fire({
			title: 'Delete',
			text: 'Do you want to continue',
			icon: 'error',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Delete',
			confirmButtonColor: '#fc0000'
		}).then(async (result)=>{
			if(result.isConfirmed){

				const requestBody = {
					inventory_id: id,
				};
		
				const params = {
					reqBody: requestBody,
					method: API.INVENTORIES_DELETE.type,
					url: API.INVENTORIES_DELETE.url,
					access: 'auth' 
				}
		
				const response = await Api(params)
				if(response.status){
					
					this.reloadContentData()
				}

				


			}
		})

	}	

	handleChangeInventory = (event) => {
		// console.log(event.target.name+'='+event.target.value)
		// console.log(`Current state is ${this.state}`)
		const newVal = {
			inventory: {
				inventory_id: this.state.inventory.inventory_id,
				inventory: (event.target.name === 'inventory')?event.target.value:this.state.inventory.inventory,
				inventory_app: (event.target.name === 'inventory_app')?event.target.value:this.state.inventory.inventory_app,
				asset_label: (event.target.name === 'asset_label')?event.target.value:this.state.inventory.asset_label,
				profile: this.state.inventory.profile,
				inventory_category: this.state.inventory.inventory_category,
				search_base: (event.target.name === 'search_base')?event.target.value:this.state.inventory.search_base,
			}
		}
		this.setState(newVal)

		// console.log(this.state)
	}

	handleCloseInventoryModal = () => {
		this.setState({
			showInventoryModal: false,
		})
	}

	handleNewInventory = () => {
		const newData = {
			inventory: {
				inventory_id:'',
				inventory: '',
				inventory_app: '',
				asset_label: '',
				search_base: 'Village',
				profile: [],
				inventory_category: []
			},
			action: "NEW",
			showInventoryModal: true,
			
		}

		this.setState(newData)
	}
	

	handleEdit = async (id) =>{
		// console.log(id)
		const requestBody = {
			inventory_id: id
		};

		const params = {
			reqBody: requestBody,
			method: API.INVENTORIES_GET.type,
			url: API.INVENTORIES_GET.url,
			access: 'auth' 
		}
		const response = await Api(params)
		if(response.status){
			this.setState({
				inventory:response.data,
				showInventoryModal: true
			})
		}
		
		
		// console.log(this.state)
	}

	handleSaveInventory = async (event)=>{
		
		if(this.state.action === 'NEW'){
			const requestBody = {
				inventory: this.state.inventory.inventory,
				inventory_app: this.state.inventory.inventory_app,
				asset_label: this.state.inventory.asset_label,
				search_base: this.state.inventory.search_base,
			}
			
				
			const params = {
				reqBody: requestBody,
				method: API.INVENTORIES_CREATE.type,
				url: API.INVENTORIES_CREATE.url,
				access: 'auth' 
			}
	
			const response = await Api(params)
			if(response.status){
				this.reloadContentData()
				this.handleCloseInventoryModal()
			}
			// console.log(response)
			// if(response.status){
				
			// 	this.reloadContentData()
			// }
			

		}else{
			const requestBody = {
				inventory_id: this.state.inventory.inventory_id,
				inventory: this.state.inventory.inventory,
				inventory_app: this.state.inventory.inventory_app,
				asset_label: this.state.inventory.asset_label, 
				search_base: this.state.inventory.search_base, 
			}
			const params = {
				reqBody: requestBody,
				method: API.INVENTORIES_UPDATE.type,
				url: API.INVENTORIES_UPDATE.url,
				access: 'auth',
				id: this.state.inventory.inventory_id,
			}
			const response = await Api(params)
			if(response.status){
					this.reloadContentData()
					this.handleCloseInventoryModal()
			}
		}
	}

		
	

	componentDidUpdate(){

	}

	componentWillUnmount(){

	}
	componentDidMount() {
		this.reloadContentData()
		

	}

	reloadContentData = async () => {
		// console.log(this.state)
		const requestBody = {
			page: 1,
			rows: 100
		};

		const params = {
			reqBody: requestBody,
			method: API.INVENTORIES_GETS.type,
			url: API.INVENTORIES_GETS.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			
			this.setState({
				inventories: response.data.data
			})
		}
		

        
	}

	handleNewCategory = async (id) => {
		const newData = {
			inventory_category: {
				inventory_id: id,
				inventory_category_id: '',
				category: '',
				icon: null,
				changeIcon: null
			},
			action_category: "NEW",
			showCategoryModal: true,
			
		}

		this.setState(newData)




	}

	handleCloseCategoryModal = async () => {
		this.setState({showCategoryModal: false})
	}

	
	handleInventoryCategoryChange = async (val) =>{
		this.setState({
			inventory_category: {
				inventory_category_id: this.state.inventory_category.inventory_category_id,
				inventory_id: this.state.inventory_category.inventory_id,
				category: val.category,
				icon: this.state.inventory_category.icon,
				changeIcon: val.changeIcon

			}
		})
		
	}

	handleSaveCategoryBtn = async (access) => {

		let requestBody;
		let params;
		let response;

		if(access){
			if(this.state.inventory_category.changeIcon !== null){
				// upload here
				const file = dataURLtoFile(this.state.inventory_category.changeIcon.data_url,this.state.inventory_category.changeIcon.file.name)
				const data = new FormData()
				data.append('file', file, file.name);
				data.append('email', process.env.REACT_APP_CDN_USERNAME);
				data.append('password',process.env.REACT_APP_CDN_PASSWORD);
				const config = {
					headers: { 'Content-Type': 'multipart/form-data' }
				}

				try {
					response = await axios.post(process.env.REACT_APP_CDN_URL, data, config)
					// console.log('response')
					// console.log(response)
					if(response.data.status){
						// console.log('status ok')
						// console.log(response.data.data.url)
						await this.setState({
							inventory_category: {
								inventory_category_id: this.state.inventory_category.inventory_category_id,
								inventory_id: this.state.inventory_category.inventory_id,
								category: this.state.inventory_category.category,
								icon: response.data.data.url,
								changeIcon: this.state.inventory_category.changeIcon,
							}
							
						})
					}
				} catch (error) {
					Swal.fire({
						icon: 'error',
						text: error.message,
					})
				}
			}
		}

		switch (this.state.action_category) {
			case 'NEW':
				requestBody = {
					inventory_id: this.state.inventory_category.inventory_id,
					category: this.state.inventory_category.category,
					icon: this.state.inventory_category.icon,
				};

				

				params = {
					reqBody: requestBody,
					method: API.INVETORY_CATEGORIES_CREATE.type,
					url: API.INVETORY_CATEGORIES_CREATE.url,
					access: 'auth' 
				}
		
				response = await Api(params)
				if(response.status){
					
					this.reloadContentData()
					this.setState({
						showCategoryModal: false,
					})
				}

				break;
			case 'EDIT':
				requestBody = {
					inventory_category_id: this.state.inventory_category.inventory_category_id,
					category: this.state.inventory_category.category,
					icon: this.state.inventory_category.icon,
				};

				

				params = {
					reqBody: requestBody,
					method: API.INVETORY_CATEGORIES_UPDATE.type,
					url: API.INVETORY_CATEGORIES_UPDATE.url,
					access: 'auth' 
				}
		
				response = await Api(params)
				if(response.status){
					
					this.reloadContentData()
					this.setState({
						showCategoryModal: false,
					})
				}
				break;
			default:
				break;
		}
	}

	handleCategoryDelete = async (id) => {
		Swal.fire({
			title: 'Delete Category',
			text: 'Do you want to continue',
			icon: 'error',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Delete',
			confirmButtonColor: '#fc0000'
		}).then(async (result)=>{
			if(result.isConfirmed){

				const requestBody = {
					inventory_category_id: id,
				};
		
				const params = {
					reqBody: requestBody,
					method: API.INVETORY_CATEGORIES_DELETE.type,
					url: API.INVETORY_CATEGORIES_DELETE.url,
					access: 'auth' 
				}
		
				const response = await Api(params)
				if(response.status){
					
					this.reloadContentData()
				}

				


			}
		})
	}


	handleCategoryEdit = async(id) => {
		const requestBody = {
			inventory_category_id: id,
		};

		const params = {
			reqBody: requestBody,
			method: API.INVETORY_CATEGORIES_GET.type,
			url: API.INVETORY_CATEGORIES_GET.url,
			access: 'auth' 
		}

		const response = await Api(params)
		
		if(response.status){

			this.setState({
				inventory_category: {
					inventory_category_id: response.data.inventory_category_id,
					category: response.data.category,
					icon: response.data.icon,
					inventory_id: response.data.inventory_id
				},
				action_category: "EDIT",
				showCategoryModal: true,

			})	
		}
		
	}

	handleNewProfile = async (id) => {
		
		const newData = {
			inventory_profile: {
				inventory_id: id,
				inventory_profile_id: '',
				profile: '',
				profile_name: '',
				profile_desc: ''
			},
			action_profile: "NEW",
			showProfileModal: true,
			
		}

		this.setState(newData)
	}

	handleCloseProfileModal = () => {
		this.setState({
			showProfileModal: false,
		})
	}

	handleInventoryProfileChange = (event) =>{
		let a = this.state.inventory_profile;
		a[event.target.name]=event.target.value
		this.setState({inventory_profile: a})
		// console.log(event.target)	
		// this.setState({
			
			// inventory_profile: {
			// 	inventory_id: this.state.inventory_profile.inventory_id,
			// 	inventory_profile_id: this.state.inventory_profile.inventory_profile_id,
			// 	profile: (event.target.name === 'profile')?event.target.value:this.state.inventory_profile.profile,
			// 	profile_name: (event.target.name === 'profile_name')?event.target.value:this.state.inventory_profile.profile_name,
			// 	profile_desc: (event.target.name === 'profile_desc')?event.target.value:this.state.inventory_profile.profile_desc
			// }
			
		// },()=>{
		// 	console.log(this.state.inventory_profile)
		// })
	}

	handleSaveProfileBtn = async () =>{
		// alert(this.state.action_profile)
		let requestBody;
		let params;
		let response;

		switch (this.state.action_profile) {
			case 'NEW':
				requestBody = {
					inventory_id: this.state.inventory_profile.inventory_id,
					profile: this.state.inventory_profile.profile,
					profile_name: this.state.inventory_profile.profile_name,
					profile_desc: this.state.inventory_profile.profile_desc,
				};

				

				params = {
					reqBody: requestBody,
					method: API.INVETORY_PROFILE_CREATE.type,
					url: API.INVETORY_PROFILE_CREATE.url,
					access: 'auth' 
				}
		
				response = await Api(params)
				if(response.status){
					
					this.reloadContentData()
					this.setState({
						showProfileModal: false,
					})
				}
				
				break;
			case 'EDIT':
				requestBody = {
					inventory_profile_id: this.state.inventory_profile.inventory_profile_id,
					profile: this.state.inventory_profile.profile,
					profile_name: this.state.inventory_profile.profile_name,
					profile_desc: this.state.inventory_profile.profile_desc,
				};

				

				params = {
					reqBody: requestBody,
					method: API.INVETORY_PROFILE_UPDATE.type,
					url: API.INVETORY_PROFILE_UPDATE.url,
					access: 'auth' 
				}
		
				response = await Api(params)
				if(response.status){
					
					this.reloadContentData()
					this.setState({
						showProfileModal: false,
					})
				}
				break;
			default:
				break;
		}
	}

	handleProfileDelete = async (id) =>{
		Swal.fire({
			title: 'Delete Profile',
			text: 'Do you want to continue',
			icon: 'error',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Delete',
			confirmButtonColor: '#fc0000'
		}).then(async (result)=>{
			if(result.isConfirmed){

				const requestBody = {
					inventory_profile_id: id,
				};
		
				const params = {
					reqBody: requestBody,
					method: API.INVETORY_PROFILE_DELETE.type,
					url: API.INVETORY_PROFILE_DELETE.url,
					access: 'auth' 
				}
		
				const response = await Api(params)
				if(response.status){
					
					this.reloadContentData()
				}

				


			}
		})
	}

	handleProfileEdit = async (id) => {
		const requestBody = {
			inventory_profile_id: id,
		};

		const params = {
			reqBody: requestBody,
			method: API.INVETORY_PROFILE_GET.type,
			url: API.INVETORY_PROFILE_GET.url,
			access: 'auth' 
		}

		const response = await Api(params)
		// console.log(response)
		if(response.status){

			this.setState({
				inventory_profile: {
					inventory_profile_id: response.data.inventory_profile_id,
					profile: response.data.profile,

					profile_name: response.data.profile_name,
					profile_desc: response.data.profile_desc,
					inventory_id: response.data.inventory_id
				},
				action_profile: "EDIT",
				showProfileModal: true,

			})	
		}
	}

	render() {
		return (
			<React.Fragment>
				<AuthToolbar 
					title='Inventories'
					breadcumb = {breadcumb}
				/>

				<div className="container-xxl" style={{marginBottom: 20}}>
					{/* <Button onClick={this.handleNewInventory()}>Create New</Button> */}
					<button type="button" className="btn btn-primary btn-sm btn-light" style={{marginRight:10}} 
						onClick={()=>this.handleNewInventory()}
					>
                		Create New
            		</button> 
				</div>

				

				<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
					<div className="content flex-row-fluid" id="kt_content">
						<div className="row gy-5 g-xl-8">
							<div className="col-xl-12">
								{
									this.state.inventories.map((data,i)=> {
										return ( 
											<InventoriesData 
												inventory_id={data.inventory_id}
												inventory={data.inventory} 
												inventory_app={data.inventory_app} 
												inventory_category = {data.inventory_category}
												search_base = {data.search_base}
												profile = {data.profile}
												key={data.inventory_id} 
												remove={()=>{this.handleDelete(data.inventory_id)}}
												edit={()=>this.handleEdit(data.inventory_id)}
												newCategory={()=>this.handleNewCategory(data.inventory_id)}
												newProfile={()=>this.handleNewProfile(data.inventory_id)}
												onCategoryDeleteAction={(value)=>this.handleCategoryDelete(value)}
												onCategoryEditAction = {(value)=>this.handleCategoryEdit(value)}
												onProfileDeleteAction={(value)=>this.handleProfileDelete(value)}
												onProfileEditAction = {(value)=>this.handleProfileEdit(value)}
											/>
										)
									})
								}
							</div>
						</div>

						
					</div>

                

                

            	</div>

			
				<CRUD  
					inventory={this.state.inventory}  
					changeInventory={this.handleChangeInventory}
					saveInventory={this.handleSaveInventory} 
					search_list={search_base} 
					open={this.state.showInventoryModal}
					closeInventoryModal = {this.handleCloseInventoryModal}
				/>

				<CategoryModal 
					open={this.state.showCategoryModal}
					closeCategoryModal = {this.handleCloseCategoryModal}
					inventory_category = {this.state.inventory_category}
					onInventoryCategoryChange = {(value)=>this.handleInventoryCategoryChange(value)}
					onSaveBtn = {(value) =>this.handleSaveCategoryBtn(value)}
					mode={this.state.action_category}
				/>

				<ProfileModal 
					open={this.state.showProfileModal}
					closeProfileModal = {this.handleCloseProfileModal}
					inventory_profile = {this.state.inventory_profile}
					onInventoryProfileChange = {this.handleInventoryProfileChange}
					onSaveBtn = {this.handleSaveProfileBtn}
					mode={this.state.action_category}
				/>	

			</React.Fragment>

			
		)
	}
}
