import React, { Component } from 'react'

export default class Footer extends Component {

	render() {
		const kres = '#';
		const mailto = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`;
		const about = '/about';
		return (
			<div className="d-flex flex-center flex-column-auto p-10">
				<div className="d-flex align-items-center fw-bold fs-6">
					<a
						href= {kres}
						className="text-muted text-hover-primary px-2"
					>
						About
					</a>
					<a
						href={mailto}
						className="text-muted text-hover-primary px-2"
					>
						Contact
					</a>
					<a
						href={about}
						className="text-muted text-hover-primary px-2"
					>
						Contact Us
					</a>
				</div>
			</div>
		)
	}
}
