import React from 'react'


export default function AuthFooter() {
	const bottomStyle = {
		position : 'absolute',
    	bottom : 0
	}

	return (
		<div className="footer py-4 d-flex flex-lg-column" id="kt_footer" style={bottomStyle}>
		{/* <!--begin::Container--> */}
		<div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
			{/* <!--begin::Copyright--> */}
			<div className="text-dark order-2 order-md-1">
				<span className="text-muted fw-bold me-1">2021©</span>
				<a href="/" target="_blank" className="text-gray-800 text-hover-primary">Exel Integrasi Solusindo</a>
			</div>
			{/* <!--end::Copyright--> */}
			{/* <!--begin::Menu--> */}
			<ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
				<li className="menu-item">
					<a href="/about" target="_blank" className="menu-link px-2">About</a>
				</li>
				<li className="menu-item">
					<a href="/support" target="_blank" className="menu-link px-2">Support</a>
				</li>
			</ul>
			{/* <!--end::Menu--> */}
		</div>
		{/* <!--end::Container--> */}
	</div>
	)
}
