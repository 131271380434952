import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

export default class UserData extends Component {

	Item = styled(Paper)(({ theme }) => ({
		...theme.typography.body2,
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	}));
		  
	render() {
		return (
			<React.Fragment>
				<div className="card" style={{marginBottom:20}} >
					<div className="card-header card-header-stretch">
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label fw-bolder text-dark">{this.props.data.fullname}</span>
							<span className="text-muted mt-1 fw-bold fs-7">{this.props.data.email}</span>
							
						</h3>
						
					</div>
					<div className="card-body">
						<Grid container spacing={3}>
						{/* <div class="d-flex flex-row flex-column-fluid"> */}
							{/* <div class="d-flex flex-row-fluid flex-center">
                				<span class="text-black">User Type : {(this.props.data.is_admin==='true')?`Administrator`:'Publisher'}</span>
            				</div>
							<div class="d-flex flex-row-fluid flex-center">
                				<span class="text-black">Status : {this.props.data.status}</span>
            				</div>
							<div class="d-flex flex-row-fluid flex-center">
                				<span class="text-black">Company : {(this.props.data.company===null)?`-`:this.props.data.company.company}</span>
            				</div>
							<div class="d-flex flex-row-fluid flex-center">
                				<span class="text-black">Group : {(this.props.data.group===null)?`-`:this.props.data.group.group}</span>
            				</div> */}
							<Grid item xs>
          						<Item>User Type : {(this.props.data.is_admin==='true')?`Administrator`:'Publisher'}</Item>
        					</Grid>
							<Grid item xs>
          						<Item>Status : {this.props.data.status}</Item>
        					</Grid>
							<Grid item xs>
          						<Item>Group : {(this.props.data.group===null)?`-`:this.props.data.group.group}</Item>
        					</Grid>

							<Grid item xs>
          						<Item>company : {(this.props.data.company===null)?`-`:this.props.data.company.company}</Item>
        					</Grid>


						{/* </div> */}
						</Grid>
						
						
						
					</div>
					<div className="card-footer">
						<button className="btn btn-primary btn-sm btn-light" onClick={this.props.editUser} style={{marginRight:10}} >Edit</button>
						<button className="btn btn-success btn-sm btn-light" onClick={this.props.changePassword} style={{marginRight:10}}>Change Password</button>
						{(this.props.data.is_admin==='true')?(<button className="btn btn-info btn-sm btn-light" >Group</button>):``}
    				</div>
					
				</div>
			</React.Fragment>
		)
	}
}
