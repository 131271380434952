import React from 'react'
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import IconButton from '@mui/material/IconButton';
import PreviewIcon from '@mui/icons-material/Preview';
import Tooltip from '@mui/material/Tooltip';

export default function CampaignData(props) {
	return (
		<TableRow>
			<TableCell>
				<div className="cell-normal">{props.data.project_name}</div>
				
			</TableCell>
			<TableCell>
				<div className="cell-normal">{props?.data?.partner?.partner || ``}</div>
			</TableCell>
			<TableCell>
				<div className="cell-normal">{props?.data?.user_products?.user_inventory_item?.user_inventory?.user?.fullname || ``}</div>
			</TableCell>
				
			<TableCell>
				<div className="cell-normal">{props?.data?.status || ``}</div>
				
			</TableCell>
			<TableCell>
				<div className="cell-normal">{props?.data?.user_products?.user_inventory_item?.user_inventory?.inventory?.inventory || ``}</div>
				
			</TableCell>
				
			<TableCell>
				<Tooltip title="Detail campaign">
					<IconButton 
						aria-label="view" 
						size="small"
						onClick={()=>props.onViewCampaign(props.data.campaign_id)}
					>
						<PreviewIcon fontSize="inherit" />
					</IconButton>

					
				</Tooltip>
					
			</TableCell>
		</TableRow>
		
	)
}
