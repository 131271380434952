import React, { Component } from 'react'

export default class CompanyData extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="card" style={{marginBottom:20}} >
					<div className="card-header card-header-stretch">
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label fw-bolder text-dark">{this.props.data.company}</span>
							<span className="text-muted mt-1 fw-bold fs-7">{this.props.data.email}</span>
							
						</h3>
						
					</div>
					<div className="card-body">
						<div>
							<span className="card-label fw-bolder text-dark">Contact: </span>
							<span className="card-label text-muted">{this.props.data.contact}</span>
						</div>
						<div>
							<span className="card-label fw-bolder text-dark">Address: </span>
							<span className="card-label text-muted">{this.props.data.address}</span>
						</div>
						<div>
							<span className="card-label fw-bolder text-dark">Fee: </span>
							<span className="card-label text-muted">{this.props.data.fee}%</span>
						</div>
						
						
					</div>
					<div className="card-footer">
						<button className="btn btn-primary btn-sm btn-light" onClick={this.props.edit} style={{marginRight:10}} >Edit</button>
						<button className="btn btn-danger btn-sm btn-light" onClick={this.props.delete} style={{marginRight:10}} >Delete</button>
						
    				</div>
					
				</div>
			</React.Fragment>
		)
	}
}
