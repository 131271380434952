import React, {useContext} from 'react'
import { Switch, Route,Redirect } from "react-router-dom";
// import LoginComp from "./component/LoginComp";
import Login from './component/Auth/Login';
import Register from "./component/Auth/Register";
import RegisteredPage from "./component/Auth/Registered.jsx";
import ResetPage from "./component/Auth/Reset";
import ResetedPage from "./component/Auth/Reseted";
import Logout from './component/Auth/Logout';
import DashbordComp from './component/DashboardComp';


// import LandingPage from './component/LandingPage';

import Dashboard from './component/Dashboard/Dashboard';
import Campaign from './component/Campaign/Campaign';
import Approval from './component/Campaign/Approval';

import MyInventories from './component/MyInventories/MyInventories';
import MyProducts from './component/MyProducts/MyProducts';
import Publishers from './component/Publishers/Publishers';

import Partners from './component/Partners/Partners';
import Companies from './component/Companies/Companies';
import Groups from './component/Groups/Groups';
import Modules from './component/Modules/Modules';
import Inventories from './component/Inventories/Inventories';
import Users from './component/Users/Users';
import Roles from './component/Roles/Roles';
import MyProfile from './component/MyProfile/MyProfile';
import Channel from './component/Channel/Channel';
import Product from './component/Product/Product'
import CreateCampaign from './component/Campaign/CreateCampaign';
import ViewCampaign from './component/Campaign/ViewCampaign';
import BrowseCampaign from './component/Campaign/BrowseCampaign';

import Pricebook from './component/Pricebook/Pricebook'
import Assets from './component/Assets/Assets';
import AssetForm from './component/AssetForm/AssetForm';
import EditAsset from './component/AssetForm/EditAsset';
import ViewAsset from './component/AssetForm/ViewAsset';

import Products from './component/Products/Products';
import ProductForm from './component/Products/ProductForm';
import EditProduct from './component/Products/EditProduct';
import ViewProduct from './component/Products/ViewProduct';

import My404 from './component/My404'


import Auth from './component/Auth/Auth'
import { AuthContext } from "./App";

// import {ConsoleLog} from './component/Helper/Helper'
export default function Router() {

	const { state } = useContext(AuthContext);
	return (
		<React.Fragment>
		<Switch>
			
			<Route exact
				path="/"
				component={Dashboard} memberType={['publisher','admin','superadmin']}
			></Route>
			<Route path='/404' component={My404} />
			<Route exact path="/login" component={Login} />
			<Route exact path="/register" component={Register} />
			<Route exact path='/registered' component= {RegisteredPage} />
			<Route exact path='/reset' component= {ResetPage} />
			<Route exact path='/reseted' component= {ResetedPage} />
			<Route exact path='/logout' component= {Logout} />

			<Auth authed={state.isAuth} exact path='/dashboard' component={Dashboard} memberType={['publisher','admin','superadmin']}/>
			<Auth authed={state.isAuth} exact path='/dash' component={DashbordComp} memberType={['publisher','admin','superadmin']}/>
			<Auth authed={state.isAuth} exact path='/campaigns' component={Campaign} memberType={['publisher','admin']} />

			<Auth authed={state.isAuth} exact path='/campaigns/approval' component={Approval}  memberType={['publisher','admin']}/>
			<Auth authed={state.isAuth} exact path='/campaigns/create' component={BrowseCampaign}  memberType={['admin']}/>
			<Auth authed={state.isAuth} exact path='/campaigns/create/:id' component={CreateCampaign}  memberType={['admin']}/>
			<Auth authed={state.isAuth} exact path='/campaigns/view/:id' component={ViewCampaign}  memberType={['publisher','admin']}/>

			<Auth authed={state.isAuth} exact path='/myinventories' component={MyInventories}  memberType={['publisher']}/>
			<Auth authed={state.isAuth} exact path='/myproducts' component={MyProducts}  memberType={['publisher']}/>
			<Auth authed={state.isAuth} exact path='/product/:id' component={Product}  memberType={['publisher','admin']}/>
			<Auth authed={state.isAuth} exact path='/pricebook/:id' component={Pricebook}  memberType={['admin']}/>

			<Auth authed={state.isAuth} exact path='/publishers' component={Publishers}  memberType={['admin']}/>
			<Auth authed={state.isAuth} exact path='/publishers/channel/:id' component={Channel}  memberType={['admin']}/>
			<Auth authed={state.isAuth} exact path='/publishers/products/:id' component={MyProducts}  memberType={['admin']}/>

			<Auth authed={state.isAuth} exact path='/asset' component={Assets}  memberType={['admin']}/> 
			<Auth authed={state.isAuth} exact path='/asset/create' component={AssetForm}  memberType={['publisher','admin']}/> 
			<Auth authed={state.isAuth} exact path='/asset/edit/:id' component={EditAsset}  memberType={['publisher','admin']}/> 
			<Auth authed={state.isAuth} exact path='/asset/view/:id' component={ViewAsset}  memberType={['publisher','admin']}/>

			<Auth authed={state.isAuth} exact path='/products' component={Products}  memberType={['publisher','admin','superadmin']}/> 
			<Auth authed={state.isAuth} exact path='/products/create' component={ProductForm}  memberType={['publisher','admin','superadmin']}/> 
			<Auth authed={state.isAuth} exact path='/products/edit/:id' component={EditProduct}  memberType={['publisher','admin','superadmin']}/> 
			<Auth authed={state.isAuth} exact path='/products/view/:id' component={ViewProduct}  memberType={['publisher','admin','superadmin']}/> 

			<Auth authed={state.isAuth} exact path='/companies' component={Companies}  memberType={['superadmin']}/>
			<Auth authed={state.isAuth} exact path='/partners' component={Partners}  memberType={['superadmin']}/>
			<Auth authed={state.isAuth} exact path='/groups' component={Groups}  memberType={['disable']}/>
			<Auth authed={state.isAuth} exact path='/modules' component={Modules}  memberType={['disable']}/>
			<Auth authed={state.isAuth} exact path='/inventories' component={Inventories}  memberType={['superadmin']}/>
			<Auth authed={state.isAuth} exact path='/users' component={Users} memberType={['admin','superadmin']}/>
			<Auth authed={state.isAuth} exact path='/roles' component={Roles} memberType={['disable']}/>

			<Auth authed={state.isAuth} exact path='/myprofile' component={MyProfile} memberType={['publisher','admin','superadmin']}/>

			{/* <Route exact path="/dashboard" component={Dashboard} /> */}

			<Redirect from='*' to='/404' />


		</Switch>
		</React.Fragment>
	)
}
