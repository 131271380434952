import React, { useState, useEffect } from 'react'
import AuthToolbar from '../Layout/AuthToolbar'
import Pagination from '@mui/material/Pagination';
import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';
import '../Pagination/Pagination.css';
import './Assets.css'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import AssetsData from './AssetsData'


const breadcumb = [
	{
		item: 'Home',
		path: '/'
	},
	{
		item: 'Asset Inventory',
		path: ''
	},
	
]

function inst(ch){
	return `%${ch}%`
}
export default function AssetsContent() {

	const [Page,setPage] = useState({
		totalPage: 1,
		activePage: 1,
		perPage: 5,
	})

	const [filterAsset,setFilterAsset] = useState({
		field: 'user_inventory_items.item_name',
		operator: 'LIKE',
		value: ''
	})
	const [filterPublisher,setFilterPublisher] = useState({
		field: 'users.fullname',
		operator: 'LIKE',
		value: ''
	})
	const [filterChannel,setFilterChannel] = useState({
		field: 'inventories.inventory',
		operator: "LIKE",
		value: ''
	})
	const [filterStatus,setFilterStatus] = useState({
		field: 'user_inventory_items.status',
		operator: 'LIKE',
		value: ''
	})

	const [filter, setFilter] = useState([
		{
			field: 'user_inventory_items.item_name',
			operator: 'LIKE',
			value: '%'
		},
		{
			field: 'users.fullname',
			operator: 'LIKE',
			value: '%'
		},
		
		{
			field: 'inventories.inventory',
			value: 'false'
		},
		{
			field: 'user_inventory_items.status',
			operator: 'LIKE',
			value: '%'
		},

	])

	const [assets,setAssets] = useState([])

	const handlePaginationChange = async (event,value) =>{
		console.log('page',value)
		reloadContentData(value)
		// setPage({
		// 	...Page,
		// 	activePage: value

		// })
		
	}

	const reloadContentData = async (page) => {

		
		const requestBody = {
			page: page,
 			rows: Page.perPage,
			filter: [
				{
					field: filterAsset.field,
					operator: filterAsset.operator,
					value: `%${filterAsset.value}%`
				},
				{
					field: filterPublisher.field,
					operator: filterPublisher.operator,
					value: `%${filterPublisher.value}%`
				},
				{
					field: filterChannel.field,
					operator: filterChannel.operator,
					value: `%${filterChannel.value}%`
				},
				{
					field: filterStatus.field,
					operator: filterStatus.operator,
					value: `%${filterStatus.value}%`
				},
			],

			order: [
				{
					field: "user_inventory_items.updated_at",
					sort: "desc"
				}
			]
		}
		const params = {
			reqBody: requestBody,
			method: API.USER_INVENTORY_ITEMS_ALL.type,
			url: API.USER_INVENTORY_ITEMS_ALL.url,
			access: 'auth' 
		}

		const response = await Api(params)
		console.log('response',response)
		if(response){

			setPage({
				totalPage: response.data.lastPage,
				activePage: response.data.page,
				perPage: response.data.perPage,
			})

			setAssets(response.data.data)

			
			
		}
	}

	// useEffect(() => {
	// 	console.log('Page',Page)
	// 	reloadContentData(Page.ActivePage)
	// }, [Page])
	

	useEffect(() => {
		console.log('assets',assets)
	}, [assets])

	useEffect(()=>{
		reloadContentData(Page.activePage)
	},[])

	const handleCreateAsset = async () => {
		window.location.href='/asset/create'
	}

	const handleFilter = (event) => {
		
		// let f
		// f = {
		// 	field: event.target.name,
		// 	operator: `LIKE`,
		// 	value: `%${event.target.value}%`
		// }

		switch (event.target.name) {
			case `user_inventory_items.item_name`:
				setFilterAsset({
					...filterAsset,
					value: event.target.value
				})
				
				break;
		
			case `users.fullname`:
				setFilterPublisher({
					...filterPublisher,
					value: event.target.value
				})
				break;
			
			case `inventories.inventory`:
				setFilterChannel({
					...filterChannel,
					value: event.target.value
				})
				break;

			case `user_inventory_items.status`:
				setFilterStatus({
					...filterStatus,
					value: event.target.value
				})
				break;

			default:
				break;
		}
	}

	useEffect(() => {
		reloadContentData(Page.activePage)
	}, [filterAsset,filterPublisher,filterChannel,filterStatus])

	return (
		<React.Fragment>
			<AuthToolbar 
				title='Asset Inventory'
				breadcumb = {breadcumb}
			/>

			<Box
				className="container-xxl"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
					m: 0,
					width: "100%",

					},
				}}
			>
				<Paper 
					elevation={3} 
					sx = {{
						p: 5
					}}
					className="col-xl-12 papper"
				>


					<Button variant="contained" onClick={handleCreateAsset}>Buat Asset baru</Button>


					<div className="table-container">
						<Table 
							sx={{ minWidth: 650,width:"100%",fontSize:"20px",marginTop: 3, marginBottom: 3 }} 
							aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>
										<div className="cell-title">Nama Asset</div>
										<Paper
											component="form"
											sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
										>
											<IconButton sx={{ p: '10px' }} aria-label="menu">
												<SearchIcon />
											</IconButton>
											<InputBase
												sx={{ ml: 1, flex: 1 }}
												placeholder="Nama asset"
												name="user_inventory_items.item_name"
												value={filterAsset.value}
												onChange={handleFilter}
												inputProps={{ 'aria-label': 'search google maps' }}
											/>
							
											<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
											
										</Paper>
									</TableCell>
									<TableCell>
									<div className="cell-title">Publisher</div>
										<Paper
											component="form"
											sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
										>
											<IconButton sx={{ p: '10px' }} aria-label="menu">
												<SearchIcon />
											</IconButton>
											<InputBase
												sx={{ ml: 1, flex: 1 }}
												placeholder="Publisher"
												name="users.fullname"
												value={filterPublisher.value}
												onChange={handleFilter}
												inputProps={{ 'aria-label': 'search google maps' }}
											/>
							
											<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
											
										</Paper>
									</TableCell>
									<TableCell>
									<div className="cell-title">Channel</div>
										<Paper
											component="form"
											sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
										>
											<IconButton sx={{ p: '10px' }} aria-label="menu">
												<SearchIcon />
											</IconButton>
											<InputBase
												sx={{ ml: 1, flex: 1 }}
												placeholder="Channel"
												name="inventories.inventory"
												value={filterChannel.value}
												onChange={handleFilter}
												inputProps={{ 'aria-label': 'search google maps' }}
											/>
							
											<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
											
										</Paper>
									</TableCell>
									<TableCell>
									<div className="cell-title">Status</div>
										<Paper
											component="form"
											sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
										>
											<IconButton sx={{ p: '10px' }} aria-label="menu">
												<SearchIcon />
											</IconButton>
											<InputBase
												sx={{ ml: 1, flex: 1 }}
												placeholder="Status"
												name="user_inventory_items.status"
												value={filterStatus.value}
												onChange={handleFilter}
												inputProps={{ 'aria-label': 'search google maps' }}
											/>
							
											<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
											
										</Paper>
									</TableCell> 
									
									<TableCell><div className="cell-title">Actions</div></TableCell>

								</TableRow>
								
								
							</TableHead>

							<TableBody>	
								{assets.map((data,i)=>(
									<AssetsData
										key={i}
										data={data}
										onViewItem = {(id)=>{
											window.location.href = '/asset/view/'+id
										}}
										
									/>
								))}
								
							</TableBody>
						</Table>
					</div>

					<Pagination count={Page.totalPage}  variant="outlined" shape="rounded" onChange={handlePaginationChange} />
				</Paper>

			</Box>	
		</React.Fragment>
	)
}

