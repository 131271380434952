/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import AuthToolbar from '../Layout/AuthToolbar'

import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

// import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormLabel from '@mui/material/FormLabel';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import {dataURLtoFile} from '../Helper/Helper'; 


import './UploadFiles.css'
import UploadFile from '../UploadFile/UploadFile'
import TextareaAutosize from '@mui/material/TextareaAutosize';


import Swal from 'sweetalert2/dist/sweetalert2.js'
import axios from 'axios'

import { useForm } from "react-hook-form";


export default function AssetFormContent(props) {

	const [optionProvinces,setOptionProvince] = useState([])
	const [optionCities,setOptionCities] = useState([])
	const [optionDistricts,setOptionDistricts] = useState([])
	const [optionVillages,setOptionVillages] = useState([])

	const [itemProfile,setItemProfile] = useState([])

	const [provinceID,setProvinceID] = useState(null)
	const [cityID,setCityID] = useState(null)
	const [districtID,setDistrictID] = useState(null)

	const { register, handleSubmit, setValue, formState: { errors } } = useForm();

	const [item_files,setItemFiles] = useState([
		{
			item_file_id: "",
			user_inventory_item_id: "",
			file: "",
			type: "IMAGE",
			file_desc: "",
			main: "false",
			data_file: null
		},
		{
			item_file_id: "",
			user_inventory_item_id: "",
			file: "",
			type: "IMAGE",
			file_desc: "",
			main: "false",
			data_file: null
		},
		{
			item_file_id: "",
			user_inventory_item_id: "",
			file: "",
			type: "IMAGE",
			file_desc: "",
			main: "false",
			data_file: null
		},
		{
			item_file_id: "",
			user_inventory_item_id: "",
			file: "",
			type: "IMAGE",
			file_desc: "",
			main: "false",
			data_file: null
		},
	])
	

	const [asset,setAsset] = useState(null)

	let Mode 
	if(props.id){
		Mode = 'EDIT'
	}else{
		Mode = 'CREATE'
	}

	const breadcumb = [
		{
			item: 'Home',
			path: '/'
		},
		{
			item: 'Asset Inventory',
			path: '/asset'
		},
		{
			item: (Mode === 'CREATE')?`Create new Asset Inventory`:`Edit Asset Inventory`,
			path: ''
		},
		
	]

	const onSubmit = async (data) => {
		Swal.fire({
			title: 'Do you want to save the changes?',
			showDenyButton: true,
			showCancelButton: true,
			confirmButtonText: 'Save',
			denyButtonText: `Don't save`,
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
			  SaveAction(data)
			} else if (result.isDenied) {
				window.location.href='/asset'
			}
		})
	} 

	const SaveAction = async (data) => {
		let f
		let formdata
		let response
		let requestBody
		let params

		console.log('data xx',data)
		console.log('item_files',item_files) 
		// delete existing files and profile

		


		for (const key in item_files) {
		 	console.log(item_files[key])
		 	data.item_files[key][`file_data`]=item_files[key]
		 	data.item_files[key][`item_file_id`] = item_files[key][`item_file_id`]
		 	data.item_files[key][`main`] = item_files[key][`main`]
		 	data.item_files[key][`type`] = item_files[key][`type`]
		 	data.item_files[key][`user_inventory_item_id`] = item_files[key][`user_inventory_item_id`]

		 	if(item_files[key].data_file !== null){
		 		f = dataURLtoFile(item_files[key].data_file.data_url,item_files[key].data_file.file.name)
		 		console.log(`f`,f)
		 		formdata = new FormData()
		 		formdata.append('file', f, f.name);
		 		formdata.append('email', process.env.REACT_APP_CDN_USERNAME);
		 		formdata.append('password',process.env.REACT_APP_CDN_PASSWORD);
		 		const config = {
		 			headers: { 'Content-Type': 'multipart/form-data' }
		 		}

		 		try {
		 			response = await axios.post(process.env.REACT_APP_CDN_URL, formdata, config)
		 			if(response.data.status){
		 					data.item_files[key].file = response.data.data.url
		 					delete data.item_files[key].file_data
		 			}
					
		 		} catch (error) {

		 			Swal.fire({
		 				icon: 'error',
		 				text: '=====' + error.message,
		 			})
		 				return false
		 			}

			}

		}

		const z = data.item_files.filter(x => !!x.file);
		data.item_files = z

		console.log('data',data)


		data.multiproduct = data.multiproduct.toString()
		requestBody = data

		

		params = {
			reqBody: requestBody,
			method: API.USER_INVENTORY_ITEMS_UPDATE.type,
			url: API.USER_INVENTORY_ITEMS_UPDATE.url,
			access: 'auth' 
		}

		response = await Api(params)
		if(response.status){
			console.log('response',response)
			window.location.href='/asset'

		}
	}

	useEffect( async () => {
		loadProvinces()
			// load asset
		const requestBody = {
			user_inventory_item_id : props.id
		}

		

		const params = {
			reqBody: requestBody,
			method: API.USER_INVENTORY_ITEMS_GET.type,
			url: API.USER_INVENTORY_ITEMS_GET.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			setAsset(response.data)
			// setUserInventory(response.data.user_inventory)
			
			
			
		}

	}, [])


	
	const loadProvinces = async () => {
		const requestBody = null;

		const params = {
			reqBody: requestBody,
			method: API.PROVINCE.type,
			url: API.PROVINCE.url,
			access: 'none' 
		}

		const response = await Api(params,true)
		if(response.status){
			const op = response.data
			for (const key in op) {
				op[key]['value'] =op[key]['ms_province_id']
				op[key]['label'] = op[key]['ms_province_name']				
			}
			setOptionProvince(op)
			setOptionCities([])
			setOptionDistricts([])
			setOptionVillages([])
		}



	}


	const loadCities = async () => {

		if(asset){
			if(asset.ms_province_id){
				const requestBody = {
					ms_province_id: asset.ms_province_id
				};
		
				const params = {
					reqBody: requestBody,
					method: API.CITY.type,
					url: API.CITY.url,
					access: 'none' 
				}
		
				const response = await Api(params,true)
	
				if(response.status){
					const op = response.data
					console.log('response.data',response.data)
					for (const key in op) {
						op[key]['value'] =op[key]['ms_city_id']
						op[key]['label'] = op[key]['ms_city_name']				
					}
					setOptionCities(op)
					setOptionDistricts([])
					setOptionVillages([])
				}
			}
			
		}
		
	}

	const loadDistricts = async () => {
		if(asset){
			if(asset.ms_city_id){
				const requestBody = {

					ms_city_id: asset.ms_city_id
				};
		
				const params = {
					reqBody: requestBody,
					method: API.DISTRICT.type,
					url: API.DISTRICT.url,
					access: 'none' 
				}
		
				const response = await Api(params,true)
				if(response.status){
					const op = response.data
					for (const key in op) {
						op[key]['value'] =op[key]['ms_district_id']
						op[key]['label'] = op[key]['ms_district_name']				
					}
					setOptionDistricts(op)
					setOptionVillages([])
				}
			}
			
		}
	}

	const loadVillages = async () => {

		if(asset){
			if(asset.ms_district_id){
				const requestBody = {
					ms_district_id: asset.ms_district_id
				};
		
				const params = {
					reqBody: requestBody,
					method: API.VILLAGE.type,
					url: API.VILLAGE.url,
					access: 'none' 
				}
		
				const response = await Api(params,true)
				if(response.status){
					const op = response.data
					for (const key in op) {
						op[key]['value'] =op[key]['ms_village_id']
						op[key]['label'] = op[key]['ms_village_name']				
					}
					setOptionVillages(op)
				}
			}
		}
		
	}

	useEffect(async () => {
		console.log('asset',asset)
		
		if(asset){
			setValue('user_inventory_item_id',asset.user_inventory_item_id,{ shouldTouch: true, shouldDirty: true })
			setValue('user_inventory_id',asset.user_inventory_id,{ shouldTouch: true, shouldDirty: true })
			setValue('item_name',asset.item_name,{ shouldTouch: true, shouldDirty: true })
			setValue('ms_province_id',asset.ms_province_id,{ shouldTouch: true, shouldDirty: true })
			await loadCities()
			setValue('ms_city_id',asset.ms_city_id,{ shouldTouch: true, shouldDirty: true })
			await loadDistricts()
			setValue('ms_district_id',asset.ms_district_id,{ shouldTouch: true, shouldDirty: true })
			await loadVillages()
			setValue('ms_village_id',asset.ms_village_id,{ shouldTouch: true, shouldDirty: true })
			setValue('lat',asset.lat,{ shouldTouch: true, shouldDirty: true })
			setValue('long',asset.long,{ shouldTouch: true, shouldDirty: true })
			setValue('multiproduct',asset.multiproduct,{ shouldTouch: true, shouldDirty: true })
			setValue('video_link',asset.video_link,{ shouldTouch: true, shouldDirty: true })
			for (const key in asset.item_files) {
				if(!!asset.item_files[key]){
					setValue(`item_files.${key}.file_desc`,asset.item_files[key].file_desc)
					setValue(`item_files.${key}.file`,asset.item_files[key].file)
				}
			 	
			}

			// get base profile
			const requestBody = {
				inventory_id: asset.user_inventory.inventory_id
			};

			const params = {
				reqBody: requestBody,
				method: API.INVETORY_PROFILE_ALL.type,
				url: API.INVETORY_PROFILE_ALL.url,
				access: 'auth' 
			}

			const response = await Api(params)
			if(response.status){
				console.log('inventory profile all',response.data)
				console.log('asset profile',asset.profiles)

				setItemProfile(asset.profiles)
			}
			
			

		}
	}, [asset])


	useEffect(() => {
		for (const key in itemProfile) {
			setValue(`profiles.${key}.profile_key`,itemProfile[key].profile_key)
			setValue(`profiles.${key}.profile_value`,itemProfile[key].profile_value)
			setValue(`profiles.${key}.profile_desc`,itemProfile[key].profile_desc)
		}
	}, [itemProfile])


	const handleProviceChange = (event) => {
		console.log("event.target",event.target)
		setProvinceID(event.target.value)
	}

	useEffect(() => {
		loadCities(provinceID)
		
	}, [provinceID])


	const handleCityChange = (event) => {
		setCityID(event.target.value)
	}

	const handleDistrictChange = (event) => {
		setDistrictID(event.target.value)
	}

	useEffect(() => {
		loadDistricts()
		
	}, [cityID])

	useEffect(() => {
		loadVillages()
		
	}, [districtID])


	const handleImage = (value,index) => {
		
		const a = [...item_files]
		a[index]['data_file'] = value
		setItemFiles(a)
	} 

	useEffect(() => {
		console.log('item_files',item_files)
	}, [item_files])

	const theForm = () => {
		let city
		let villages
		let video_link
		let multiproduct
		let uploadfiles
		let location

		

		city = (
			<React.Fragment>
				<FormControl fullWidth sx={{marginTop:"20px !important"}}> 
					<InputLabel id="label-provinces" sx={{marginLeft: -2}}>Province</InputLabel>
					<Select
						variant ="standard"
						label ="Pilih provinsi ..."
						labelId = "label-provinces"
						name = "ms_province_id" 
						defaultValue={(asset)?(asset.ms_province_id):(``)}
						{...register("ms_province_id")}
						onChange={handleProviceChange}

					>
						{optionProvinces.map((data,i)=>(
							<MenuItem key={i} value={data.ms_province_id} >{data.ms_province_name}</MenuItem>
						))}

					</Select>
				</FormControl>

				<FormControl fullWidth sx={{marginTop:"20px !important"}}> 
					<InputLabel id="label-city" sx={{marginLeft: -2}}>City</InputLabel>
					<Select
						variant="standard"
						label="Pilih kota ..."
						labelId= "label-city" 
						name = "ms_city_id"
						defaultValue={(asset)?(asset.ms_city_id):(``)}
						{...register("ms_city_id")}
						onChange={handleCityChange}
					>
						{optionCities.map((data,i)=>(
							<MenuItem key={i} value={data.ms_city_id}>{data.ms_city_name}</MenuItem>
						))}

					</Select>
				</FormControl>
				
			</React.Fragment>
		)
				
		villages = (
			<React.Fragment>
				<FormControl fullWidth sx={{marginTop:"20px !important"}}> 
					<InputLabel id="label-district" sx={{marginLeft: -2}}>District</InputLabel>
					<Select
						variant="standard"
						label="Pilih kecamatan ..."
						labelId= "label-district" 
						name = "ms_district_id"
						defaultValue={(asset)?(asset.ms_district_id):(``)}
						{...register("ms_district_id")}
						onChange={handleDistrictChange}
					>
						{optionDistricts.map((data,i)=>(
							<MenuItem key={i} value={data.ms_district_id}>{data.ms_district_name}</MenuItem>
						))}

					</Select>
				</FormControl>

				<FormControl fullWidth sx={{marginTop:"20px !important"}}> 
					<InputLabel id="label-villages" sx={{marginLeft: -2}}>Village</InputLabel>
					<Select
						variant="standard"
						label="Pilih kelurahan ..."
						labelId= "label-villages" 
						name = "ms_village_id"
						defaultValue={(asset)?(asset.ms_village_id):(``)}
						{...register("ms_village_id")}
					>
						{optionVillages.map((data,i)=>(
							<MenuItem key={i} value={data.ms_village_id}>{data.ms_village_name}</MenuItem>
						))}

					</Select>
				</FormControl>

			</React.Fragment>
		)
					

		video_link = (
			<React.Fragment>
				<FormControl fullWidth>
					<label className="form-input-label">Link video</label>
					<TextField type="text" 
						variant="standard" 
						{...register("video_link")}
						name="video_link"
					/>
				</FormControl>	
			</React.Fragment>

		)

		location = (
			<React.Fragment>
				<FormControl fullWidth>
					<label className="form-input-label">Latitude</label>
					<TextField  
						variant="standard" 
						{...register("lat")}
						// eslint-disable-next-line react/jsx-no-duplicate-props
						type="text"
						name="lat"
					/>
				</FormControl>
				
				<FormControl fullWidth>
					<label className="form-input-label">Longitude</label>
					<TextField  
						variant="standard" 
						{...register("long")}
						// eslint-disable-next-line react/jsx-no-duplicate-props
						type="text"
						name="long"
					/>
				</FormControl>

			</React.Fragment>
		)

		multiproduct = (
			<React.Fragment>
				<FormControl fullWidth>
					<FormControlLabel control={<Switch defaultChecked={(asset)?((asset.multiproduct === "true")?(true):(false)):(false)} {...register("multiproduct")}/>} 
						label="Multi Product" 
					/>
				</FormControl>	
			</React.Fragment>
		)
			

		uploadfiles = (
			<div className="unload-container">
				<div className="upload-column">
					<div className="upload-desc-title">
						Foto Asset Inventory
					</div>
					<div className="upload-desc">
						Format gambar .jpg .png dan ukuran minimum 300px x 300px, dan ukuran maximum 700px x 700px
					</div>
					<div className="upload-desc">
						Pilih foto produk atau tarik dan letakan hingga 5 photo sekaligus disini, cantumkan minimal 3 foto yang menarik agar produk semakin menarik pembeli
					</div>
				</div>
				<div className="file-container">
					<div className="file-column">
						<UploadFile 
							onFileChange={(value,index)=>handleImage(value,0)}
							
							defaultFile={(asset)?(asset.item_files.length>0)?(asset.item_files[0])?asset.item_files[0].file:``:``:``}
							maxResolutionWidth =  {300}
							maxResolutionHeight = {300}
							width={200}
							height={200}
							
								
						/>
						<input type="hidden" {...register(`item_files.0.file`)} />
						<FormControl fullWidth>
							<TextareaAutosize className="upload-text-area"
								aria-label="minimum height"
								minRows={3}
								placeholder="Keterangan foto"
								{...register(`item_files.0.file_desc`)}
							/>
						</FormControl>

						
					</div>
					
					<div className="file-column">

						<UploadFile 
							onFileChange={(value,index)=>handleImage(value,1)}
							defaultFile={(asset)?(asset.item_files.length>0)?(asset.item_files[1])?asset.item_files[1].file:``:``:``}
							maxResolutionWidth =  {300}
							maxResolutionHeight = {300}
							width={200}
							height={200}
								
						/>

						<input type="hidden" {...register(`item_files.1.file`)} />
						<FormControl fullWidth>
							<TextareaAutosize className="upload-text-area"
								aria-label="minimum height"
								minRows={3}
								placeholder="Keterangan foto"
								{...register(`item_files.1.file_desc`)}
							/>
						</FormControl>
					</div>

					<div className="file-column">
						<UploadFile 
							onFileChange={(value,index)=>handleImage(value,2)}
							defaultFile={(asset)?(asset.item_files.length>2)?(asset.item_files[2])?asset.item_files[2].file:``:``:``}
							maxResolutionWidth =  {300}
							maxResolutionHeight = {300}
							width={200}
							height={200}
								
						/>

						<input type="hidden" {...register(`item_files.2.file`)} />
						<FormControl fullWidth>
							<TextareaAutosize className="upload-text-area"
								aria-label="minimum height"
								minRows={3}
								placeholder="Keterangan foto"
								{...register(`item_files.2.file_desc`)}
							/>
						</FormControl>
					</div>

					<div className="file-column">
						<UploadFile 
							onFileChange={(value,index)=>handleImage(value,3)}
							defaultFile={(asset)?(asset.item_files.length>0)?(asset.item_files[3])?asset.item_files[3].file:``:``:``}
							maxResolutionWidth =  {300}
							maxResolutionHeight = {300}
							width={200}
							height={200}
								
						/>
						<input type="hidden" {...register(`item_files.3.file`)} />
						<FormControl fullWidth>
							<TextareaAutosize className="upload-text-area"
								aria-label="minimum height"
								minRows={3}
								placeholder="Keterangan foto"
								{...register(`item_files.3.file_desc`)}
							/>
						</FormControl>
					</div>
					

					
				</div>
			</div>
		)
		if(asset){
			switch (asset.user_inventory.inventory.search_base) {
				case 'Villages':
					return (
						<React.Fragment>
							{city}
							{villages}
							{location}
							{uploadfiles}
							{video_link}
							{multiproduct}
	
						</React.Fragment>
					)
					
				case 'City':
					return (
						<React.Fragment>
							{city}
							{uploadfiles}
							{video_link}
							{multiproduct}
						</React.Fragment>
					)
				case 'Social Media Account':
					return (
						<React.Fragment>
							<FormControl fullWidth>
								<TextField type="text" 
									label="Social Media Account"
									variant="standard" 
									
									name="inventory"
								/>
							</FormControl>	
						</React.Fragment>
					)
			
				default:
					break;
			}
		}
		
		
		
	}

	// const handleAssetChange = (event) => {
	// 	setAsset({
	// 		...asset,
	// 		[event.target.name]:event.target.value
	// 	})
	// }
	return (
		<React.Fragment>
			<AuthToolbar 
				title= {`Edit Asset Inventory`}
				breadcumb = {breadcumb}
			/>

			<Box
				className="container-xxl"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
					m: 0,
					width: "100%",

					},
				}}
			>
				
				<Paper 
					elevation={3} 
					sx = {{
						p: 5
					}}
					className="col-xl-12 papper"
				>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-title">
						Form Penyuntingan asset
					</div>



					<div className="form-content">
						<FormControl fullWidth>
							<TextField type="text" 
								label="Nama Publisher"
								variant="standard" 
								value={(asset)?(asset.user_inventory.user.fullname):(``) }
								readOnly={true}
							/>
							
						</FormControl>
						  
						<FormControl fullWidth>

							<TextField type="text" 
								label="Inventory"
								variant="standard" 
								value={(asset)?(asset.user_inventory.inventory.inventory):(``)}
								readOnly={true}
							/>
							
						</FormControl>


						<input type="hidden" {...register(`user_inventory_item_id`)}/>
						<input type="hidden" {...register(`user_inventory_id`)}/>
						<React.Fragment>
							<FormControl fullWidth>
								<label className="form-input-label">Nama Asset</label>
								<TextField type="text" 
									variant="standard" 
									{...register("item_name",{required: true})}
									name="item_name"

								/>
								<div className="error-message">{errors.item_name && "asset name is required"}</div> 
							</FormControl>	
						</React.Fragment>

						
						{(asset)?(theForm()):(``)}

						
						
					</div>

					<div className="form-title" style={{paddingTop:"10px"}}>
						Asset Profile
					</div>

					<div className="form-content">

						{itemProfile.map((data,i)=>(
							<FormControl key={i} fullWidth>
								<input 
									type="hidden" 
									{...register(`profiles.${i}.profile_key`)}
									value={data.profile_key}

								/>
								<input 
									type="hidden" 
									{...register(`profiles.${i}.profile_label`)}
									value={data.profile_label}

								/>
								<input 
									type="hidden" 
									{...register(`profiles.${i}.profile_desc`)}
									value={data.profile_desc}

								/>
								<label className="form-input-label">{data.profile_label}</label>
								<TextField type="text" 
									variant="standard" 
									{...register(`profiles.${i}.profile_value`)}
									name={`profiles.${i}.profile_value`}
									helperText={data.profile_desc}

								/>

							</FormControl>	
						))}	

						{/* <ProfileForm data={itemProfile}/> */}
					</div>

					<div className="form-action">
						<button type="button" style={{marginRight: 10}} className="btn btn-light" onClick= {()=>window.history.back()}>Close</button>
						<button type="submit"  className="btn btn-primary" >Save changes</button>
					</div>
				</form>
				</Paper>
				
			</Box>

		</React.Fragment>
	)
}
