import axios from 'axios';

export function ConsoleLog(data) {
	if(process.env.REACT_APP_MODE !=='production'){
		console.log(data)
	}
	return true
}

export function dataURLtoFile(dataurl, filename) {
	const arr = dataurl.split(',')
	const mime = arr[0].match(/:(.*?);/)[1]
	const bstr = atob(arr[1])
	let n = bstr.length
	const u8arr = new Uint8Array(n)
	while (n) {
	  u8arr[n - 1] = bstr.charCodeAt(n - 1)
	  n -= 1 // to make eslint happy
	}
	return new File([u8arr], filename, { type: mime })
}

export async function uploadToCDN(dataBase64,filename){
	const file = dataURLtoFile(dataBase64,filename);
	const data = new FormData()
	data.append('file', file, file.name);
	data.append('email', process.env.REACT_APP_CDN_USERNAME);
	data.append('password',process.env.REACT_APP_CDN_PASSWORD);
	const config = {
		headers: { 'Content-Type': 'multipart/form-data' }
	}

	try {
		const response = await axios.post(process.env.REACT_APP_CDN_URL, data, config)
		return response.data
	} catch (error) {
		return {
			status: false,
			message: error.message,
		}
	}


}

export function titleCase(str) {
	return str.split(' ').map(function(val){ 
	  return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
	}).join(' ');
}

export function mergeObject(object1,object2){
	const ob1 = [...object1]
	const ob2 = [...object2] 

	const o1 = Object.entries(ob1[0])
	const o2 = Object.entries(ob2[0])

	console.log('o1',o1)
	console.log('o2',o2)

}
