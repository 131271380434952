import React, { Component } from 'react';
import AuthToolbar from '../Layout/AuthToolbar';
import Pagination from '@mui/material/Pagination';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import Stack from '@mui/material/Stack';

import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';

import '../Pagination/Pagination.css';
import FilterPartner from './FilterPartner';
import PartnerModal from './PartnerModal';
import PartnerData from './PartnerData';

const breadcumb = [
    {
        item: 'Home',
        path: '/'
    },
    {
        item: 'Settings',
        path: ''
    },
    {
        item: 'Partners',
        path: ''
    }
]

export default class PartnerContent extends Component {

    constructor(props) {
		super(props);
		this.state = {
			Pagination : {
				totalPage: 1,
				activePage: 1,
				perPage: 5,

			},
			Partners: [],
			Partner: {
				partner_id: '',
				partner: '',
				client_id: '',
				server_id: '',
                status: 'ACTIVE',
				partner_response_url: '',
                partner_email: '',

			},
			order: [],
			filter: [
				{
					field: 'partner',
					operator: 'LIKE',
					value: '%'
				},
				{
					field: 'partner_email',
					operator: 'LIKE',
					value: '%'
				},
                {
					field: 'status',
					operator: 'LIKE',
					value: '%'
				}
			],
			showModal: false,
			ActionMode : 'NEW',
		}
		
	}

    componentDidMount() {
		// get companies
	
		this.reloadContentData()
	}

    reloadContentData = async () => {

		const requestBody = {
			page: this.state.Pagination.activePage,
			rows: this.state.Pagination.perPage,
			filter: this.state.filter,
		}
		const params = {
			reqBody: requestBody,
			method: API.PARTNER_GETS.type,
			url: API.PARTNER_GETS.url,
			access: 'auth' 
		}

		const response = await Api(params)
		// console.log(response)
		if(response.status){

			
			this.setState({
				Pagination : {
					totalPage: response.data.lastPage,
					activePage: response.data.page,
					perPage: response.data.perPage,
	
				},
				Partners : response.data.data
			},()=>{
				// console.log(this.state)
			})
		}


	}

    handlePaginationChange = async (event,value) =>{
		// console.log(value)
		let old_pagination = this.state.Pagination;
		old_pagination.activePage= value;
		await this.setState({
			Pagination: old_pagination
		},()=>{
			// console.log(this.state)
			this.reloadContentData()
		})

		// this.reloadContentData()
	}

    handleFilter = (filter) => {
		// console.log('filter')
		// console.log(filter)
		this.setState({
			filter: [
				{
					field: 'partner',
					operator: 'LIKE',
					value: (filter.partner==='')?'%':`%${filter.partner}%`
				},
				{
					field: 'partner_email',
					operator: 'LIKE',
					value: (filter.partner_email==='')?'%':`%${filter.partner_email}%`
				},
                {
					field: 'status',
					operator: 'LIKE',
					value: (filter.status==='')?'%':`%${filter.status}%`
				},
				
				

			]
		},()=>{
			// console.log('after state ---- filter')
			// console.log(this.state)
			this.reloadContentData()
		})
	}

    handleNew = async () =>{
		this.setState({
			Partner: {
				partner_id: '',
				partner: '',
				client_id: '',
				server_id: '',
                status: 'ACTIVE',
				partner_response_url: '',
                partner_email: '',

			},
			showModal: true,
			ActionMode: 'NEW'
		})
	}

    handleCloseModal = () =>{
		this.setState({
			showModal: false,
		})
	}

    handleChange = (event) => {
		// console.log(event.target.name +' = '+ event.target.value)
		const a = this.state.Partner;
		a[event.target.name]=event.target.value
		this.setState({
			Partner: a
		},()=>{
			// console.log(this.state.Company)
		})

	}

    handleSave = async () => {
		let requestBody;
		let params;
		let response;
		
		switch (this.state.ActionMode) {
			case 'NEW':
				requestBody = {
                    partner: this.state.Partner.partner,
                    status: this.state.Partner.status,
                    partner_response_url: this.state.Partner.partner_response_url,
                    partner_email: this.state.Partner.partner_email,

				};
				params = {
					reqBody: requestBody,
					method: API.PARTNER_CREATE.type,
					url: API.PARTNER_CREATE.url,
					access: 'auth' 
				}
				

				break;
			
			case 'EDIT':
				requestBody = {
                    partner_id: this.state.Partner.partner_id,
					partner: this.state.Partner.partner,
                    status: this.state.Partner.status,
                    partner_response_url: this.state.Partner.partner_response_url,
                    partner_email: this.state.Partner.partner_email,
				};
				params = {
					reqBody: requestBody,
					method: API.PARTNER_UPDATE.type,
					url: API.PARTNER_UPDATE.url,
					access: 'auth' 
				}
				break;
			
			
		
			default:
				break;
		}

		response = await Api(params)
		if(response.status){
			this.setState({
				showModal: false
			})
			this.reloadContentData()
			
			
		}
	}

    handleDelete = async (id) => {
		Swal.fire({
			title: 'Delete Partner',
			text: 'Do you want to continue',
			icon: 'error',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Delete',
			confirmButtonColor: '#fc0000'
		}).then(async (result)=>{
			if(result.isConfirmed){

				const requestBody = {
					partner_id: id,
				};
		
				const params = {
					reqBody: requestBody,
					method: API.PARTNER_DELETE.type,
					url: API.PARTNER_DELETE.url,
					access: 'auth' 
				}
		
				const response = await Api(params)
				if(response.status){
					
					this.reloadContentData()
				}

				


			}
		})
	}

    handleEdit = async (id) => {
        // console.log('edit')
		let requestBody
		let params
		let response
		requestBody = {
			partner_id: id
		};
		params = {
			reqBody: requestBody,
			method: API.PARTNER_GET.type,
			url: API.PARTNER_GET.url,
			access: 'auth' 
		}
		response = await Api(params)

		if(response.status){
			this.setState({
				Partner: response.data,
				showModal: true,
				ActionMode: 'EDIT'
			})
		}
	}



    render() {
        return (
            <React.Fragment>
                <AuthToolbar 
                    title='Partners'
                    breadcumb = {breadcumb}
                />

                <div className="container-xxl d-flex flex-stack flex-wrap" style={{marginBottom: 20}}>
					<div className="d-flex flex-column me-3">
						<div className="d-flex align-items-center py-3 py-md-1">
							<div className='me-4'>
								<button type="button" className="btn btn-primary btn-sm btn-light" style={{marginRight:10}} 
									onClick={()=>this.handleNew()}
								>
									Create New Partner
								</button>
							</div> 
						</div>
					</div>
					<div className="d-flex align-items-center py-3 py-md-1">
						<div className="d-flex align-items-center py-3 py-md-1">
							<div className='me-4'>
								
								<FilterPartner onUpdateFilter={(value) => this.handleFilter(value) }/>
							</div>

						</div>		
					</div>
				</div>

                <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
					<div className="content flex-row-fluid" id="kt_content">
						<div className="row gy-5 g-xl-8">
							<div className="col-xl-12">
								
								{this.state.Partners.map((data,i)=>(
									<PartnerData 
										key={data.partner_id}
										data={data}
										edit={()=>this.handleEdit(data.partner_id)}
										delete={()=>this.handleDelete(data.partner_id)}

									/>	
								))}
								<Pagination count={this.state.Pagination.totalPage}  variant="outlined" shape="rounded" onChange={this.handlePaginationChange} />
									
								
							</div>
						</div>
					</div>
				</div>

                <PartnerModal 
					data={this.state.Partner}
					open= {this.state.showModal}
					closeModal = {this.handleCloseModal}
					onChange={this.handleChange}
					onSaveBtn={this.handleSave}
					modalMode={this.state.companyActionMode}
				/>

            </React.Fragment>
        )
    }
}

