import React from 'react'
import {Navigation} from './Menu'
import {member} from '../Auth/localStorage'
// import {ConsoleLog} from '../Helper/Helper'

export default function ItemMenu() {
	const data = Navigation 
	const memberType = member()
	const Menu = data.map((m,i)=>{
		if(m.auth.find(e => e === memberType)){

			return <React.Fragment key={i}>
			
				{(m.subMenu !== null)? (
				<React.Fragment >	
					<div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" className="menu-item here show menu-lg-down-accordion me-lg-1">
						<a className="menu-link active py-3" href={m.to}>
							<span className="menu-title">{m.title}</span>
							<span className="menu-arrow d-lg-none"></span>
						</a>
						<div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
						{
							m.subMenu.map((sub,y)=>{
								
								if(sub.auth.find(element => element === memberType)){
									return (
										<div key={y} className="menu-item">
											<a className="menu-link active py-3" href={sub.to}>
												<span className="menu-icon">
													<span className="httpsvg-icon svg-icon-">
														<img src={process.env.REACT_APP_BASE_URL+"/"+sub.icon} alt='icon' />
													</span>
												</span>
												<span className="menu-title">{sub.title}</span>
											</a>
										</div>
									)
								}else{
									return (<div key={y}></div>)
								}
							}
								
								
							) 
						}
						</div>
						
					</div>
					
				</React.Fragment>
				):(
					<div className="menu-item here show menu-lg-down-accordion me-lg-1">
						<a className="menu-link active py-3" href={m.to}>
							<span className="menu-title">{m.title}</span>
							<span className="menu-arrow d-lg-none"></span>
						</a>
					</div>
				)}
			</React.Fragment>







		}else{
			return (<div key={i}></div>)
		}
		
	})

	return (
		<React.Fragment>	
		{Menu}
		</React.Fragment>
	)
}
