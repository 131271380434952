import React from 'react';
import CategoryData from './CategoryData';
import ProfileData from './ProfileData';

export default function InventoriesData(props) {
	const handleCategoryDelete = (value) => {
		props.onCategoryDeleteAction(value)
	}

	const handleCategoryEdit = (value) => {
		
		props.onCategoryEditAction(value)
	}

	const handleProfileDelete = (value) => {
		props.onProfileDeleteAction(value)
	}

	const handleProfileEdit = (value) => {
		
		props.onProfileEditAction(value)
	}

	return (
		<React.Fragment>
			<div className="card" style={{marginBottom:20}} >
				<div className="card-header card-header-stretch">
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label fw-bolder text-dark">{props.inventory}</span>
						<span className="text-muted mt-1 fw-bold fs-7">{props.inventory_app}</span>
						<span className="mt-1 fw-bold fs-7">Search base with : {props.search_base}</span>
					</h3>
					<div className="card-toolbar">
						<ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
							<li className="nav-item">
								<a className="nav-link active" data-bs-toggle="tab" href={`#category_${props.inventory_id}`} >Category</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" data-bs-toggle="tab" href={`#profile_${props.inventory_id}`}>Profile</a>
							</li>
							<li className="nav-item">
								<button type="button" className="btn btn-sm btn-light" style={{marginRight:10}} onClick={props.edit} >
									Edit
								</button>
							</li>
							<li className="nav-item">
								<button type="button" className="btn btn-sm btn-light"  onClick={props.remove}>
									Delete
								</button>
							</li>

						</ul>
					</div>
				</div>
				<div className="card-body">

					<div className="tab-content" id="myTabContent">
						<div className="tab-pane fade show active" id={`category_${props.inventory_id}`} role="tabpanel">
							<button className="btn btn-primary btn-sm btn-light" onClick={props.newCategory}> Create new Category</button>
							{
								props.inventory_category.map((data) => {
									return 	<CategoryData key={data.inventory_category_id} 
												data={data} 
												onDeleteCategory={(value)=>handleCategoryDelete(value)}
												onEditCategory={(value)=>handleCategoryEdit(value)}
											/>
								})
							}
						</div>

						<div className="tab-pane fade" id={`profile_${props.inventory_id}`} role="tabpanel">
						<button className="btn btn-primary btn-sm btn-light" onClick={props.newProfile}> Create new Profile</button>
							{
								props.profile.map((data) => {
									return <ProfileData key={data.inventory_profile_id}
												data={data}
												onDeleteProfile={(value)=>handleProfileDelete(value)}
												onEditProfile={(value)=>handleProfileEdit(value)}
											/>
								})
							}
						</div>

						
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}
