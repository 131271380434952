import axios from 'axios';
import {token} from '../component/Auth/localStorage.js';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import '../component/Swal/Swal.css';

// const qs = require("querystring");
// const queryString = require('query-string');

var qs = require('qs');
// var assert = require('assert');

// params is
// access = 'auth/public'
// url = url
// method = 'get/post/put/delete'
// reqBody = paramater object



export const Api = async (params,pub=false) => {
	// console.log(pub)
	const config = {
		baseURL: (pub)?(process.env.REACT_APP_API_PUBLIC_URL):(process.env.REACT_APP_API_BASE_URL),
		timeout: process.env.REACT_APP_REQUEST_TIMEOUT,
	}

	if(params.access==='auth'){
		config.headers = {'Authorization': 'bearer '+token()}
	}
	//  console.log(params)
	let response 


	try {
		switch (params.method) {

			case 'get':
				// config.params = params.reqBody;
				// console.log('params.reqBody')
				// console.log(params.reqBody)
				// console.log(qs.stringify(params.reqBody))
				response =  await axios.get(params.url+'?'+qs.stringify(params.reqBody),config)
				break;

			case 'post':
				response =  await axios.post(params.url,params.reqBody,config)	
				break;

			case 'put':
				// const ur = process.env.REACT_APP_API_BASE_URL+params.url+'/?'+qs.stringify(params.id);
				// console.log(ur)
				
				
				config.data = params.reqBody;
				response =  await axios.put(params.url,params.reqBody,config)
				break;

			case 'delete':
				config.data = params.reqBody;
				response =  await axios.delete(config.baseURL+params.url,config)	
				break;		
			default:
				break;
		}
		
		if(!response.data.status){
			Swal.fire({
				icon: 'error',
				text: response.data.message,
				
			})
		}
		return response.data

	} catch (error) {
		// console.log('ini errrnya')
		// console.log({error})
		if(!error.response){
			Swal.fire({
				icon: 'error',
				text: error.message,
				})
			
			return error
		}else{
			// console.log('else')
			if(error.response.status === 401 || error.response.status === 403){
				// clear local stotage
				localStorage.clear();
				window.location.replace(process.env.REACT_APP_BASE_URL+'/login');
			} 
		}
	}

	
	
}