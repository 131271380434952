import React from 'react';
import { Route,Redirect } from "react-router-dom";

import {member} from './localStorage'


export default function Auth ({component: Component, authed, memberType, ...rest}) {
	if(!memberType.find(element => element === member())){
		return <Redirect from='*' to='/404' />
	}
	return (
		<Route
			{...rest}
			render={(props) => authed === true
		  	? <Component {...props} />
		  	: <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
	  	/>
	)
  }