import React, { Component } from 'react'
// import Topbar from '../Topbar/Topbar'
import Sidebar from '../Sidebar/Sidebar'
import './Layout.css'

export default class Layout extends Component {
	render() {
		return (
			<div>
				{/* <Topbar/> */}
				<div className="containers">
					<Sidebar/>
					<div className="other">Other Pages</div>
				</div>
			</div>
		)
	}
}

