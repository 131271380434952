import React, { Component } from 'react';
import Modal from '@mui/material/Modal';

export class ProfileModal extends Component {
	
	state = {
		profile : null,
		profile_name: null,
		profile_desc: null,
		validate: false
	}

	handleProfileChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		},()=>{
			// console.log('handleProfileChange')
			// console.log(this.state)
			this.props.onInventoryProfileChange({
				profile:this.state.profile,
				profile_name: this.state.profile_name,
				profile_desc: this.state.profile_desc
			})
		})
	}

	handleSaveButton = async () =>{
		if(this.state.validate === 'none'){
			this.props.onSaveBtn(true)
		}
	}


	render() {
		

		return (
			<React.Fragment>
				
				<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				>
					
						<div className="modal-dialog modal-dialog-centered mw-650px">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Profile</h5>

									
									<div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
										<span className="svg-icon svg-icon-2x"></span>
									</div>
									
								</div>
									<div className="modal-body">
										<div className="mb-5 fv-row fv-plugins-icon-container">
																		
											<label className="fs-5 fw-bold mb-2">Profile</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.inventory_profile.profile}
												onChange={this.props.onInventoryProfileChange}
												name="profile"
												placeholder='Type Profile variable'
												
											/>

											<label className="fs-5 fw-bold mb-2">Profile Name</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.inventory_profile.profile_name}
												onChange={this.props.onInventoryProfileChange}
												name="profile_name"
												placeholder='Type profile name when display in customer'
												
											/>

											<label className="fs-5 fw-bold mb-2">Profile Description</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.inventory_profile.profile_desc}
												onChange={this.props.onInventoryProfileChange}
												name="profile_desc"
												placeholder='Type profile descriptions'
												
											/>
										</div>
									</div>

									<div className="modal-footer">
										<button type="button" className="btn btn-light" onClick= {this.props.closeProfileModal}>Close</button>
										<button type="button" className="btn btn-primary" onClick = {this.props.onSaveBtn} disabled={(this.props.inventory_profile.profile.length>0 && this.props.inventory_profile.profile_name.length>0 && this.props.inventory_profile.profile_desc.length>0)?false:true}>Save changes</button>
									</div>
							</div>
						</div>





				</Modal>
			</React.Fragment>
			

			
		)
	}
}




