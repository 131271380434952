import React, { Component } from 'react';
import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';
import AuthToolbar from '../Layout/AuthToolbar'
import Pagination from '@mui/material/Pagination';
import ProductData from './ProductData';
import ProductModal from './ProductModal';
import './MyProductData.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {dataURLtoFile} from '../Helper/Helper';
import axios from 'axios';
import ValidateProduct from './ValidateProduct';
import { Redirect } from "react-router-dom"



const breadcumb = [
    {
        item: 'Home',
        path: '/'
    },
    {
        item: 'Products',
        path: ''
    }
]

export default class MyProductContent extends Component {

    constructor(props) {
		super(props);
		this.state = {
			Pagination : {
				totalPage: 1,
				activePage: 1,
				perPage: 5,

			},
			user: {
				user_id: "",
				email: "",
				fullname: "",

			},
			inventory: {
				inventory_id: "",
				inventory: ""
			},
			user_products: [],
			user_product: {
				user_product_id: '',
				user_inventory_item_id: '',
				inventory_category_id: '',
				product : '',
				description: '',
				price: 0,
				discount_price: 0,
				status: 'ACTIVE',
				url_video: '',
				expired_date: ''
			},
			user_product_setting: {
				user_product_setting_id: '',
				user_product_id: '',
				unit: '',
				unit_desc: '',
				customer_need_schedule: 'false',
				schedule_type: 'Daily',
				publish_duration: 1,
				customer_need_upload_file: 'true',
				content_create_by: 'customer',
				content_file_type: 'video',
				content_file_duration: '0',
				video_hres: null,
				video_vres: null
			},
			user_product_files: [
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},

			],
			user_inventories: [],
			user_inventory_items: [],
			order: [],
			filter: [],
			showModal: false,
			actionMode : 'NEW',
			vieProductID: null
		}
	}

    componentDidMount(){
		this.reloadContentData()
		this.getUserInventories()
        // console.log(this.props)
		
	}

	getUserInventories = async () => {
		let requestBody;
		let params;
		let response;

		requestBody = {
			user_id: this.props.id,
			
		}
		params = {
			reqBody: requestBody,
			method: API.USER_INVENTORY_GET_ALL.type,
			url: API.USER_INVENTORY_GET_ALL.url,
			access: 'auth' 
		}

		response = await Api(params)
		// console.log(response.data.data)
		if(response.status){

			// console.log('user_inventories')
			// console.log(response)
			this.setState({
				user_inventories: response.data
			},()=>{
				// console.log('user_inventories')
				// console.log(this.state.user_inventories)

			})
		}
	}

    reloadContentData = async () => {
		let requestBody;
		let params;
		let response;

		requestBody = {
			user_id: this.props.id,
            page: this.state.Pagination.activePage,
            rows: this.state.Pagination.perPage,
            filter: this.state.filter,
			
		}
		params = {
			reqBody: requestBody,
			method: API.USER_PRODUCT_GETS.type,
			url: API.USER_PRODUCT_GETS.url,
			access: 'auth' 
		}

		response = await Api(params)
		// console.log(response.data.data)
		if(response.status){

			
			this.setState({
				user: response.data.user,
				user_products: response.data.data,
                Pagination: {
					totalPage: response.data.lastPage,
					activePage: response.data.page,
					perPage: response.data.perPage,
				} 
				// Partners : response.data.data
			},()=>{
				// console.log(this.state.user_products)
				// this.reloadDetailData()
				// this.loadProvince()
			})
		}
	}

    handlePaginationChange = async (event,value) =>{
		// console.log(value)
		let old_pagination = this.state.Pagination;
		old_pagination.activePage= value;
		this.setState({
			Pagination: old_pagination
		},()=>{
			// console.log(this.state)
			this.reloadContentData()
		})

		// this.reloadContentData()
	}

	handleStatus = async (value) => {
		let newStatus
		const data = this.state.user_products.find(x=>x.user_product_id === value)
		// console.log(data)
		if(data.status === 'ACTIVE'){
			newStatus = 'NOT ACTIVE'
		}else{
			newStatus = 'ACTIVE'
		}

		let requestBody;
		let params;
		let response;

		requestBody = {
			user_product_id: value,
			status: newStatus
           
			
		}
		params = {
			reqBody: requestBody,
			method: API.USER_PRODUCT_UPDATE.type,
			url: API.USER_PRODUCT_UPDATE.url,
			access: 'auth' 
		}

		response = await Api(params)
		if(response.status){
			this.reloadContentData()
		}
	}

	handleEdit = async (value) => {
		// console.log(value)
		let requestBody;
		let params;
		let response;

		this.setState({
			user_product_files: [
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},

			],
		})
		
		requestBody = {
			user_product_id: value,
		}
		params = {
			reqBody: requestBody,
			method: API.USER_PRODUCT_GET.type,
			url: API.USER_PRODUCT_GET.url,
			access: 'auth' 
		}

		response = await Api(params)
		if(response.status){
			console.log('response.data',response.data)
			console.log('response.data.user_product_files.length',response.data.user_product_files.length)
			console.log('response.data.user_product_files',response.data.user_product_files)
			const a = response.data;
			a['url_video_change']=null;
			

			const upf = this.state.user_product_files
			for (const key in upf) {
				if(key <= response.data.user_product_files.length - 1){
					
					upf[key].user_product_file_id= response.data.user_product_files[key].user_product_file_id
					upf[key].user_product_id= response.data.user_product_files[key].user_product_id
					upf[key].file= response.data.user_product_files[key].file
					upf[key].type= response.data.user_product_files[key].type
					upf[key].file_desc= response.data.user_product_files[key].file_desc
					upf[key].main= response.data.user_product_files[key].main
				}
			}


			this.setState({
				user_product: a,
				showModal: true,
				actionMode: 'EDIT',
				user_product_files: upf,
				user_product_setting: response.data.user_product_setting
				
			},()=>{
				console.log('this.state.user_product_files',this.state.user_product_files)
			})
		}

		
	}

	handleClose = () => {
		this.setState({
			showModal: false
		})
	}

	createNew = async(id) => {
		// console.log('new')
		// console.log('this.state.user_inventories')
		// console.log(this.state.user_inventories)
		this.setState({
			user_product : {
				user_product_id: '',
				user_inventory_item_id: '',
				inventory_category_id: '',
				product : '',
				description: '',
				price: 0,
				discount_price: 0,
				status: 'ACTIVE',
				url_video: '',
				expired_date: '',

			},
			user_product_setting: {
				user_product_setting_id: '',
				user_product_id: '',
				unit: 'pcs',
				unit_desc: '',
				customer_need_schedule: 'true',
				schedule_type: 'Daily',
				customer_need_upload_file: 'true',
				content_create_by: 'customer',
				content_file_type: 'video',
				content_file_duration: '0',
				video_hres: '',
				video_vres: ''
			},
			user_product_files: [
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},

			],
			showModal: true,
			actionMode: 'NEW'

		})
	}

	handleChange = (value) => {
		
		
		this.setState({
			user_product: value
		},()=>{
			// console.log('user_product change on')
			// console.log(this.state.user_product)
		})
	}

	handleSettingChange = (event) => {
		console.log('event.target',event.target)
		const a = this.state.user_product_setting
		a[event.target.name] = event.target.value
		this.setState({
			user_product_setting : a
		},()=>{
			console.log('this.state.user_product_setting',this.state.user_product_setting)
		})
	}


	handleSave = async () => {

		let valid = ValidateProduct(this.state.user_product)
		let actionStatus;

		if(!valid.validate){
			Swal.fire({
			 	icon: 'error',
			 	text: valid.message
			})

			return valid.validate
		}

		switch (this.state.actionMode) {
			case "NEW":
					actionStatus = await this.createProduct()
				break;
			
			case "EDIT":
					actionStatus = await this.updateProduct()
				break;

			default:
					actionStatus = {
						status: false,
						message: "Invalid action"

					}
				break;
		}


		// if(!actionStatus.status){
		// 	Swal.fire({
		// 		icon: 'error',
		// 		text: actionStatus.message
		//    })
		// }else{
		// 	this.setState({
		// 		showModal: false,
		// 	})
		// }
	}

	uploadFiles = async (data,actionMode) => {

		console.log('data',data)
		let newObj;
		let temp_files = []
		let file
		let updata
		let config
		let response
		for (const i in data) {
			if(data[i].image_change !== null){
				file = dataURLtoFile(data[i].image_change.data_url,data[i].image_change.file.name)
				// console.log(data[i])
				updata = new FormData()
				updata.append('file', file, file.name);
				updata.append('email', process.env.REACT_APP_CDN_USERNAME);
				updata.append('password',process.env.REACT_APP_CDN_PASSWORD);
				config = {
					headers: { 'Content-Type': 'multipart/form-data' }
				}

				response = await axios.post(process.env.REACT_APP_CDN_URL, updata, config)
				if(response.data.status){
					data[i].file = response.data.data.url
				}

			}
		
			switch (actionMode) {
				case "NEW":
					if(data[i].user_product_file_id === '' && data[i].image_change !== null){
						// delete data[i].user_product_file_id
						// delete data[i].user_product_id
						// delete data[i].image_change
						newObj = JSON.parse(JSON.stringify(data[i]))
						delete newObj.user_product_file_id
						delete newObj.user_product_id
						delete newObj.image_change

						temp_files.push(newObj)
					}
					
					break;
				case "EDIT":
					if(data[i].image_change !== null){
						delete data[i].image_change;
						data[i].user_product_id = this.state.user_product.user_product_id
						temp_files.push(data[i])
					}
					
					break;
			
				default:
					break;
			}

		}

		return temp_files

		
	}

	productSetting = async (OldData,actionMode) =>{
		let data = Object.assign({}, OldData)
		switch (actionMode) {
			case "NEW":
				delete data.user_product_setting_id
				delete data.user_product_id
				data.video_hres = (data.video_hres === '')?null:data.video_hres
				data.video_vres = (data.video_vres === '')?null:data.video_vres
				break;
		
			case "EDIT":
			
				break;
		
			default:
				break;
		}

		return data
	}

	createProduct = async () => {
		let requestBody;
		let params;
		let response;

		const data = Object.assign({}, this.state.user_product_files)
		
		// console.log('BEFORE',data)
		
		const uploadFiles = await this.uploadFiles(data,this.state.actionMode)
		const setting = await this.productSetting(this.state.user_product_setting,this.state.actionMode)
		// console.log('uploadFiles',uploadFiles)
		// console.log('this.state.user_product_files',this.state.user_product_files)

		
		requestBody = {
			user_inventory_item_id: this.state.user_product.user_inventory_item_id,
			inventory_category_id: this.state.user_product.inventory_category_id,
			product: this.state.user_product.product,
			description: this.state.user_product.description,
			price: this.state.user_product.price,
			discount_price: this.state.user_product.discount_price,
			status: this.state.user_product.status,
			url_video: this.state.user_product.url_video,
			user_product_setting: setting,
			user_product_files: uploadFiles


		}

		

		params = {
			reqBody: requestBody,
			method: API.USER_PRODUCT_CREATE.type,
			url: API.USER_PRODUCT_CREATE.url,
			access: 'auth' 
		}

		response = await Api(params)
		if(response.status){
			
			this.setState({
				showModal: false
			})
		}else{
			// console.log('---uploadFiles')
			// console.log(uploadFiles)
			// console.log('---this.state.user_product_files')
			// console.log(this.state.user_product_files)
		}
	}

	updateP = async (uploadFiles) => {
		let requestBody;
		let params;
		let response;

		requestBody = {
			user_product_id : this.state.user_product.user_product_id,
			product: this.state.user_product.product,
			description: this.state.user_product.description,
			price: this.state.user_product.price,
			discount_price: this.state.user_product.discount_price,
			status: this.state.user_product.status,
			user_product_setting: this.state.user_product_setting
		}

		params = {
			reqBody: requestBody,
			method: API.USER_PRODUCT_UPDATE.type,
			url: API.USER_PRODUCT_UPDATE.url,
			access: 'auth' 
		}

		response = await Api(params)
		if(response.status){
			await this.updateF(uploadFiles)
			this.setState({
				showModal: false
			},()=>{
				this.reloadContentData()
			})
		}else{
			
		}


	}

	updateF = async (data) => {
		let requestBody;
		let params;
		let response;
		let URL;
		let URL_method;

		for (const f of data) {
			console.log('loop data', f)
			if(f.user_product_file_id === ""){
				console.log('data.user_product_file_id === ""')
				requestBody = {
					user_product_id : f.user_product_id,
					file: f.file,
					type: f.type,
					file_desc: f.file_desc,
				}

				URL = API.USER_PRODUCT_FILE_CREATE.url;
				URL_method = API.USER_PRODUCT_FILE_CREATE.type;

			}else{
				console.log('data.user_product_file_id !== ""')
				requestBody = {
					user_product_file_id : f.user_product_file_id,
					user_product_id : f.user_product_id,
					file: f.file,
					type: f.type,
					file_desc: f.file_desc,
				}

				URL = API.USER_PRODUCT_FILE_UPDATE.url;
				URL_method = API.USER_PRODUCT_FILE_UPDATE.type;
			}

			
			params = {
				reqBody: requestBody,
				method: URL_method,
				url: URL,
				access: 'auth' 
			}

			console.log('params',params)

			response = await Api(params)
			if(response.status){

			}else{
				
			}

		}
	}

	updateProduct = async () => {
		const data = Object.assign({}, this.state.user_product_files)
		const uploadFiles = await this.uploadFiles(data,this.state.actionMode)
		

		await this.updateP(uploadFiles)
		
		

		//
	}
	

	handleFileChange = async(value) => {
		this.setState({
			user_product_files: value
		})
	}

	handleView = async(value) => {
		console.log(value)

		this.setState({
		 	actionMode: "VIEW",
		 	viewProductID: value
		})
	}

    render() {

		if (this.state.actionMode === "VIEW"){
			return <Redirect to={'/product/'+this.state.viewProductID}/>
		}

        return (
            <React.Fragment>
                <AuthToolbar 
                    title='Products'
                    breadcumb = {breadcumb}
                />

                <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
					<div className="content flex-row-fluid" id="kt_content">
						<div className="row gy-5 g-xl-8">
							<div className="col-xl-12">



								<div className="card" style={{marginBottom:20}} >
									<div className="card-header">
										<h3 className="card-title align-items-start flex-column">
											<span className="card-label fw-bolder text-dark">Publisher Name</span>
											{/* <span className="text-muted mt-1 fw-bold fs-7">{this.state.inventory.inventory}</span> */}
											<button className="btn btn-primary btn-sm btn-light" onClick={this.createNew} style={{marginRight:10}} >Create new Product</button>
										</h3>
                                        

                                        
										
									</div>
									<div className="card-body">
										<div className="grid-container-one-col">
											

											{(this.state.user_products.length === 0)?`No product defined`:
											(
												<ProductData className='grid-data-one-col'
													data={this.state.user_products}
													onEdit={(value)=>this.handleEdit(value)}
													onView={(value)=>this.handleView(value)}
													
													onDeleteBtn={(value)=>this.handleDelete(value)}
													onChangeStatus = {(value)=>this.handleStatus(value)}
												/>
											)
											}
										</div>
                                        
										
									</div>
									
									<div className="card-footer">
										
                                        {(this.state.Pagination.totalPage>1)?(
                                            <Pagination count={this.state.Pagination.totalPage}  variant="outlined" shape="rounded" onChange={this.handlePaginationChange} />
                                        ):(``)}
										
									</div>
									
								</div>



							</div>
						</div>
					</div>
				</div>

				<ProductModal
					open={this.state.showModal}
					onClose = {this.handleClose}
					inventories = {this.state.user_inventories}
					data= {this.state.user_product}
					files = {this.state.user_product_files}
					setting= {this.state.user_product_setting}
					user_product = {this.state.user_product}
					onChangeProduct = {value=>this.handleChange(value)}
					onSettingChange = {this.handleSettingChange}
					onFileChange = {(value)=>this.handleFileChange(value)}
					onSave= {this.handleSave}
					actionMode={this.state.actionMode}

				/>

            </React.Fragment>
        )
    }
}


