import React from 'react';
import AuthToolbar from '../Layout/AuthToolbar';
import './Dashboard.css';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AddTaskIcon from '@mui/icons-material/AddTask';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

import { Bar } from 'react-chartjs-2';
import faker from 'faker';





const DashbordContent = () => {
    const breadcumb = [
		{
			item: 'Home',
			path: '/'
		},
		{
			item: 'Dashboard',
			path: ''
		}

	]

	

	// const labels = Utils.months({count: 7});
	
	

	const options = {
		responsive: true,
		plugins: {
		  legend: {
			position: 'top',
		  },
		  title: {
			display: true,
			text: 'Chart.js Bar Chart',
		  },
		},
	  };

	  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

	const data = {
		labels,
		datasets: [
		  {
			label: 'Campaign Order',
			data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
			backgroundColor: 'rgba(255, 99, 132, 0.5)',
		  },
		  {
			label: 'Campaign Success',
			data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
			backgroundColor: 'rgba(53, 162, 235, 0.5)',
		  },
		],
	  };



    return (
        <React.Fragment>

            <AuthToolbar 
                title='Dashboard'
                breadcumb = {breadcumb}
            />
			<div className="container">
				<div className="dashboard-container4">
					<div className="dashboard-card">
						<div className="dashboard-icon"><PendingActionsIcon sx={{ fontSize: 40 }}/></div>
						<div className="dashboard-number">227</div>
						<div className="dashboard-status">PENDING</div>
						
					</div>
					<div className="dashboard-card">
						<div className="dashboard-icon"><AddTaskIcon sx={{ fontSize: 40 }}/></div>
						<div className="dashboard-number">374</div>
						<div className="dashboard-status">IN PROGRESS</div>
					</div>
					<div className="dashboard-card">
						<div className="dashboard-icon"><SmsFailedIcon sx={{ fontSize: 40 }}/></div>
						<div className="dashboard-number">2</div>
						<div className="dashboard-status">REJECTED</div>
					</div>
					<div className="dashboard-card">
						<div className="dashboard-icon"><AssignmentTurnedInIcon sx={{ fontSize: 40 }}/></div>
						<div className="dashboard-number">769</div>
						<div className="dashboard-status">DONE</div>
					</div>
				</div>

				<div className="dashboard-container2">
					<div className="dashboard-card">
						SALES PROGRESS
						<Bar options={options} data={data} />
						<div className="dashboard-container2" style={{marginTop:"10px"}}>
							<div className="item-container">
								<div className="dashboard-status">TOTAL SALES</div>
								<div className="inventory-title">Rp. 7745.885.754</div>
							</div>
							<div className="item-container">
								<div className="dashboard-status">Annual Income</div>
								<div className="inventory-title">Rp. 7745.885.754</div>
							</div>
							<div className="item-container">
								<div className="dashboard-status">Avarange Sale</div>
								<div className="inventory-title">Rp. 7745.885.754</div>
							</div>
							<div className="item-container">
								<div className="dashboard-status">Commision</div>
								<div className="inventory-title">Rp. 7745.885.754</div>
							</div>
						</div>
					</div>
					<div className="dashboard-container1">
						<div className="dashboard-container2">
							<div className="dashboard-card-3 b-black">
								<div className="dashboard-icon"><InventoryIcon sx={{ fontSize: 40, color: "white" }}/></div>
								<div className="dashboard-number" style={{color: "white"}}>777</div>
								<div className="dashboard-status" style={{color: "white"}}>TOTAL INVENTORY</div>
								
							</div>
							<div className="dashboard-card-3 b-blue">
								<div className="dashboard-icon"><AssignmentIndIcon sx={{ fontSize: 40,color: "white" }}/></div>
								<div className="dashboard-number" >1683</div>
								<div className="dashboard-status" style={{color: "white"}}>TOTAL PUBLISHER</div>
							</div>
						</div>
						<div className="dashboard-card" style={{minHeight:"300px"}}>
							<div className="dashboard-status">
								Desember 2021
							</div>
							<div className="dashboard-headline">
								<div className="inventory-container">
									<div className="invantory-image"><img src="https://source.unsplash.com/user/c_v_r" alt="" width="100%"/></div>
									<div className="inventory-detail">
										<div className="dashboard-status">DOOH - BILLBOARD MAMPANG</div>
										<div className="inventory-title">TOTAL REVENUE Rp. 57.879.000</div>

									</div>
								</div>
							</div>
							
						</div>
					</div>
					
					
				</div>

				<div className="dashboard-container1">
					<div className="dashboard-card">
						TOP PUBLISHER
					</div>
				</div>
				
			</div>
        </React.Fragment>
    )
    
}


export default DashbordContent;