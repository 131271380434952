import React, { Component } from 'react';
import Modal from '@mui/material/Modal';


export default class PartnerModal extends Component {




	render() {
		return (
			<React.Fragment>
				<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				>
					<div className="modal-dialog modal-dialog-centered mw-650px">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Partner</h5>

									
									<div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
										<span className="svg-icon svg-icon-2x"></span>
									</div>
									
								</div>
								<div className="form w-100">
									<div className="modal-body">
										<div className="mb-5 fv-row fv-plugins-icon-container">
																
											<label className="fs-5 fw-bold mb-2">Partner</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.data.partner}
												onChange={this.props.onChange}
												name="partner"
												/>
										</div>
										<div className="mb-5 fv-row fv-plugins-icon-container">
																
											<label className="fs-5 fw-bold mb-2">Email</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.data.partner_email}
												onChange={this.props.onChange}
												name="partner_email"
												disabled={(this.props.modalMode === 'EDIT')}
												/>
										</div>

										
										<div className="mb-5 fv-row fv-plugins-icon-container">
																		
											<label className="fs-5 fw-bold mb-2">Response URL</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.data.partner_response_url}
												onChange={this.props.onChange}
												name="partner_response_url"
												/>
										</div>
									</div>

									<div className="modal-footer">
										<button type="button" className="btn btn-light" onClick= {this.props.closeModal}>Close</button>
										<button type="button" className="btn btn-primary" onClick = {this.props.onSaveBtn}>Save changes</button>
									</div>
								</div>
							</div>
						</div>
				</Modal>
				
			</React.Fragment>
		)
	}
}
