import React from 'react'

export default function AuthToolbar(props) {
	const dt = props.breadcumb
	// const dt = [
	// 	{ key: 1, item: 'Home',	path: '/'},
	// 	{ key: 2, item: 'Dashboard',path: ''}
	// ]
	// <a href={post.path} key={i} className="text-white text-hover-primary">{post.item} </a>

	const breadcumb = (
		<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
			{dt.map((post,i) =>
				<div key={i} style={{display: "flex",marginRight: "10px"}}>
					{post.path === '' ?(
						<li className="breadcrumb-item text-white opacity-75"> {post.item}</li>
					):(
						<li  className="breadcrumb-item text-white opacity-75" >
							<a href={post.path} className="text-white text-hover-primary">{post.item} </a>
						</li>
					)}
								
					
					{i !== (dt.length-1)?(
					
					<li className="breadcrumb-item">
						<span className="bullet bg-white opacity-75 w-5px h-2px"> </span>
					</li>
					
					):""}

				</div>
		  	)}
		</ul>
	  );

	
	return (
		<div className="toolbar py-5 py-lg-15" id="kt_toolbar">
			<div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
				<div className="page-title d-flex flex-column me-3">
					<h1 className="d-flex text-white fw-bolder my-1 fs-3">{props.title}</h1>
						{breadcumb}
						
					

							{/* <li className="breadcrumb-item">
								<span className="bullet bg-white opacity-75 w-5px h-2px"></span>
							</li> */}
					
						
						
				</div>
			</div>
		</div>
	)
}





