import React, { Component } from 'react';
import Modal from '@mui/material/Modal';


export default class CompanyModal extends Component {




	render() {
		return (
			<React.Fragment>
				<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				>
					<div className="modal-dialog modal-dialog-centered mw-650px">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Company</h5>

									
									<div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
										<span className="svg-icon svg-icon-2x"></span>
									</div>
									
								</div>
								<div className="form w-100">
									<div className="modal-body">
										<div className="mb-5 fv-row fv-plugins-icon-container">
																
											<label className="fs-5 fw-bold mb-2">Company</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.company.company}
												onChange={this.props.onChangeCompany}
												name="company"
												/>
										</div>
										<div className="mb-5 fv-row fv-plugins-icon-container">
																
											<label className="fs-5 fw-bold mb-2">Email</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.company.email}
												onChange={this.props.onChangeCompany}
												name="email"
												disabled={(this.props.modalMode === 'EDIT')}
												/>
										</div>

										<div className="mb-5 fv-row fv-plugins-icon-container">
																		
											<label className="fs-5 fw-bold mb-2">Contact</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.company.contact}
												onChange={this.props.onChangeCompany}
												name="contact"
												/>
										</div>
										<div className="mb-5 fv-row fv-plugins-icon-container">
																		
											<label className="fs-5 fw-bold mb-2">Address</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.company.address}
												onChange={this.props.onChangeCompany}
												name="address"
												/>
										</div>
										<div className="mb-5 fv-row fv-plugins-icon-container">
																		
											<label className="fs-5 fw-bold mb-2">Fee (%)</label>
											<input 
												type="number" 
												className="form-control" 
												value={this.props.company.fee}
												onChange={this.props.onChangeCompany}
												name="fee"
												/>
										</div>
									</div>

									<div className="modal-footer">
										<button type="button" className="btn btn-light" onClick= {this.props.closeModal}>Close</button>
										<button type="button" className="btn btn-primary" onClick = {this.props.onSaveBtn}>Save changes</button>
									</div>
								</div>
							</div>
						</div>
				</Modal>
				
			</React.Fragment>
		)
	}
}
