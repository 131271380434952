import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "./Pricebook.css";
import Button from '@mui/material/Button';
import PricebookForm from './PricebookForm';





export default function Pricebook(props) {
	const [open,setOpen] = useState(false);

	const handleCreate = () => {
		setOpen(true)
	}

	const handleCreatePricebook = async (pricebook) => {
		setOpen(false)
		props.onCreatePricebook(pricebook)
	}

	return (

		<React.Fragment>
			<Button variant="contained" onClick={handleCreate}>Define new Price</Button>
			<Paper className="papper-table">
			
				<Table sx={{ minWidth: 650 }} aria-label="simple table" className="pricebook-table">
					<TableHead>
						<TableRow>
							<TableCell>#ID</TableCell>
							<TableCell>#Partner</TableCell>
							<TableCell>#CompanyID</TableCell>
							<TableCell>#Company Name</TableCell>
							<TableCell>#Price</TableCell>
							<TableCell>Action</TableCell>
							

						</TableRow>
					</TableHead>
					<TableBody>
						{props.data.map((dt,i)=>(
							<TableRow key={i}>
								<TableCell>{dt.user_product_pricebook_id}</TableCell>
								<TableCell>{dt.partner.partner}</TableCell>
								<TableCell>{dt.partner_company_id}</TableCell>
								<TableCell>{dt.partner_company_name}</TableCell>
								<TableCell>{dt.price}</TableCell>
								<TableCell><Button variant="contained" onClick={()=>props.onDelete(dt.user_product_pricebook_id)}>Delete</Button></TableCell>
							</TableRow>
						))}

						
						
						
					</TableBody>

					
				</Table>

				{(props.data.length==0)?(
							<div style={{textAlign:"center",marginTop:"10px"}}>Nothing price defined</div>
						):(``)}

			</Paper>

			<PricebookForm 
				open={open}
				onClose={()=>setOpen(false)}
				partners={props.partners}
				product={props.product}
				onCreate={(value)=>handleCreatePricebook(value)}
			/>
		</React.Fragment>
	)
}
