import React, { Component } from 'react';
import Modal from '@mui/material/Modal';
import UploadFile from './UploadFile';

export class CategoryModal extends Component {
	
	state = {
		category : '',
		dataFile : null,
		file: null,
		validate: 'block'
	}


	
	
	handleFileChange = (file64) => {
		// if(file64===undefined) {
		// 	file64 = null
		// }

		this.setState({
			dataFile: file64
		},()=>{
			this.props.onInventoryCategoryChange({
				category:this.state.category,
				changeIcon: this.state.dataFile
			})
		})
			
		// this.props.onInventoryCategoryChange({changeIcon:file64})
	}

	handleCategoryChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value.toUpperCase(),
			validate: (event.target.value.length > 0)?'none':'block'
		},()=>{
			// console.log(this.state)
			this.props.onInventoryCategoryChange({
				category:this.state.category,
				changeIcon: this.state.dataFile,
				
			})
		})
		
			
	}

	handleSaveButton = async () =>{
		
		
		if(this.state.validate === 'none'){
			this.props.onSaveBtn(true)
		}
		
		
		

		
		

		

	}


	render() {
		

		return (
			<React.Fragment>
				
				<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				>
					
						<div className="modal-dialog modal-dialog-centered mw-650px">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Category</h5>

									
									<div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
										<span className="svg-icon svg-icon-2x"></span>
									</div>
									
								</div>
									<div className="modal-body">
										<div className="mb-5 fv-row fv-plugins-icon-container">
																		
											<label className="fs-5 fw-bold mb-2">Category</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.inventory_category.category}
												onChange={this.handleCategoryChange}
												name="category"
												placeholder={(this.state.validate === 'block')?'Type category name':'type Category'}
												
											/>

											

											<label className="fs-5 fw-bold mb-2">Icon</label>
											<img style={{width: 50}}src={(this.props.inventory_category.icon !== null)?this.props.inventory_category.icon:`../../assets/media/icons/duotune/abstract/abs038.svg`} alt='icon'/>	
											<span style={
												{
													display: "block",
													color: "grey",
													fontSize: "10px",
													fontStyle: "italic"

													}
												}>
													( Icon file max 100x100 pixel )
												</span>
											<UploadFile 
												onFileChange={(value) => this.handleFileChange(value)}
												width={50}
											/>

											
										</div>
										
 



									</div>

									<div className="modal-footer">
										<button type="button" className="btn btn-light" onClick= {this.props.closeCategoryModal}>Close</button>
										<button type="button" className="btn btn-primary" onClick = {this.handleSaveButton} disabled={(this.state.validate==='none')?false:true}>Save changes</button>
									</div>
							</div>
						</div>





				</Modal>
			</React.Fragment>
			

			
		)
	}
}




