/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import AuthToolbar from '../Layout/AuthToolbar'

import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Autocomplete from '@mui/material/Autocomplete';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import {dataURLtoFile} from '../Helper/Helper';


import TextareaAutosize from '@mui/material/TextareaAutosize';


import Swal from 'sweetalert2/dist/sweetalert2.js'
import axios from 'axios'

import { useForm } from "react-hook-form";

export default function ProductFormContent() {
	const breadcumb = [
		{
			item: 'Home',
			path: '/'
		},
		{
			item: 'Products',
			path: '/products'
		},
		{
			item: `Create new Product`,
			path: ''
		},
		
	]

	const [product,setProduct] = useState(null) 
	const [optionPublisher,setOptionPublisher] = useState([])
	const [fullname,setFullname] = useState('')
	const [publisher,setPublisher] = useState(null)
	const [userInventory,setUserInventory] = useState(null)
	const [asset,setAsset] = useState({
		user_inventory_item_id: "",
		user_inventory_id: "",
		item_name: "",
		status: "waiting",
		ms_province_id: "",
		ms_city_id: "",
		ms_district_id: "",
		ms_village_id: "",
		lat: "",
		long: "",
		social_account: "",
		follower: "",
		social_image: "",
		video_link: "",
		multiproduct: "false",
	})

	const [user_inventory_items, setUserInventoryItems] = useState([])
	const [inventory_categories, setInventoryCategories] = useState([])


	const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
		defaultValues: {
			
		},
	});

	const onSubmit = async (data) => {
		Swal.fire({
			title: 'Do you want to save the changes?',
			showDenyButton: true,
			showCancelButton: true,
			confirmButtonText: 'Save',
			denyButtonText: `Don't save`,
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
			   SaveAction(data)
			} else if (result.isDenied) {
				window.location.href='/products'
			}
		})
	}

	const SaveAction = async (data) =>{
		console.log('data',data)
		const requestBody = data
		const params = {
			reqBody: requestBody,
			method: API.USER_PRODUCT_CREATE.type,
			url: API.USER_PRODUCT_CREATE.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			console.log('response',response)
			window.location.href='/products'

		}


	}

	const handleAutoCompleteChange = (event,value) =>{
		if(typeof value === 'object'){
			setPublisher(value)
		}else{
			return false
		}
		
	}

	useEffect(() => {
		console.log('publisher',publisher)
	}, [publisher])

	

	const handlePublisherChange = async(event) => {
		console.log('handlePublisherChange event')
		reset();
		setPublisher(null)
		setUserInventory(null)


		const requestBody = {
			fullname: event.target.value
		}

		const params = {
			reqBody: requestBody,
			method: API.USER_PUBLISHER.type,
			url: API.USER_PUBLISHER.url,
			access: 'auth' 
		}

		const response = await Api(params)
		let dts = []
		let dt
		if(response.status){
			for (const i of response.data) {
				dt = i
				dt['label'] = i.fullname
				dts.push(dt)
			}
			setOptionPublisher(dts)
		}
	}

	const handleInventoryChange = (event) =>{
		const res = publisher.user_inventories.find(({ user_inventory_id }) => user_inventory_id == event.target.value)
		setAsset({
			...asset,
			user_inventory_id: res.user_inventory_id,
		
		})
		setUserInventory(res)
		setValue('user_inventory_item_id', '', { shouldValidate: true })
		setValue('inventory_category_id', '', { shouldValidate: true })

	}

	useEffect(() => {
		console.log('userInventory',userInventory)
		if(userInventory !== null ){
			const items_valid = userInventory.user_inventory_items.filter(({status})=>status == 'validate')
			console.log('userInventory.user_inventory_items',userInventory.user_inventory_items)
			console.log('items_valid',items_valid)
			setUserInventoryItems(items_valid)
			setInventoryCategories(userInventory.inventory.inventory_categories)
		}
	}, [userInventory])
	return (
		<React.Fragment>
			<AuthToolbar 
				title= {`Create new Product`}
				breadcumb = {breadcumb}
			/>

			<Box
				className="container-xxl"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
					m: 0,
					width: "100%",

					},
				}}
			>
				
				<Paper 
					elevation={3} 
					sx = {{
						p: 5
					}}
					className="col-xl-12 papper"
				>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form-title">
							Form Pembuatan Product
						</div>

						<div className="form-content">
							<FormControl fullWidth>
								<Autocomplete
									id="free-solo-demo"
									autoComplete = {true}
									freeSolo
									onChange={handleAutoCompleteChange}
									options={optionPublisher}
									isOptionEqualToValue = {(option,value) => option.label === value.label}
									renderInput={(params) => 
										<TextField {...params} 
											label="Nama publisher" 
											variant="standard"
											value={fullname}
											onChange={handlePublisherChange}
										/>}
								/>
							</FormControl>

							{(publisher)?(
								<React.Fragment>
									<FormControl component="fieldset">
										<FormLabel component="legend">Inventory tersedia</FormLabel>
										<RadioGroup row aria-label="gender" name="row-radio-buttons-group">
											{(publisher.user_inventories.map((data,i)=>(
												<FormControlLabel key={i} 
													value={data.user_inventory_id} 
													
													control={<Radio />} 
													label={data.inventory.inventory} 
													search_base= {data.inventory.search_base}
													onChange={handleInventoryChange}
												/>
											)))}
										</RadioGroup>
									</FormControl>

									<FormControl fullWidth>
										<FormLabel component="legend">Asset</FormLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											label="Asset" 
											variant="standard"
											{...register("user_inventory_item_id",{required: true})}
										>
											{user_inventory_items.map((data,i)=>(
												<MenuItem key={i} value={data.user_inventory_item_id}>{data.item_name}</MenuItem>
											))}
										</Select> 
										<div className="error-message">{errors.user_inventory_item_id && "asset is required"}</div> 
									</FormControl>

									<FormControl fullWidth>
										<FormLabel component="legend">Category</FormLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											label="Asset" 
											variant="standard"
											{...register("inventory_category_id",{required: true})}
										>
											{inventory_categories.map((data,i)=>(
												<MenuItem key={i} value={data.inventory_category_id}>{data.category}</MenuItem>
											))}
										</Select> 
										<div className="error-message">{errors.inventory_category_id && "category is required"}</div> 
									</FormControl>

									<FormControl fullWidth>
										
										<TextField  
											label="Product" 
											variant="standard"
											{...register("product",{required: true})}
											
											
										/>
										<div className="error-message">{errors.product && "product name is required"}</div> 
									</FormControl>
									
									
									

									<FormControl >
										
										<TextField  
											label="Price" 
											variant="standard"
											type="number"
											{...register("price")}
											
										/>
									</FormControl>

									<FormControl >
										
										<TextField  
											label="Discount Price" 
											variant="standard"
											type="number"
											{...register("discount_price")}
											
										/>
									</FormControl>

									<FormControl fullWidth>
										<FormControlLabel control={
												<Switch 
													defaultChecked 
													{...register("call_price")}
												/>
											} 
											label="Call Price" 
										/>
									</FormControl>

									<FormControl fullWidth>
										<FormControlLabel control={
												<Switch 
													defaultChecked 
													{...register("call_us")}
													
												/>
											} 
											label="Call Us" />
									</FormControl>

									<FormControl fullWidth>
										<FormLabel component="legend">Deskripsi</FormLabel>
										<TextareaAutosize
											aria-label="empty textarea"
											placeholder="description"											
											minRows={3}
											{...register("description",{required: true})}
										/>
										<div className="error-message">{errors.description && "product description is required"}</div> 
									</FormControl>

									<input type="hidden" value="ACTIVE" {...register("status",{required: true})}/>

								</React.Fragment>
							):(``)}

						</div>

						{(publisher)?(
							<React.Fragment>
								<div className="form-title" style={{paddingTop:"10px"}}>
									Product Profile
								</div>

								<div className="form-content">
									<FormControl fullWidth className="form-input-container">
										<div className="form-item-label">Unit</div>
										<TextField  
											variant="outlined"
											{...register("user_product_setting.unit",{required: true})}
										/>
										<div></div>
										{(!!errors.user_product_setting)?(
											<div className="error-message">{errors.user_product_setting.unit && "unit is required"}</div> 
										):(``)}
										
									</FormControl>

									<FormControl fullWidth className="form-input-container">
										<div className="form-item-label">Unit description</div>
										<TextareaAutosize
											aria-label="empty textarea"
											placeholder="description"	
											variant="outlined"										
											minRows={3}
											{...register("user_product_setting.unit_desc",{required: true})}
										/>
										<div></div>
										{(!!errors.user_product_setting)?(
										<div className="error-message">{errors.user_product_setting.unit_desc && "unit description is required"}</div>
										):(``)}
									</FormControl>

									<FormControl fullWidth className="form-input-container">
										<div className="form-item-label">Customer Need Input Schedule</div>
										<RadioGroup
											row
											aria-label="customer_need_schedule"
											defaultValue="true"
											name="row-radio-buttons-group"
											
										>
											<FormControlLabel {...register("user_product_setting.customer_need_schedule")} value="true" control={<Radio />} label="Yes" />
											<FormControlLabel {...register("user_product_setting.customer_need_schedule")} value="false" control={<Radio />} label="No" />
										</RadioGroup>
									</FormControl>

									<FormControl fullWidth className="form-input-container">
										<div className="form-item-label">Schedule Type</div>
										<RadioGroup
											row
											aria-label="schedule_type"
											defaultValue="Daily"
											name="row-radio-buttons-group"
											
										>
											<FormControlLabel {...register("user_product_setting.schedule_type")} value="Daily" control={<Radio />} label="Daily" />
											<FormControlLabel {...register("user_product_setting.schedule_type")} value="Monthly" control={<Radio />} label="Monthly" />
											<FormControlLabel {...register("user_product_setting.schedule_type")} value="Annual" control={<Radio />} label="Annual" />
											<FormControlLabel {...register("user_product_setting.schedule_type")} value="Unit" control={<Radio />} label="Unit" />
										</RadioGroup>
									</FormControl>

									<FormControl fullWidth className="form-input-container">
										<div className="form-item-label">Publish duration</div>
										<TextField  
											variant="outlined"
											{...register("user_product_setting.publish_duration",{required: true})}
										/>
										<div></div>
										{(!!errors.user_product_setting)?(
										<div className="error-message">{errors.user_product_setting.publish_duration && "publish duration is required"}</div>
										):(``)}
									</FormControl>

									
									
									<FormControl fullWidth className="form-input-container">
										<div className="form-item-label">Customer need upload file</div>
										<RadioGroup
											row
											aria-label="customer_need_upload_file"
											defaultValue="true"
											name="row-radio-buttons-group"
											
										>
											<FormControlLabel {...register("user_product_setting.customer_need_upload_file")} value="true" control={<Radio />} label="Yes" />
											<FormControlLabel {...register("user_product_setting.customer_need_upload_file")} value="false" control={<Radio />} label="No" />
										</RadioGroup>
										
									</FormControl>

									<FormControl fullWidth className="form-input-container">
										<div className="form-item-label">Content Create By</div>
										<RadioGroup
											row
											aria-label="content_create_by"
											defaultValue="customer"
											name="row-radio-buttons-group"
											
										>
											<FormControlLabel {...register("user_product_setting.content_create_by")} value="customer" control={<Radio />} label="Customer" />
											<FormControlLabel {...register("user_product_setting.content_create_by")} value="publisher" control={<Radio />} label="Publisher" />
										</RadioGroup>
									</FormControl>

									<FormControl fullWidth className="form-input-container">
										<div className="form-item-label">Content file Type</div>
										<RadioGroup
											row
											aria-label="content_file_type"
											defaultValue="audio"
											name="row-radio-buttons-group"
											
										>
											<FormControlLabel {...register("user_product_setting.content_file_type")} value="audio" control={<Radio />} label="Audio" />
											<FormControlLabel {...register("user_product_setting.content_file_type")} value="video" control={<Radio />} label="Video" />
											<FormControlLabel {...register("user_product_setting.content_file_type")} value="image" control={<Radio />} label="Image" />
											<FormControlLabel {...register("user_product_setting.content_file_type")} value="other" control={<Radio />} label="Other" />
										</RadioGroup>
									</FormControl>

									<FormControl fullWidth className="form-input-container">
										<div className="form-item-label">Video Vertical Resolution (pixel)</div>
										<TextField  
											variant="outlined"
											{...register("user_product_setting.video_vres")}
										/>
									</FormControl>

									<FormControl fullWidth className="form-input-container">
										<div className="form-item-label">Video Horizontal Resolution (pixel)</div>
										<TextField  
											variant="outlined"
											{...register("user_product_setting.video_hres")}
										/>
									</FormControl>

									<FormControl fullWidth className="form-input-container">
										<div className="form-item-label">Content duration</div>
										<TextField  
											variant="outlined"
											{...register("user_product_setting.content_file_duration",{required: true})}
										/>

										<div></div>
										{(!!errors.user_product_setting)?(
										<div className="error-message">{errors.user_product_setting.content_file_duration && "content duration is required"}</div>
										):(``)}
									</FormControl>

									
								</div>
							</React.Fragment>
						):(``)}

						

						<div className="form-action">
							<button type="button" style={{marginRight: 10}} className="btn btn-light" onClick= {()=>window.history.back()}>Close</button>
							<button type="submit"  className="btn btn-primary" >Save changes</button>
						</div>
					</form>
				</Paper>
			</Box>
		</React.Fragment>
	)
}
