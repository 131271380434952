import React, { Component } from 'react'
import AuthToolbar from '../Layout/AuthToolbar';

import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';
import ChannelData from './ChannelData';
import ChannelModal from './ChannelModal';
import ProfileModal from './ProfileModal';
import {uploadToCDN} from '../Helper/Helper';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import './ChannelData.css';
import Pagination from '@mui/material/Pagination';

const breadcumb = [
	{
		item: 'Home',
		path: '/'
	},
	{
		item: 'Publishers',
		path: ''
	},
	{
		item: 'Channel',
		path: ''
	}
]

export default class ChannelContent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			Pagination : {
				totalPage: 1,
				activePage: 1,
				perPage: 5,

			},
			user: {
				user_id: "",
				email: "",
				fullname: "",

			},
			inventory: {
				inventory_id: "",
				inventory: ""
			},
			user_inventory_items: [],
			user_inventory_item: {
				user_inventory_item_id: "",
				user_inventory_id: this.props.id,
				item_name: "",
				ms_province_id: "",
				ms_city_id: "",
				ms_district_id: "",
				ms_village_id: "",
				social_account: "",
				follower: "",
				social_image: "",
				social_image_change : null,
				status: 'Waiting',
				lat: "",
				long: ""

			},
			inventory_profile: {
				user_inventory_item_id: "",
				profile_key: "",
				profile_label: "",
				profile_desc: "",
				profile_value: "",


			},
			inventory_profiles: [],
			ms_provinces: [],
			ms_province_id: "",
			ms_cities: [],
			ms_city_id: "",
			ms_districts: [],
			ms_district_id: "",
			ms_villages:[],
			ms_village_id: "",
			order: [],
			filter: [],
			showModal: false,
			actionMode : 'NEW',
			profileModal: false,
		}
	}

	componentDidMount(){
		// console.log(this.props)
		this.reloadContentData()
	}


	reloadContentData = async () => {
		let requestBody;
		let params;
		let response;

		requestBody = {
			user_inventory_id: this.props.id,
			
		}
		params = {
			reqBody: requestBody,
			method: API.USER_INVENTORY_GET.type,
			url: API.USER_INVENTORY_GET.url,
			access: 'auth' 
		}

		response = await Api(params)
		if(response.status){

			console.log('response.data.inventory',response.data.inventory)
			this.setState({
				user: response.data.user,
				inventory: response.data.inventory,
				inventory_profiles: response.data.inventory.profile
				// Partners : response.data.data
			},()=>{
				console.log('this.state.inventory_profiles',this.state.inventory_profiles)
				this.reloadDetailData()
				this.loadProvince()
			})
		}

		


	}

	loadProvince = async() => {
		const requestBody = null;

		const params = {
			reqBody: requestBody,
			method: API.PROVINCE.type,
			url: API.PROVINCE.url,
			access: 'none' 
		}

		const response = await Api(params,true)
		if(response.status){

			
			this.setState({
				ms_provinces: response.data
				
			},()=>{
				// console.log(this.state)
				
			})
		}

	}


	reloadDetailData = async () => {
		const requestBody = {
			user_inventory_id: this.props.id,
			page: this.state.Pagination.activePage,
			rows: this.state.Pagination.perPage,
			filter: this.state.filter,
		}
		const params = {
			reqBody: requestBody,
			method: API.USER_INVENTORY_ITEMS_GETS.type,
			url: API.USER_INVENTORY_ITEMS_GETS.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){

			
			this.setState({
				Pagination: {
					totalPage: response.data.lastPage,
					activePage: response.data.page,
					perPage: response.data.perPage,
				},
				user_inventory_items: response.data.data
			},()=>{
				// console.log(this.state)
				
			})
		}
	}

	handleClose = () => {
		this.setState({
			showModal: false
		})
	}

	createNew = ()=> {
		console.log('new')
		this.setState({
			user_inventory_item: {
				user_inventory_item_id: '',
				user_inventory_id: this.props.id,
				item_name: '',
				ms_province_id: '',
				ms_city_id: '',
				ms_district_id: '',
				ms_village_id: '',
				social_account: '',
				follower: '',
				social_image: '',
				social_image_change: null,
				status: 'Waiting'

			},
			ms_cities: [],
			ms_districts: [],
			ms_villages: [],
			showModal: true,
			actionMode: 'NEW'
		})
	}

	handleChange = async (event) => {
		let requestBody = null;
		let params;
		let response;
		

		

		let a = this.state.user_inventory_item
		a[event.target.name] = event.target.value;
		this.setState({
			user_inventory_item: a
		},async ()=>{
			console.log(this.state.user_inventory_item.ms_province_id)
			switch (event.target.name) {
				case 'ms_province_id':
					if(event.target.value===""){
						a['ms_province_id'] = '';
						a['ms_city_id'] = '';
						a['ms_district_id'] = '';
						a['ms_village_id'] = '';

						this.setState({
							user_inventory_item: a,
							ms_cities: [],
							ms_districts: [],
							ms_villages: []
						})
					}else{
						requestBody = {
							ms_province_id: this.state.user_inventory_item.ms_province_id
						};
						params = {
							reqBody: requestBody,
							method: API.CITY.type,
							url: API.CITY.url,
							access: 'none' 
						}
	
						response = await Api(params,true)
						if(response.status){
							this.setState({
								ms_cities: response.data
							},()=>{
								// console.log(this.state)
							})
						}



					}
					
					break;
				case 'ms_city_id':
					if(event.target.value===""){
						a['ms_city_id'] = '';
						a['ms_district_id'] = '';
						a['ms_village_id'] = '';

						this.setState({
							user_inventory_item: a,
							ms_districts: [],
							ms_villages: []
						})
					}else{
						requestBody = {
							ms_city_id: this.state.user_inventory_item.ms_city_id
						};
						params = {
							reqBody: requestBody,
							method: API.DISTRICT.type,
							url: API.DISTRICT.url,
							access: 'none' 
						}
	
						response = await Api(params,true)
						if(response.status){
							this.setState({
								ms_districts: response.data
							},()=>{
								// console.log(this.state)
							})
						}



					}
					break;

				case 'ms_district_id':
					if(event.target.value===""){
						a['ms_village_id'] = '';

						this.setState({
							user_inventory_item: a,
							ms_villages: []
						})
					}else{
						requestBody = {
							ms_district_id: this.state.user_inventory_item.ms_district_id
						};
						params = {
							reqBody: requestBody,
							method: API.VILLAGE.type,
							url: API.VILLAGE.url,
							access: 'none' 
						}
	
						response = await Api(params,true)
						if(response.status){
							this.setState({
								ms_villages: response.data
							},()=>{
								// console.log(this.state)
							})
						}



					}
					break;
				default:
					break;
			}

			
			
		})
	}

	handleChangeImage = async (value) => {
		let a = this.state.user_inventory_item;
		console.log(value)
		if(value.changeImage !== null){
			console.log(value.changeImage)
			a['social_image_change'] = value.changeImage;
		}else {
			a['social_image_change'] = null
		}

		this.setState({
			user_inventory_item: a
		},()=>{
			console.log(this.state.user_inventory_item)
		})
	}

	handleSaveBtn = async ()=>{
		let requestBody = null;
		let params;
		let response;
		let imageURL = this.state.user_inventory_item.social_image;

		// console.log(this.state.user_inventory_item)		
		// return true;

		switch (this.state.actionMode) {
			case 'NEW':
				console.log(this.state.user_inventory_item)
				if(this.state.user_inventory_item.social_image_change !== null){
					const responseCDN = await uploadToCDN(this.state.user_inventory_item.social_image_change.data_url,this.state.user_inventory_item.social_image_change.file.name)
					if(!responseCDN.status){
						Swal.fire({
							icon: 'error',
							text: responseCDN.message,
						})
						return false;
					}else{
						imageURL = responseCDN.data.url;
					}
				}

				requestBody = {
					user_inventory_id: this.state.user_inventory_item.user_inventory_id,
					item_name: this.state.user_inventory_item.item_name,
					ms_province_id: this.state.user_inventory_item.ms_province_id,
					ms_city_id: this.state.user_inventory_item.ms_city_id,
					ms_district_id: this.state.user_inventory_item.ms_district_id,
					ms_village_id: this.state.user_inventory_item.ms_village_id,
					follower: this.state.user_inventory_item.follower,
					social_account: this.state.user_inventory_item.social_account,
					social_image: imageURL,
					lat: this.state.user_inventory_item.lat,
					long: this.state.user_inventory_item.long,


				};
				params = {
					reqBody: requestBody,
					method: API.USER_INVENTORY_ITEMS_CREATE.type,
					url: API.USER_INVENTORY_ITEMS_CREATE.url,
					access: 'auth' 
				}

				response = await Api(params)
				if(response.status){
					this.setState({
						showModal: false,
					},()=>{
						this.reloadDetailData()
					})
				}


				break;
			case 'EDIT':

				if(this.state.user_inventory_item.social_image_change !== null){
					const responseCDN = await uploadToCDN(this.state.user_inventory_item.social_image_change.data_url,this.state.user_inventory_item.social_image_change.file.name)
					if(!responseCDN.status){
						Swal.fire({
							icon: 'error',
							text: responseCDN.message,
						})
						return false;
					}else{
						imageURL = responseCDN.data.url;
					}
				}

				requestBody = {
					user_inventory_item_id: this.state.user_inventory_item.user_inventory_item_id,
					user_inventory_id: this.state.user_inventory_item.user_inventory_id,
					item_name: this.state.user_inventory_item.item_name,
					ms_province_id: this.state.user_inventory_item.ms_province_id,
					ms_city_id: this.state.user_inventory_item.ms_city_id,
					ms_district_id: this.state.user_inventory_item.ms_district_id,
					ms_village_id: this.state.user_inventory_item.ms_village_id,
					follower: this.state.user_inventory_item.follower,
					social_account: this.state.user_inventory_item.social_account,
					social_image: imageURL,
					lat: this.state.user_inventory_item.lat,
					long: this.state.user_inventory_item.long,


				};
				params = {
					reqBody: requestBody,
					method: API.USER_INVENTORY_ITEMS_UPDATE.type,
					url: API.USER_INVENTORY_ITEMS_UPDATE.url,
					access: 'auth' 
				}

				response = await Api(params)
				if(response.status){
					this.setState({
						showModal: false,
					},()=>{
						this.reloadDetailData()
					})
				}
				break;
		
			default:
				break;
		}
	}

	handleEdit = async (id) => {
		console.log('Edit - '+id)

		const requestBody = {
			user_inventory_item_id: id,
		};

		const params = {
			reqBody: requestBody,
			method: API.USER_INVENTORY_ITEMS_GET.type,
			url: API.USER_INVENTORY_ITEMS_GET.url,
			access: 'auth' 
		}

		const response = await Api(params)
		
		if(response.status){
			const a = response.data;
			a['social_image_change']= null

			if(response.data.ms_city_id !== null){
				const requestBodyCity = {
					ms_province_id: a.ms_province_id,
				};
		
				const paramsCity = {
					reqBody: requestBodyCity,
					method: API.CITY.type,
					url: API.CITY.url,
					access: 'none' 
				}
		
				const responseCity = await Api(paramsCity,true)
				if(responseCity.status){
					this.setState({
						ms_cities: responseCity.data,
					})
				}


			}

			if(response.data.ms_district_id !== null){
				const requestBodyDistrict = {
					ms_city_id: a.ms_city_id,
				};
		
				const paramsDistrict = {
					reqBody: requestBodyDistrict,
					method: API.DISTRICT.type,
					url: API.DISTRICT.url,
					access: 'none' 
				}
		
				const responseCity = await Api(paramsDistrict,true)
				if(responseCity.status){
					this.setState({
						ms_districts: responseCity.data,
					})
				}
				

			}

			if(response.data.ms_district_id !== null){
				const requestBodyDistrict = {
					ms_city_id: a.ms_city_id,
				};
		
				const paramsDistrict = {
					reqBody: requestBodyDistrict,
					method: API.DISTRICT.type,
					url: API.DISTRICT.url,
					access: 'none' 
				}
		
				const responseCity = await Api(paramsDistrict,true)
				if(responseCity.status){
					this.setState({
						ms_districts: responseCity.data,
					})
				}
			}

			if(response.data.ms_village_id !== null){
				const requestBodyVillage = {
					ms_district_id: a.ms_district_id,
				};
		
				const paramsVillage = {
					reqBody: requestBodyVillage,
					method: API.VILLAGE.type,
					url: API.VILLAGE.url,
					access: 'none' 
				}
		
				const responseVillage = await Api(paramsVillage,true)
				if(responseVillage.status){
					this.setState({
						ms_villages: responseVillage.data,
					})
				}
			}


			this.setState({
				user_inventory_item: a,
				showModal: true,
				actionMode : 'EDIT',

			},()=>{
				
			})	
		}


	}

	handleDelete = async (id) => {
		console.log('Delete - '+id)
		Swal.fire({
			title: 'Delete Channel',
			text: 'Do you want to continue',
			icon: 'error',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Delete',
			confirmButtonColor: '#fc0000'
		}).then(async (result)=>{
			if(result.isConfirmed){

				const requestBody = {
					user_inventory_item_id: id,
				};
		
				const params = {
					reqBody: requestBody,
					method: API.USER_INVENTORY_ITEMS_DELETE.type,
					url: API.USER_INVENTORY_ITEMS_DELETE.url,
					access: 'auth' 
				}
		
				const response = await Api(params)
				if(response.status){
					
					this.reloadContentData()
				}

				


			}
		})

	}

	handlePaginationChange = async (event,value) =>{
		// console.log(value)
		let old_pagination = this.state.Pagination;
		old_pagination.activePage= value;
		await this.setState({
			Pagination: old_pagination
		},()=>{
			// console.log(this.state)
			this.reloadContentData()
		})

		// this.reloadContentData()
	}

	handleValidate = async (id,access) => {
		let status;
		if(access){
			console.log(id)

			Swal.fire({
				title: 'Channel Approval Comment',
				input: 'text',
				inputAttributes: {
				  autocapitalize: 'on'
				},
				showCancelButton: true,
				confirmButtonText: 'This channel is valid',
				confirmButtonColor: "#17db0d",
				showDenyButton: true,
				denyButtonText: `This channel is invalid`,
				showLoaderOnConfirm: true,
				returnInputValueOnDeny: true,
				inputValidator: (value) => {
					if (!value) {
					  return 'You need to write something!'
					}
				},
				preConfirm: (login) => {
				  
				},
				allowOutsideClick: () => !Swal.isLoading()
			}).then(async (result) => {
				console.log(result)
				if(!result.isDissmissed){
					if(result.isConfirmed){
						status = 'validate'
					}else{
						status = 'invalid'
					}
					const requestBody = {
						user_inventory_item_id: id,
						status: status,
						comment: result.value
					};
			
					const params = {
						reqBody: requestBody,
						method: API.USER_INVENTORY_ITEMS_APPROVAL.type,
						url: API.USER_INVENTORY_ITEMS_APPROVAL.url,
						access: 'auth' 
					}
			
					await Api(params)

				}
				
				
			})

		}
		
	}

	handleAddProfile = async (id) => {
		this.setState({
			inventory_profile: {
				user_inventory_item_id: id,
				profile_key: this.state.inventory_profiles[0] || "",
				profile_value: "",
			},
			profileModal: true
		})

	}

	handleProfileClose = () => {
		this.setState({
			profileModal: false,

		})
	}

	handleProfileChange = (event) => {
		console.log("event.target.name",event.target.name)
		const a = this.state.inventory_profile
		a[event.target.name]=event.target.value
		this.setState({
			inventory_profile: a
		})
	}

	handleProfileSave = async () =>{
		const requestBody = {
			user_inventory_item_id: this.state.inventory_profile.user_inventory_item_id,
			profile_key: this.state.inventory_profile.profile_key,
			profile_value: this.state.inventory_profile.profile_value,
		};

		const params = {
			reqBody: requestBody,
			method: API.ITEM_PROFILE_CREATE.type,
			url: API.ITEM_PROFILE_CREATE.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			this.setState({
				profileModal: false,

			})
			this.reloadContentData()
		}

	}

	handleDeleteProfile = async (id) => {
		const requestBody = {
			item_profile_id: id,
		};

		const params = {
			reqBody: requestBody,
			method: API.ITEM_PROFILE_DELETE.type,
			url: API.ITEM_PROFILE_DELETE.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			
			this.reloadContentData()
		}

	}
	render() {
		return (
			<React.Fragment>
				<AuthToolbar 
					title='Channel'
					breadcumb = {breadcumb}
				/>

				<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
					<div className="content flex-row-fluid" id="kt_content">
						<div className="row gy-5 g-xl-8">
							<div className="col-xl-12">



								<div className="card" style={{marginBottom:20}} >
									<div className="card-header card-header-stretch">
										<h3 className="card-title align-items-start flex-column">
											<span className="card-label fw-bolder text-dark">{this.state.user.fullname}</span>
											<span className="text-muted mt-1 fw-bold fs-7">{this.state.inventory.inventory}</span>
											
										</h3>
										
									</div>
									<div className="card-body">
										<div>
											

											{(this.state.user_inventory_items.length === 0)?`No Channel defined`:
											(
												<ChannelData 
													data={this.state.user_inventory_items}
													onEditBtn={(value)=>this.handleEdit(value)}
													onDeleteBtn={(value)=>this.handleDelete(value)}
													onClickStatus = {(value,access)=>this.handleValidate(value,access)}
													onAddProfile = {(value)=>this.handleAddProfile(value)}
													onDeleteProfile = {(val)=>this.handleDeleteProfile(val)}
												/>
											)
											}
										</div>
										<Pagination count={this.state.Pagination.totalPage}  variant="outlined" shape="rounded" onChange={this.handlePaginationChange} />
									</div>
									
									<div className="card-footer">
										<button className="btn btn-primary btn-sm btn-light" onClick={this.createNew} style={{marginRight:10}} >Create new Channel</button>
										
										
									</div>
									
								</div>



							</div>
						</div>
					</div>
				</div>

				<ChannelModal
					open= {this.state.showModal}
					data= {this.state.user_inventory_item}
					closeModal = {this.handleClose}
					inventory = {this.state.inventory}
					provinces = {this.state.ms_provinces}
					cities = {this.state.ms_cities}
					districts = {this.state.ms_districts}
					villages = {this.state.ms_villages}
					onChange={this.handleChange}
					onChangeImage={(value)=>this.handleChangeImage(value)}
					onSaveBtn={this.handleSaveBtn}
					
					
				/>

				<ProfileModal
					open={this.state.profileModal}
					onClose = {this.handleProfileClose}
					inventory_profiles={this.state.inventory_profiles}
					data = {this.state.inventory_profile}
					onChange = {this.handleProfileChange}
					onSave = {this.handleProfileSave}
					
				/>
			</React.Fragment>
		)
	}
}


