import React, { useState, useEffect } from 'react'
import AuthToolbar from '../Layout/AuthToolbar'
import Pagination from '@mui/material/Pagination';
import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';
import '../Pagination/Pagination.css';
import PublisherData from './PublisherData';
import './Publisher.css'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useStateWithCallbackLazy } from 'use-state-with-callback';

import ChannelModal from './ChannelModal'


import UserModal from '../Users/UserModal'



const breadcumb = [
    {
        item: 'Home',
        path: '/'
    },
    {
        item: 'Publishers',
        path: ''
    }
]


export default function PublisherContent() {

	const [Page,setPage] = useState({
		totalPage: 1,
		activePage: 1,
		perPage: 5,
	})

	const [Inventories,setInventories] = useState([])
	const [Inventory,setInventory] = useState(null)
	const [Publishers,setPublishers] = useState([])
	const [Publisher,setPublisher] = useState({
		user_id: '',
		fullname: '',
		email: '',
		phone_number: '',
		status: '',
	})

	const [selectedPublisher, setSelectedPublisher] = useState(null)

	const [User,setUser] = useStateWithCallbackLazy({
		user_id: '',
		fullname: '',
		email: '',
		phone_number: '',
		is_admin: 'false',
		status: 'NOT ACTIVE',
		company_id: '',
		group_id: '',
		ms_province_id: '',
		ms_city_id: '',
		password: '',
		repeat_password: '',
	})

	const [filter, setFilter] = useState([
		{
			field: 'fullname',
			operator: 'LIKE',
			value: '%'
		},
		{
			field: 'email',
			operator: 'LIKE',
			value: '%'
		},
		{
			field: 'status',
			operator: 'LIKE',
			value: '%'
		},
		{
			field: 'is_admin',
			value: 'false'
		},

	])

	const [showUserModal,setShowUserModal] = useState(false)
	const [showInventoryModal,setShowInventoryModal] = useState(false)
	const [inventoryActionMode,setInventoryActionMode] = useState('NEW')
	const [userActionMode, setUserActionMode] = useState('NEW')
	const [redirect,setRedirect] = useState(false)
	const [redirect_url,setRedirectURL] = useState('')
	const [companies,setCompanies] = useState([])
	const [channels,setChannels] = useState([])

	const [userInventories,setUserInventories] = useState([])
	const [showChannelModal,setShowChannelModal] = useState(false)
	const [inventoryUser,setInventoryUser] =useState([])
	let defaultUserInventories = []


	

	const getDefaultChannel = async() => {
		const params = {
			reqBody: [],
			method: API.INVENTORIES_GET_ALL.type,
			url: API.INVENTORIES_GET_ALL.url,
			access: 'auth' 
		}

        const response = await Api(params)
		// console.log(response)
		if(response.status){
			defaultUserInventories = []
			for (const i of response.data) {
				defaultUserInventories.push({
					user_inventory_id: '',
					inventory_id: i.inventory_id,
					inventory: i.inventory,
					on: false
				})
			}
		}
	}

	const getInventories = async () => {
		const params = {
			reqBody: [],
			method: API.INVENTORIES_GET_ALL.type,
			url: API.INVENTORIES_GET_ALL.url,
			access: 'auth' 
		}

        const response = await Api(params)
		// console.log(response)
		if(response.status){
			setInventories(response.data)
		}
	}

	const reloadContentData = async (page) => {
		console.log('page',page)
		const requestBody = {
			page: page,
 			rows: Page.perPage,
			filter: filter,
		}
		const params = {
			reqBody: requestBody,
			method: API.USER_GETS.type,
			url: API.USER_GETS.url,
			access: 'auth' 
		}

		const response = await Api(params)
		// console.log(response)
		if(response.status){

			setPage({
				totalPage: response.data.lastPage,
				activePage: response.data.page,
				perPage: response.data.perPage,
			})

			
			setPublishers(response.data.data)
		}
	}

	useEffect(() => {
	}, [Publishers])

	const getCompanies = async () => {
		const params = {
			reqBody: null,
			method: API.COMPANY_GET_ALL.type,
			url: API.COMPANY_GET_ALL.url,
			access: 'auth' 
		}

		const response = await Api(params)
		// console.log(response)
		if(response.status){

			setCompanies(response.data)			
		}
	}
	

	const handleCreatePublisher = async () => {

		setUser({
				user_id: '',
				fullname: '',
				email: '',
				phone_number: '',
				is_admin: 'false',
				status: 'NOT ACTIVE',
				company_id: '',
				group_id: '',
				ms_province_id: '',
				ms_city_id: '',
				password: '',
				retype_password: '',
		},()=>{
			console.log('callback create')
			
			
			setUserActionMode("NEW")
			setShowUserModal(true)
			console.log('channels',channels)
		})
		
		
		
	}

	useEffect(() => {
		if(userActionMode === "NEW"){
			setChannels(defaultUserInventories)
		}
	}, [userActionMode])

	const handlePaginationChange = async (event,value) =>{

		reloadContentData(value)
	}

	const handleCloseUserModal = () => {
		setShowUserModal(false)
	}

	const handleChangeUser = async (event) => {
		setUser({
			...User,
			[event.target.name]:event.target.value
		})
	}


	const handleUserSave = async () => {
		let params
		let requestBody

		console.log("User",User)
		

		switch (userActionMode) {
			case "NEW":
				requestBody = {
					email: User.email,
					fullname: User.fullname,
					password: User.password,
					retype_password: User.retype_password,
					is_admin: User.is_admin,
					company_id: User.company_id


				}
				params = {
					reqBody: requestBody,
					method: API.USER_CREATE.type,
					url: API.USER_CREATE.url,
					access: 'auth' 
				}
				break;
			case "EDIT":
				requestBody = {
					user_id: User.user_id,
					fullname: User.fullname,
					phone_number:User.phone_number,
					status: User.status,
					company_id: User.company_id
				}
				params = {
					reqBody: requestBody,
					method: API.USER_UPDATE.type,
					url: API.USER_UPDATE.url,
					access: 'auth' 
				}
				break;

			case "PASSWORD":
				requestBody = {
					user_id: User.user_id,
					password: User.password,
					retype_password: User.retype_password
				}
				params = {
					reqBody: requestBody,
					method: API.USER_UPDATE.type,
					url: API.USER_UPDATE.url,
					access: 'auth' 
				}
			
			
				
				break;
			default:
				break;
		}

		const response = await Api(params)
		if(response.status){
			reloadContentData(Page.ActivePage)
			setShowUserModal(false)
		}


		
	}

	


	const handleEditPublisher = async (id) => {

		const requestBody = {
			user_id: id,
		}
		const params = {
			reqBody: requestBody,
			method: API.USER_GET.type,
			url: API.USER_GET.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			
			setUser(response.data,()=>{
				setShowUserModal(true)
				setUserActionMode("EDIT")
			})
			
			
			
			
			
		}

	}

	useEffect(() => {
		getInventories()
		console.log('awal load page',Page)
		reloadContentData(Page.activePage)
		getCompanies()
		getDefaultChannel()
	},[])

	const handleAction = async (action,id) =>{
		switch (action) {
			case 'channel':
				window.location.href = `/publishers/channel/${id}`;

				break;
			case 'delete':

				const requestBody = {
					user_inventory_id: id,
				}
				const params = {
					reqBody: requestBody,
					method: API.USER_INVENTORY_DELETE.type,
					url: API.USER_INVENTORY_DELETE.url,
					access: 'auth' 
				}
		
				const response = await Api(params)
				// console.log(response)
				if(response.status){
		
					reloadContentData(Page.ActivePage)
				}
				break;
					
		
			default:
				break;
		}
	}


	const handleAddUserInventory = async () =>{
		if((typeof inventoryID) !=='object' ){
			const requestBody = {
				user_id: selectedPublisher,
				inventory_id: Inventory,
			}
			const params = {
				reqBody: requestBody,
				method: API.USER_INVENTORY_CREATE.type,
				url: API.USER_INVENTORY_CREATE.url,
				access: 'auth' 
			}
	
			const response = await Api(params)
			// console.log(response)
			if(response.status){
	
				reloadContentData(Page.ActivePage)
			}
		}
		

    }

	const handleChannelBtn = (id) => {

	}

	const handleAddChannel = async (id) => {
		const requestBody = {
			user_id: id,
			
		}
		const params = {
			reqBody: requestBody,
			method: API.INVENTORIES_USER.type,
			url: API.INVENTORIES_USER.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			console.log('response.data', response.data)
			setInventoryUser(response.data)
			setInventory(null)
			setShowChannelModal(true)
			setSelectedPublisher(id)
		}
		
	}

	const handleInventoryChange = (event) => {
		setInventory(event.target.value)
	}

	useEffect(() => {
		console.log('Inventory',Inventory)
	}, [Inventory])

	const handleCreateChannel = async () => {
		
	}

	return (
		
		<React.Fragment>
			<AuthToolbar 
				title='Publishers'
				breadcumb = {breadcumb}
			/>
			
			<Box
				className="container-xxl"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
					m: 0,
					width: "100%",

					},
				}}
			>

                

				<Paper 
					elevation={3} 
					sx = {{
						p: 5
					}}
					className="col-xl-12 papper"
				>
						
					<div className="header-container">
						<div className="search-item">
							<Paper
								component="form"
								sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
							>
								<IconButton sx={{ p: '10px' }} aria-label="menu">
									<SearchIcon />
								</IconButton>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder="Publisher name or email address"
									inputProps={{ 'aria-label': 'search google maps' }}
								/>
							
								<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
								<IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
									<DirectionsIcon />
								</IconButton>
							</Paper>
						</div>

						<div className="action-item">
							<Button variant="contained" onClick={handleCreatePublisher}>Add Publisher</Button>
						</div>
					</div>
					<div className="table-container">
						<Table 
							sx={{ minWidth: 650,width:"100%",fontSize:"20px",marginTop: 3, marginBottom: 3 }} 
							aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Publisher</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Phone</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Channel</TableCell>
									<TableCell>Action</TableCell>

								</TableRow>
							</TableHead>

							<TableBody>
							{Publishers.map((data,i)=>(



								<PublisherData 
									key={data.user_id}
									data={data}
									addInventoryBtn={(value)=>this.handleAddInventory(value)}
									Inventories={Inventories}
									action={(action,id)=>{handleAction(action,id)}}
									onClickProduct={(value)=>this.handleProduct(value)}
									onEditPublisher={(value)=>handleEditPublisher(value)}
									onAddChannel={(id)=>handleAddChannel(id)}
									

								/>	
							))}
							</TableBody>
						</Table>
					</div>

						

					<Pagination count={Page.totalPage}  variant="outlined" shape="rounded" onChange={handlePaginationChange} />
							
						
				</Paper>
				

			</Box>

			<UserModal 
				user={User}
				title="Publisher"
				open= {showUserModal}
				closeUserModal = {handleCloseUserModal}
				companies={companies}
				onChangeUser={handleChangeUser}
				onSaveBtn={handleUserSave}
				modalMode={userActionMode}
				channels={channels}
				
			
			/>

			<ChannelModal
				open={showChannelModal}
				data={inventoryUser}
				inventory={Inventory}
				inventoryChange={handleInventoryChange}
				closeChannelModal = {()=>{setShowChannelModal(false)}}
				onChannelAdd = {handleAddUserInventory}
			/>
        </React.Fragment>
	)
}


  







