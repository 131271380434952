import SecureLS from 'secure-ls'
var ls = new SecureLS({ encodingType: 'aes' })

export function token() {
	// const saved = localStorage.getItem("authUser");
	const saved = ls.get("authUser")
	const storage = JSON.parse(saved);

	return storage.auth.auth.token
}

export function member() {
	// const saved = localStorage.getItem("authUser");
	const saved = ls.get("authUser")
	const storage = JSON.parse(saved);

	return storage.auth.member
}

export function userEmail() {
	// const saved = localStorage.getItem("authUser");
	const saved = ls.get("authUser")
	const storage = JSON.parse(saved);

	return storage.auth.email
}

export function userFullname() {
	// const saved = localStorage.getItem("authUser");
	const saved = ls.get("authUser")
	const storage = JSON.parse(saved);

	return storage.auth.fullname
}
export function userID() {
	// const saved = localStorage.getItem("authUser");
	const saved = ls.get("authUser")
	const storage = JSON.parse(saved);

	return storage.auth.user_id
}
export function companyID() {
	// const saved = localStorage.getItem("authUser");
	const saved = ls.get("authUser")
	const storage = JSON.parse(saved);

	return storage.auth.company_id
}

export function memberType() {
	// const saved = localStorage.getItem("authUser");
	const saved = ls.get("authUser")
	const storage = JSON.parse(saved);

	const company = storage.auth.company_id
	const is_admin =  storage.auth.is_admin
	if(is_admin === 'true'){
		if(company === null){
			return 'superadmin'
		}else{
			return 'admin'
		}
	}else {
		return 'publisher'
	}
}






 
