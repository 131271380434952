import React from 'react'
import AuthFooter from '../Layout/AuthFooter'
import AuthHeader from '../Layout/AuthHeader'
import DashboardContent from './DashboardContent'
import ScrollTop from '../Landing/ScrollTop'

export default function Dashboard() {

	return (
		<div id="kt_body"  className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled" style={{backgroundImage: `url(../../assets/media/patterns/header-bg.jpg)`,backgroundRepeat  : 'no-repeat'}}>
			<div className="d-flex flex-column flex-root">
				<div className="page d-flex flex-row flex-column-fluid">
					<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
						<AuthHeader/>
				
						<DashboardContent/>
						
					</div>
				</div>
			</div>
			
			<ScrollTop/>
			<script>var hostUrl = "assets/";</script>
		</div>
	)
}
