import React, { Component } from 'react'
import AuthToolbar from '../Layout/AuthToolbar'
import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';
import { Redirect } from "react-router-dom"

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import ImageGallery from 'react-image-gallery';
import Container from '@mui/material/Container';
import Masonry from '@mui/lab/Masonry';
import Paper from '@mui/material/Paper';

import "react-image-gallery/styles/css/image-gallery.css";
import './Product.css';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import {memberType} from '../Auth/localStorage'
import Pricebook from '../Pricebook/Pricebook';
import Swal from 'sweetalert2/dist/sweetalert2.js';


const breadcumb = [
    {
        item: 'Home',
        path: '/'
    },
    {
        item: 'Product',
        path: ''
    }
]


export default class ProductContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_product: {
				user_product_id: '',
				user_inventory_item_id: '',
				inventory_category_id: '',
				product : '',
				description: '',
				price: '',
				discount_price: '',
				status: 'ACTIVE',
				url_video: '',
				expired_date: ''
			},
			user_inventory_item: {
				user_inventory_item_id: '',
				user_inventory_id: '',
				item_name: '',
				ms_city_id: '',
				ms_village_id: '',
				ms_district_id: '',
				ms_province_id: '',
				social_account: '',
				follower: '',
				social_image: '',
				status: '',
				lat: '',
				long: '',

			},
			user_product_setting: {
				user_product_setting_id: '',
				user_product_id: '',
				unit: '',
				unit_desc: '',
				customer_need_schedule: 'false',
				schedule_type: 'Daily',
				publish_duration: 1,
				customer_need_upload_file: 'true',
				content_create_by: 'customer',
				content_file_type: 'video',
				content_file_duration: '0',
				video_hres: null,
				video_vres: null
			},
			pricebook: [],
			sp_price: null,
			partners: [],
			inventory_category: '',
			inventory: '',
			search_base: '',
			search_base_detail: '',
			owner: '',
			gallery: [],
			tab: "3",
			profiles: [],
			actionMode: "NONE",

		}
	}

	componentDidMount() {
		this.getProduct()
		this.getPartner()
	}

	getPartner = async () => {
		const requestBody = {
			
		}
		const params = {
			reqBody: requestBody,
			method: API.PARTNER_GET_ALL.type,
			url: API.PARTNER_GET_ALL.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			this.setState({
				partners: response.data
			},()=>{
				for (const i of this.state.partners) {
					i['value'] = i.partner_id
					i['label'] = i.partner
				}
			})


		}
	}

	getProduct = async () => {
		const requestBody = {
			user_product_id: this.props.id,
		}
		const params = {
			reqBody: requestBody,
			method: API.USER_PRODUCT_GET.type,
			url: API.USER_PRODUCT_GET.url,
			access: 'auth' 
		}

		const response = await Api(params)
		
		if(response.status){

			let sb = '';
			switch (response.data.user_inventory_item.user_inventory.inventory.search_base) {
				case 'Villages':
					sb = `${response.data.user_inventory_item.ms_village.ms_village_name} kec. ${response.data.user_inventory_item.ms_district.ms_district_name}, ${response.data.user_inventory_item.ms_city.ms_city_name}, ${response.data.user_inventory_item.ms_province.ms_province_name}`
					break;
				case 'City':
					sb = `${response.data.user_inventory_item.ms_city.ms_city_name}, ${response.data.user_inventory_item.ms_province.ms_province_name}`
					break;
				case 'Social Media Account':
					sb = `${response.data.user_inventory_item.social_account} Follower: ${response.data.user_inventory_item.follower}`
					break;
			
				default:
					break;
			}


			this.setState({
				user_product : response.data,
				gallery: response.data.user_product_files,
				user_inventory_item: response.data.user_inventory_item,
				inventory: response.data.user_inventory_item.user_inventory.inventory.inventory,
				search_base: response.data.user_inventory_item.user_inventory.inventory.search_base,
				inventory_category: response.data.inventory_category.category,
				search_base_detail: sb,
				owner: response.data.user_inventory_item.user_inventory.user.fullname,
				profiles: response.data.user_inventory_item.item_profiles,
				user_product_setting: response.data.user_product_setting,
				pricebook: response.data.pricebook

			
			},()=>{
				console.log('response',response.data)
				// console.log('this.state.user_inventory_item',this.state.user_inventory_item)
				for (const  i of this.state.gallery) {
					i['original'] = i.file
					i['thumbnail'] = i.file
				}

				

				
			})
		}

	}

	handleTabChange = (event, newValue) =>{
		this.setState({
			tab: newValue,
		})
	}

	Profile = (datas) =>{
		return (
			datas.map((data,i)=>{
				
				<React.Fragment key={data.item_profile_id}>
					<div className="profile-label">{data.profile_label}</div>
					<div className="profile-value">{data.profile_value}</div>
				</React.Fragment>
			})
		)

	}

	handleCreateCampaign = async (id) => {

		window.location.href = `/campaign/create/${this.props.id}`
		// if (this.state.actionMode === "CREATE"){
		// 	return <Redirect to={'/campaign/create/'+this.props.id}/>
		// }
		// this.setState({
		// 	actionMode: "CREATE"
		// })
	}

	handleCreatePricebook = async (pricebook) => {
		const requestBody = {
			user_product_id: pricebook.user_product_id,
			partner_id: pricebook.partner_id,
			partner_company_id: pricebook.partner_company_id,
			partner_company_name: pricebook.partner_company_name,
			price: pricebook.price
		}
		const params = {
			reqBody: requestBody,
			method: API.PRICEBOOK_CREATE.type,
			url: API.PRICEBOOK_CREATE.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			this.getProduct()
		}
	}

	handleDeletePricebook = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
  			cancelButtonText: 'No, cancel!',
		}).then(async (result)=>{
			if(result.isConfirmed){
				const requestBody = {
					user_product_pricebook_id: id,
				}
				const params = {
					reqBody: requestBody,
					method: API.PRICEBOOK_DELETE.type,
					url: API.PRICEBOOK_DELETE.url,
					access: 'auth' 
				}
		
				const response = await Api(params)
				if(response.status){
					this.getProduct()
				}
			}
		})
	}

	render() {

		

		return (
			<React.Fragment>
				<AuthToolbar 
                    title='Product'
                    breadcumb = {breadcumb}
                />

				
				<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
					<div className="content flex-row-fluid " id="kt_content">
						<div className="container_product rounded">
							<div className="container-galery">
								<ImageGallery items={this.state.gallery}/>
							</div>
							<div className="container-item">
								<div className="sub-container">
									<div className="category">{this.state.inventory} ({this.state.inventory_category}) </div>
									<div className="channel">{this.state.user_inventory_item.item_name}</div>
									<div className="product">{this.state.user_product.product}</div>

									{((this.state.user_product.discount_price<this.state.user_product.price) && (this.state.user_product.discount_price !== null))?
									(
										<React.Fragment>
											<div className="price">
												{this.state.user_product.discount_price.toLocaleString('id-ID', {
													style: 'currency',
													currency: 'IDR',
													minimumFractionDigits: 0
												})}
											</div>
											<div className="discount-price">
												{this.state.user_product.price.toLocaleString('id-ID', {
													style: 'currency',
													currency: 'IDR',
													minimumFractionDigits: 0
												})}
											</div>

										</React.Fragment>
										
									):(
										<React.Fragment>
											<div className="price">
												{this.state.user_product.price.toLocaleString('id-ID', {
													style: 'currency',
													currency: 'IDR',
													minimumFractionDigits: 0
												})}
											</div>

										
										</React.Fragment>
										

									)}

								</div>

								<div className="search-base">
									{this.state.search_base_detail}
								</div>
								
								<div className="owner">
									Owner: {this.state.owner}
								</div>

								{((memberType()==='admin' || memberType()==='superadmin') && (this.state.user_product.status === 'ACTIVE'))?(
									<Button
										variant="contained"
										onClick={()=>this.handleCreateCampaign(this.props.id)}
									>
										Create Campaign
									</Button>
								):(``)}

								<Box sx={{ width: '100%', typography: 'body1' }}>
									<TabContext value={this.state.tab}>
										<Box sx={{ borderBottom: 1, borderColor: 'divider',fontFamily: "SF Pro Regular"  }}>
										<TabList onChange={this.handleTabChange} aria-label="lab API tabs example" sx={{fontFamily: "SF Pro Regular"}}>
											<Tab label="DETAIL" value="1" />
											<Tab label="PROFILE" value="2" />
											<Tab label="KETENTUAN" value="3" />
											<Tab label="PETA" value="4" />
											{(memberType()==='admin')?(
												<Tab label="PRICEBOOK" value="5" />
											):(``)}
										</TabList>
										</Box>
										<TabPanel value="1" className="tab-panel">{this.state.user_product.description}</TabPanel>
										<TabPanel value="2" className="tab-panel">
											
											{this.state.profiles.map((data,i)=>(
												<React.Fragment key={data.item_profile_id}>
													<div className="profile-label">{data.profile_label}</div>
													<div className="profile-value">{data.profile_value}</div>
												</React.Fragment>
												
											))}
											
										</TabPanel>
										<TabPanel value="3" className="tab-panel">
											<div className="profile-label">Satuan Jual</div>
											<div className="profile-value">{this.state.user_product_setting.unit}</div>

											<div className="profile-label">Penjelasan satuan jual</div>
											<div className="profile-value">{this.state.user_product_setting.unit_desc}</div>

											<div className="profile-label">Penjadwalan</div>
											<div className="profile-value">{(this.state.user_product_setting.customer_need_schedule === "true")?(`Ya`):(`Tidak`)}</div>

											{(this.state.user_product_setting.customer_need_schedule === "true")?(
												<React.Fragment>
													<div className="profile-label">Jenis Penjadwalan</div>
													<div className="profile-value">{this.state.user_product_setting.schedule_type}</div>
												</React.Fragment>
											):(``)}

											<div className="profile-label">Durasi Tayang</div>
											<div className="profile-value">{this.state.user_product_setting.publish_duration}</div>

											<div className="profile-label">Butuh upload file</div>
											<div className="profile-value">{(this.state.customer_need_upload_file === "true")?(`Ya`):(`Tidak`)}</div>

											<div className="profile-label">Konten dibuat oleh</div>
											<div className="profile-value">{this.state.user_product_setting.content_create_by}</div>

											<div className="profile-label">Jenis konten</div>
											<div className="profile-value">{this.state.user_product_setting.content_file_type}</div>

											{(this.state.user_product_setting.content_file_type === "video" || this.state.user_product_setting.content_file_type === "audio")?(
												<React.Fragment>
													<div className="profile-label">Durasi</div>
													<div className="profile-value">{this.state.user_product_setting.content_file_duration} detik</div>
												</React.Fragment>
											):('')}

											{(this.state.user_product_setting.content_file_type === "video")?(
												<React.Fragment>
													<div className="profile-label">Resolusi video</div>
													<div className="profile-value">{this.state.user_product_setting.video_hres} x {this.state.user_product_setting.video_vres} pixel</div>
												</React.Fragment>
											):(``)}
										</TabPanel>
										<TabPanel value="4" className="tab-panel">Katakan peta katakan peta</TabPanel>
										<TabPanel value="5" className="tab-panel" className="papper-table">
											<Pricebook 
												data={this.state.pricebook}
												partners = {this.state.partners}
												product = {this.state.user_product}
												onCreatePricebook = {(value)=>this.handleCreatePricebook(value)}
												onDelete = {(value)=>this.handleDeletePricebook(value)}
											/>
										</TabPanel>
									</TabContext>
								</Box>

								<div className="description">
									
								</div>
								
								
								
							</div>
						</div>
						{/* <Pricebook/> */}
					</div>
					
				</div>

				

			</React.Fragment>
		)
	}
}
