/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import AuthToolbar from '../Layout/AuthToolbar'

import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Autocomplete from '@mui/material/Autocomplete';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import {dataURLtoFile} from '../Helper/Helper';


import TextareaAutosize from '@mui/material/TextareaAutosize';

 
import Swal from 'sweetalert2/dist/sweetalert2.js'
import axios from 'axios'

import { useForm } from "react-hook-form";

export default function EditProductContent(props) {
	const breadcumb = [
		{
			item: 'Home',
			path: '/'
		},
		{
			item: 'Products',
			path: '/products'
		},
		{
			item: `Edit Product`,
			path: ''
		},
		
	]

	const [product,setProduct] = useState({
		user_product_id: ``,
		product: ``,
		inventory_category_id: ``,
		price: 0,
		discount_price: 0,

		user_inventory_item: {
			item_name: ``,
			user_inventory: {
				inventory: {
					inventory: ``,
					inventory_categories: [],
				},
				user: {
					fullname: ``
				}
			}
		}
	}) 
	const [publisher,setPublisher] = useState({
		fullname: '',
	})

	const [customer_need_schedule,set_customer_need_schedule] = useState('true')
	const [schedule_type, set_schedule_type] = useState('Daily')
	const [customer_need_upload_file, set_customer_need_upload_file] = useState('true')
	const [content_create_by, set_content_create_by] = useState('publisher')
	const [content_file_type,set_content_file_type] = useState('video')
	const [inventory_category_id,set_inventory_category_id] = useState('')
	const [call_price, set_call_price] = useState('true')
	

	// const [user_inventory_items, setUserInventoryItems] = useState([])
	// const [inventory_categories, setInventoryCategories] = useState([])


	const { register, handleSubmit, setValue, formState: { errors } } = useForm({
		defaultValues: {
			
		},
	});

	useEffect( async () => {
		// load asset
		const requestBody = {
			user_product_id : props.id
		}

		

		const params = {
			reqBody: requestBody,
			method: API.USER_PRODUCT_GET.type,
			url: API.USER_PRODUCT_GET.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			setProduct(response.data)
			
			// setUserInventory(response.data.user_inventory)
		}

	}, [])

	useEffect(() => {
		if(product.user_product_id !==``){
			console.log('product',product)
			setPublisher(product.user_inventory_item.user_inventory.user)
			setValue('user_product_id',product.user_product_id)
			setValue('user_inventory_item_id',product.user_inventory_item_id)
			setValue('inventory_category_id',product.inventory_category_id)
			set_inventory_category_id(product.inventory_category_id)
			

			setValue('product',product.product)
			setValue('price',product.price)
			setValue('discount_price',product.discount_price)
			setValue('call_price',product.call_price)
			set_call_price(product.call_price)
			setValue('description',product.description)
			setValue('status',product.status)

			setValue('user_product_setting.unit',product.user_product_setting.unit)
			setValue('user_product_setting.unit_desc',product.user_product_setting.unit_desc)
			
			setValue('user_product_setting.customer_need_schedule',product.user_product_setting.customer_need_schedule)
			set_customer_need_schedule(product.user_product_setting.customer_need_schedule)
			
			setValue('user_product_setting.customer_need_upload_file',product.user_product_setting.customer_need_upload_file)
			set_customer_need_upload_file(product.user_product_setting.customer_need_upload_file)

			setValue('user_product_setting.schedule_type',product.user_product_setting.schedule_type)
			set_schedule_type(product.user_product_setting.schedule_type)
			
			setValue('user_product_setting.publish_duration',product.user_product_setting.publish_duration)
			set_schedule_type(product.user_product_setting.schedule_type)
			
			setValue('user_product_setting.content_create_by',product.user_product_setting.content_create_by)
			set_content_create_by(product.user_product_setting.content_create_by)

			setValue('user_product_setting.content_file_type',product.user_product_setting.content_file_type)
			set_content_file_type(product.user_product_setting.content_file_type)

			setValue('user_product_setting.video_vres',product.user_product_setting.video_vres)
			setValue('user_product_setting.video_hres',product.user_product_setting.video_hres)
			setValue('user_product_setting.content_file_duration',product.user_product_setting.content_file_duration)
		}
		


	},[product])

	const onSubmit = async (data) => {
		Swal.fire({
			title: 'Do you want to save the changes?',
			showDenyButton: true,
			showCancelButton: true,
			confirmButtonText: 'Save',
			denyButtonText: `Don't save`,
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				console.log('data',data)
				// return true
				data.call_price = data.call_price.toString()
			    SaveAction(data)
			} else if (result.isDenied) {
				window.location.href='/products'
			}
		})
	}

	

	const SaveAction = async (data) =>{
		
		const requestBody = data
		const params = {
			reqBody: requestBody,
			method: API.USER_PRODUCT_UPDATE.type,
			url: API.USER_PRODUCT_UPDATE.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			console.log('response',response)
			window.location.href='/products'

		}


	}

	

	useEffect(() => {
		console.log('publisher',publisher)
	}, [publisher])

	

	

	
	// useEffect(() => {
	// 	console.log('userInventory',userInventory)
	// 	if(userInventory !== null ){
	// 		const items_valid = userInventory.user_inventory_items.filter(({status})=>status == 'validate')
	// 		console.log('userInventory.user_inventory_items',userInventory.user_inventory_items)
	// 		console.log('items_valid',items_valid)
	// 		setUserInventoryItems(items_valid)
	// 		setInventoryCategories(userInventory.inventory.inventory_categories)
	// 	}
	// }, [userInventory])\

	const radioChange = (event) =>{
		console.log(event.target)
		switch (event.target.name) {
			case 'user_product_setting.customer_need_schedule':
				set_customer_need_schedule(event.target.value)
				break;
		
			case 'user_product_setting.schedule_type':
				set_schedule_type(event.target.value)
				break;
			
			case 'user_product_setting.content_create_by':
				set_content_create_by(event.target.value)
				break;
			
			case 'user_product_setting.customer_need_upload_file':
				set_customer_need_upload_file(event.target.value)
				break;
			
			case 'user_product_setting.content_file_type':
				set_content_file_type(event.target.value)
				break;

			default:
				break;
		}
		
		setValue(event.target.name,event.target.value)
	}

	const inventory_category_idChange = (event) => {
		console.log(event.target)
		set_inventory_category_id(event.target.value)
		setValue(event.target.name,event.target.value)
	}

	const handleSwitch = (event) =>{

		console.log('event.target.name',event.target.checked)
		switch (event.target.name) {
			case 'call_price':
				set_call_price(event.target.checked.toString())
				break;
		
			default:
				break;
		}
		setValue(event.target.name,event.target.checked.toString())
		

	}
	return (
		<React.Fragment>
			<AuthToolbar 
				title= {`Edit Product`}
				breadcumb = {breadcumb}
			/>

			<Box
				className="container-xxl"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
					m: 0,
					width: "100%",

					},
				}}
			>
				
				<Paper 
					elevation={3} 
					sx = {{
						p: 5
					}}
					className="col-xl-12 papper"
				>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form-title">
							Edit Product
						</div>

						<div className="form-content">
							<FormControl fullWidth>
								<TextField type="text" 
									label="Nama Publisher"
									variant="standard" 
									value={product.user_inventory_item.user_inventory.user.fullname}
									readOnly={true}
									disabled={true}
								/>
								
							</FormControl>

							<FormControl fullWidth>
								<TextField type="text" 
									label="Inventory"
									variant="standard" 
									value={product.user_inventory_item.user_inventory.inventory.inventory}
									readOnly={true}
									disabled={true}
								/>
								
							</FormControl>

							<FormControl fullWidth>
								<TextField type="text" 
									label="Asset"
									variant="standard" 
									value={product.user_inventory_item.item_name}
									readOnly={true}
									disabled={true}
								/>
								
							</FormControl>

							

							<FormControl fullWidth>
								<FormLabel component="legend">Category</FormLabel>
								<Select
									label="Asset" 
									variant="standard"
									name="inventory_category_id"
									value={inventory_category_id}
									onChange={inventory_category_idChange}
									
								>
									{product.user_inventory_item.user_inventory.inventory.inventory_categories.map((data,i)=>(
										<MenuItem key={i} {...register("inventory_category_id",{required: true})} value={data.inventory_category_id}>{data.category}</MenuItem>
									))}
								</Select> 
								
							</FormControl>

							<FormControl fullWidth>
								<label className="form-input-label">Product</label>
								<TextField  
									// label="Product" 
									variant="standard"
									// value={product.product}
									{...register("product",{required: true})}
									
									
								/>
								
							</FormControl>

							<FormControl >
								<label className="form-input-label">Price</label>		
								<TextField  
									// label="Price" 
									variant="standard"
									type="number"
									// value={product.price}
									
									{...register("price")}
									
								/>
							</FormControl>

							<FormControl >
							<label className="form-input-label">Discount Price</label>			
								<TextField  
									// label="Discount Price" 
									variant="standard"
									type="number"
									// value={product.discount_price}
									{...register("discount_price")}
									
								/>
							</FormControl>

							<FormControl fullWidth>
								<FormControlLabel control={
										<Switch 
											// defaultChecked = {(call_price === 'true')?true:false}
											checked={(call_price === 'true')?true:false}
											onClick={handleSwitch}
											{...register("call_price")}
										/>
									} 
									label="Call Price" 
								/>
							</FormControl>

							

							<FormControl fullWidth>
								<FormLabel component="legend">Deskripsi</FormLabel>
								<TextareaAutosize
									aria-label="empty textarea"
									placeholder="description"											
									minRows={3}
									{...register("description",{required: true})}
								/>
								<div className="error-message">{errors.description && "product description is required"}</div> 

								
							</FormControl>

							<input type="hidden" {...register("status",{required: true})}/>

							<div className="form-title" style={{paddingTop:"10px"}}>
								Product Profile
							</div>

							<div className="form-content">
								<FormControl fullWidth className="form-input-container">
									<div className="form-item-label">Unit</div>
									<TextField  
										variant="outlined"
										{...register("user_product_setting.unit",{required: true})}
									/>
									<div></div>
									{(!!errors.user_product_setting)?(
										<div className="error-message">{errors.user_product_setting.unit && "unit is required"}</div> 
									):(``)}
									
								</FormControl>

								<FormControl fullWidth className="form-input-container">
									<div className="form-item-label">Unit description</div>
									<TextareaAutosize
										aria-label="empty textarea"
										placeholder="description"	
										variant="outlined"										
										minRows={3}
										{...register("user_product_setting.unit_desc",{required: true})}
									/>
									<div></div>
									{(!!errors.user_product_setting)?(
									<div className="error-message">{errors.user_product_setting.unit_desc && "unit description is required"}</div>
									):(``)}
								</FormControl>

								<FormControl fullWidth className="form-input-container">
									<div className="form-item-label">Customer Need Input Schedule</div>
									<RadioGroup
										row
										value = {customer_need_schedule}
										onChange = {radioChange}
									>
										<FormControlLabel {...register("user_product_setting.customer_need_schedule")} value="true" control={<Radio />} label="Yes" />
										<FormControlLabel {...register("user_product_setting.customer_need_schedule")} value="false" control={<Radio />} label="No" />
									</RadioGroup>
								</FormControl>

								

								<FormControl fullWidth className="form-input-container">
									<div className="form-item-label">Schedule Type</div>
									<RadioGroup
										row
										value = {schedule_type}
										onChange = {radioChange}
										
									>
										<FormControlLabel {...register("user_product_setting.schedule_type")} value="Daily" control={<Radio />} label="Daily" />
										<FormControlLabel {...register("user_product_setting.schedule_type")} value="Monthly" control={<Radio />} label="Monthly" />
										<FormControlLabel {...register("user_product_setting.schedule_type")} value="Annual" control={<Radio />} label="Annual" />
										<FormControlLabel {...register("user_product_setting.schedule_type")} value="Unit" control={<Radio />} label="Unit" />
									</RadioGroup>
								</FormControl>

								<FormControl fullWidth className="form-input-container">
									<div className="form-item-label">Publish duration</div>
									<TextField  
										variant="outlined"
										type="number"
										{...register("user_product_setting.publish_duration",{required: true})}
									/>
									<div></div>
									{(!!errors.user_product_setting)?(
									<div className="error-message">{errors.user_product_setting.publish_duration && "publish duration is required"}</div>
									):(``)}
								</FormControl>

								
								
								<FormControl fullWidth className="form-input-container">
									<div className="form-item-label">Customer need upload file</div>
									<RadioGroup
										row
										value = {customer_need_upload_file}
										onChange = {radioChange}
										
									>
										<FormControlLabel {...register("user_product_setting.customer_need_upload_file")} value="true" control={<Radio />} label="Yes" />
										<FormControlLabel {...register("user_product_setting.customer_need_upload_file")} value="false" control={<Radio />} label="No" />
									</RadioGroup>
									
								</FormControl>

								<FormControl fullWidth className="form-input-container">
									<div className="form-item-label">Content Create By</div>
									<RadioGroup
										row
										value = {content_create_by}
										onChange = {radioChange}

									>
										<FormControlLabel {...register("user_product_setting.content_create_by")} value="customer" control={<Radio />} label="Customer" />
										<FormControlLabel {...register("user_product_setting.content_create_by")} value="publisher" control={<Radio />} label="Publisher" />
									</RadioGroup>
								</FormControl>

								<FormControl fullWidth className="form-input-container">
									<div className="form-item-label">Content file Type</div>
									<RadioGroup
										row
										value = {content_file_type}
										onChange = {radioChange}
										
									>
										<FormControlLabel {...register("user_product_setting.content_file_type")} value="audio" control={<Radio />} label="Audio" />
										<FormControlLabel {...register("user_product_setting.content_file_type")} value="video" control={<Radio />} label="Video" />
										<FormControlLabel {...register("user_product_setting.content_file_type")} value="image" control={<Radio />} label="Image" />
										<FormControlLabel {...register("user_product_setting.content_file_type")} value="other" control={<Radio />} label="Other" />
									</RadioGroup>
								</FormControl>

								<FormControl fullWidth className="form-input-container">
									<div className="form-item-label">Video Vertical Resolution (pixel)</div>
									<TextField  
										variant="outlined"
										type="number"
										{...register("user_product_setting.video_vres")}
									/>
								</FormControl>

								<FormControl fullWidth className="form-input-container">
									<div className="form-item-label">Video Horizontal Resolution (pixel)</div>
									<TextField  
										variant="outlined"
										type="number"
										{...register("user_product_setting.video_hres")}
									/>
								</FormControl>

								<FormControl fullWidth className="form-input-container">
									<div className="form-item-label">Content duration</div>
									<TextField  
										variant="outlined"
										type="number"
										{...register("user_product_setting.content_file_duration",{required: true})}
									/>

									<div></div>
									{(!!errors.user_product_setting)?(
									<div className="error-message">{errors.user_product_setting.content_file_duration && "content duration is required"}</div>
									):(``)}
								</FormControl>

								
							</div>
						</div>
						<div className="form-action">
							<button type="button" style={{marginRight: 10}} className="btn btn-light" onClick= {()=>window.history.back()}>Close</button>
							<button type="submit"  className="btn btn-primary" >Save changes</button>
						</div>
					</form>
				</Paper>
			</Box>
		</React.Fragment>
	)
}
