import React, { useState, useEffect } from 'react'
import AuthToolbar from '../Layout/AuthToolbar'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';


export default function ViewCampaignContent(props) {
    
    const breadcumb = [
        {
            item: 'Home',
            path: '/'
        },
        {
            item: 'Campaigns',
            path: '/campaigns'
        },
        {
            item: 'View Campaign',
            path: ''
        }
    ]

    const reloadContentData = async () =>{
        const requestBody = {
			campaign_id : props.id
		}

        const params = {
			reqBody: requestBody,
			method: API.USER_PRODUCT_GET.type,
			url: API.USER_PRODUCT_GET.url,
			access: 'auth' 
		}
        
    }

    useEffect( async()=>{
        await reloadContentData()
    },[])
    
    
    
    return (
        <React.Fragment>
            <AuthToolbar 
				title= {`Detail Campaign`}
				breadcumb = {breadcumb}
			/>

            <Box
				className="container-xxl"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
					m: 0,
					width: "100%",

					},
				}}
			>
                <Paper 
					elevation={3} 
					sx = {{
						p: 5
					}}
					className="col-xl-12 papper"
				>

                </Paper>
            </Box> 
        </React.Fragment>
    )
}
    