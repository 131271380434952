import React from 'react'
import './Sidebar.css'
import {PieChart} from '@material-ui/icons';

export default function Sidebar() {
	return (
		<div className="sidebar">
			<div className="sidebarWrapper">
				<div className="sidebarMenu">
					<h3 className="sidebarTitle">Dashboard</h3>
					<ul className="sidebarList">
						<li className="sidebarListItem active">
							<PieChart className="sidebarIcon"/> Chart
						</li>
					</ul>
				</div>
				<div className="sidebarMenu">
					<h3 className="sidebarTitle">Campaign</h3>
					<ul className="sidebarList">
						<li className="sidebarListItem">
							<PieChart className="sidebarIcon"/> Campaign List
						</li>
						<li className="sidebarListItem">
							<PieChart className="sidebarIcon"/> Approval
						</li>
					</ul>
				</div>
				<div className="sidebarMenu">
					<h3 className="sidebarTitle">Intentories</h3>
					<ul className="sidebarList">
						<li className="sidebarListItem">
							<PieChart className="sidebarIcon"/> Inventory
						</li>
					</ul>
				</div>

				<div className="sidebarMenu">
					<h3 className="sidebarTitle">Users</h3>
					<ul className="sidebarList">
						<li className="sidebarListItem">
							<PieChart className="sidebarIcon"/> User
						</li>
					</ul>
				</div>

				<div className="sidebarMenu">
					<h3 className="sidebarTitle">My Inventory</h3>
					<ul className="sidebarList">
						<li className="sidebarListItem">
							<PieChart className="sidebarIcon"/> Inventory
						</li>
					</ul>
				</div>

				<div className="sidebarMenu">
					<h3 className="sidebarTitle">My Products</h3>
					<ul className="sidebarList">
						<li className="sidebarListItem">
							<PieChart className="sidebarIcon"/> Products
						</li>
					</ul>
				</div>

				<div className="sidebarMenu">
					<h3 className="sidebarTitle">Settings</h3>
					<ul className="sidebarList">
						<li className="sidebarListItem">
							<PieChart className="sidebarIcon"/> Setting
						</li>
					</ul>
				</div>
			</div>


		</div>
	)
}
