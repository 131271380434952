import React, { Component } from 'react'

export default class FilterUser extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email : '',
			status: '%',
			member_type:'%',
			admin_checked: true,
			publisher_checked: true,
		}
	}
	handleResetFilter = () => {
		this.setState({
			email : '',
			status: '%',
			member_type:'%',
			admin_checked: true,
			publisher_checked: true,
		},()=>{
			this.props.onUpdateFilter(this.state)
		})


	}

	handleChangeFilter = (event) => {
		
		this.setState({
			[event.target.name]:event.target.value
		})
	}

	handleChangeAdmin = () =>{
		this.setState({
			admin_checked: !this.state.admin_checked,
			

		},()=>{
			
			this.setState({
				member_type: (this.state.admin_checked && this.state.publisher_checked )?`%`:(this.state.admin_checked && !this.state.publiser_checked)?'true':(!this.state.admin_checked && this.state.publisher_checked)?`false`:''
			})
		})
	}

	handleChangePublisher = () => {
		this.setState({
			publisher_checked: !this.state.publisher_checked,
			
		},()=>{
			this.setState({
				member_type: (this.state.admin_checked && this.state.publisher_checked )?`%`:(this.state.admin_checked && !this.state.publiser_checked)?'true':(!this.state.admin_checked && this.state.publisher_checked)?`false`:''
			})
		})
	}

	handleApplyFilter = () => {
		this.props.onUpdateFilter(this.state)
	}

	render() {
		return (
			<React.Fragment>
				<div className="btn btn-custom btn-active-white btn-flex btn-color-white btn-active-color-primary show menu-dropdown" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">
					Filter
				</div>

				<div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px " data-kt-menu="true" id="kt_menu_613570945bcd4" style={{zIndex: 105, position: "fixed", inset: "0px auto auto 0px", margin:"0px", }} data-select2-id="select2-data-kt_menu_613570945bcd4" data-popper-placement="bottom-end">
					{/* <!--begin::Header--> */}
					<div className="px-7 py-5">
						<div className="fs-5 text-dark fw-bolder">Filter Options</div>
					</div>
					{/* <!--end::Header--> */}
					{/* <!--begin::Menu separator--> */}
					<div className="separator border-gray-200"></div>
					{/* <!--end::Menu separator--> */}
						{/* <!--begin::Form--> */}
						<div className="px-7 py-5">
							<div className="mb-10">
								<label className="form-label fw-bold">Email: (like)</label>
								<input type="text" className="form-control form-control-solid" placeholder="Email" name="email" value={this.state.email} onChange={this.handleChangeFilter}/>
							</div>
							{/* <!--begin::Input group--> */}
							<div className="mb-10">
								{/* <!--begin::Label--> */}
								<label className="form-label fw-bold">Status:</label>
								{/* <!--end::Label--> */}
								{/* <!--begin::Input--> */}
								
								<select className="form-select" value={this.state.status} onChange={this.handleChangeFilter} name="status">

									<option value="%" data-select2-id="select2-data-74-u9sm">All Status</option>
									<option value="ACTIVE" data-select2-id="select2-data-74-u9sm">ACTIVE</option>
									<option value="NOT ACTIVE" data-select2-id="select2-data-75-grjf">NOT ACTIVE</option>
									<option value="VERIFY EMAIL" data-select2-id="select2-data-76-cs6j">VERIFY EMAIL</option>
									<option value="SUSPEND" data-select2-id="select2-data-77-7rwh">SUSPEND</option>
								</select>
								
								{/* <!--end::Input--> */}
							</div>
							{/* <!--end::Input group--> */}
							
							{/* <!--begin::Input group--> */}
							<div className="mb-10">
								{/* <!--begin::Label--> */}
								<label className="form-label fw-bold">Member Type:</label>
								{/* <!--end::Label--> */}
								{/* <!--begin::Options--> */}
								<div className="d-flex">
									<div className="form-check form-check-custom form-check-solid" style={{marginRight: 10}}>
										<input className="form-check-input" type="checkbox" onChange={this.handleChangeAdmin} name="member_type" checked={this.state.admin_checked} />
										<label className="form-check-label" >
											Administrator
										</label>
									</div>

									<label className="form-check form-check-custom form-check-solid">
										<input className="form-check-input" type="checkbox" onChange={this.handleChangePublisher} name="member_type" checked={this.state.publisher_checked} />
										<span className="form-check-label">
											Publisher
										</span>
									</label>
								</div>
								{/* <!--end::Options--> */}
							</div>
							{/* <!--end::Input group--> */}

							{/* <!--begin::Actions--> */}
							<div className="d-flex justify-content-end">
								<button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" onClick={this.handleResetFilter}>Reset</button>
								<button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true" onClick={this.handleApplyFilter}>Apply</button>
							</div>
							{/* <!--end::Actions--> */}
						</div>
					{/* <!--end::Form--> */}
				</div>
			</React.Fragment>
		)
	}
}

