import React, {useEffect,useState} from 'react';
import AuthToolbar from '../Layout/AuthToolbar'
import Pagination from '@mui/material/Pagination';
import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';
import '../Pagination/Pagination.css';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CampaignData from './CampaignData'



const CampaignContent = (props) => {
    const [breadcumb,setBreadcumb] = useState([
		{
			item: 'Home',
			path: '/'
		},
		{
			item: 'Campaign',
			path: ''
		},
        {
			item: 'Campaign List',
			path: ''

        }

	])

	

	const [campaigns, set_campaigns] = useState([])
	
	const [Page,setPage] = useState({
		totalPage: 1,
		activePage: 1,
		perPage: 5,
	})

	const [filter, setFilter] = useState([
	])

	useEffect(() => {
		setBreadcumb(props.breadcumb)
		if(props.approval){
			const f = filter.push({
				field: 'campaigns.status',
				value: 'WAITING'
			})
			setFilter(f)
		}
		
		reloadContentData()
	}, [])

	useEffect(() => {
		console.log('campaign',campaigns)
	}, [campaigns])

	const reloadContentData = async () => {

		
		const requestBody = {
			page: Page.activePage,
 			rows: Page.perPage,
			filter: filter,
			order: [{
				field: "updated_at",
				sort: "desc"
			}]
		}
		const params = {
			reqBody: requestBody,
			method: API.CAMPAIGN_GET_ALL.type,
			url: API.CAMPAIGN_GET_ALL.url,
			access: 'auth' 
		}

		const response = await Api(params)
		console.log(response)
		if(response.status){

			setPage({
				totalPage: response.data.lastPage,
				activePage: response.data.page,
				perPage: response.data.perPage,
			})

			
			set_campaigns(response.data.data)
		}
	}

	const handlePaginationChange = async (event,value) =>{
		reloadContentData(value)
	}

    return (
        <React.Fragment>
            <AuthToolbar 
                title={props.title}
                breadcumb = {breadcumb}
            />
			<Box
				className="container-xxl"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
					m: 0,
					width: "100%",

					},
				}}
			>
				<Paper 
					elevation={3} 
					sx = {{
						p: 5
					}}
					className="col-xl-12 papper"
				>
					<div className="header-container">
						<div className="search-item">
							<Paper
								component="form"
								sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
							>
								<IconButton sx={{ p: '10px' }} aria-label="menu">
									<SearchIcon />
								</IconButton>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder="Campaigns name"
									inputProps={{ 'aria-label': 'search google maps' }}
								/>
							
								<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
								<IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
									<DirectionsIcon />
								</IconButton>
							</Paper>
						</div>
						{props.createCampaignButton?(
							<div className="action-item">
								<Button variant="contained" onClick={()=>{
									window.location.href='/campaigns/create'
								}}>Create Campaign</Button>
							</div>
						):(``)}
						
					</div>

					<div className="table-container">
						<Table 
							sx={{ minWidth: 650,width:"100%",fontSize:"20px",marginTop: 3, marginBottom: 3 }} 
							aria-label="simple table"
						>
							<TableHead>
								<TableRow>
									<TableCell>Nama Project</TableCell>
									<TableCell>Partner</TableCell>
									<TableCell>Publisher</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Channel</TableCell>
									<TableCell>Action</TableCell>

								</TableRow>
							</TableHead>
							
							<TableBody>
									
								
								{campaigns.map((data,i)=>(
								 	<CampaignData 
								 		key={i}
								 		data={data}
										onViewCampaign = {(id)=>{
											window.location.href = '/campaigns/view/'+id
										}}
								 	/>	
								))}
							</TableBody>
						</Table>
					</div>
					<Pagination count={Page.totalPage}  variant="outlined" shape="rounded" onChange={handlePaginationChange} />
				</Paper>
			</Box>
        </React.Fragment>
    )
}

export default CampaignContent;