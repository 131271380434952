export const Navigation = [
	{
		title: 'Dashboard',
		to: '/dashboard',
		subMenu: null,
		auth: ['publisher','admin','superadmin'],
	},
	{
		title: 'Campaign',
		to: null,
		auth: ['publisher','admin'],
		subMenu: [
			{
				title: 'Campaign',
				icon: 'assets/media/icons/duotune/ecommerce/ecm008.svg',
				to: '/campaigns',
				auth: ['publisher','admin'],
			},
			{
				title: 'Approval',
				icon: 'assets/media/icons/duotune/general/gen055.svg',
				to: '/campaigns/approval',
				auth: ['publisher','admin'],
			}
		]
	},
	
	
	
	{
		title: 'Publishers',
		to: '/publishers',
		subMenu: null,
		auth: ['admin'],
	},
	{
		title: 'Asset Inventory',
		to: '/asset',
		subMenu: null,
		auth: ['admin'],
	},

	{
		title: 'Products',
		to: '/products',
		subMenu: null,
		auth: ['publisher','admin'],
	}, 

	{
		title: 'User',
		to: '/user',
		subMenu: null,
		auth: ['admin','superadmin'],
	},

	{
		title: 'Report',
		to: '/report',
		subMenu: null,
		auth: ['publisher','admin','superadmin'],
	},

	{
		title: 'Settings',
		to: null,
		auth: ['superadmin'],
		subMenu: [
			{
				title: 'Partners',
				to: '/partners',
				icon: 'assets/media/icons/duotune/communication/com005.svg',
				auth: ['superadmin'],
			},
			{
				title: 'Companies',
				to: '/companies',
				icon: 'assets/media/icons/duotune/ecommerce/ecm004.svg',
				auth: ['superadmin'],
			},
			// {
			// 	title: 'Groups',
			// 	to: '/groups',
			// 	icon: 'assets/media/icons/duotune/communication/com014.svg',
			// 	auth: ['publisher','admin','superadmin'],
			// },
			// {
			// 	title: 'Modules',
			// 	to: '/modules',
			// 	icon: 'assets/media/icons/duotune/communication/com004.svg',
			// 	auth: ['publisher','admin','superadmin'],
			// },
			{
				title: 'Inventories',
				to: '/inventories',
				icon: 'assets/media/icons/duotune/communication/com004.svg',
				auth: ['superadmin'],
			},
			{
				title: 'Users',
				to: '/users',
				icon: 'assets/media/icons/duotune/communication/com013.svg',
				auth: ['admin','superadmin'],
			},
			// {
			// 	title: 'Roles',
			// 	to: '/roles',
			// 	icon: 'assets/media/icons/duotune/coding/cod001.svg',
			// 	auth: ['publisher','admin','superadmin'],
			// }

		]
	}


]