import React, { Component } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Collapse from '@mui/material/Collapse';

const labelStyle = {
	marginTop: "10px"
}

export default class ProductSetting extends Component {
	handleSwitchChange = (event) => {
		console.log(event.target.value)
		// event.target.value = event.target.value.toString()
		// this.props.onChange(event)

	}

	handleRadioChange = (event) => {
		this.props.onChange(event)
	}

	handleChange = (event) => {
		this.props.onChange(event)
	}

	render() {
		return (
			<Container>
				<Box sx={{ bgcolor: '#e8f3f9', padding: 1, marginTop: 1, marginBottom: 1, borderRadius: 1 }}>
					<div className="block-title">Product Setting</div>

					<FormControl  sx={{ m: 0, minWidth: "10%", marginTop: 1 }}>
						<TextField 
							label="Product Unit" 
							variant="standard" 
							name="unit"
							value={this.props.data.unit}
							onChange={this.handleChange}
						/>
					</FormControl>

					<FormControl   sx={{ m: 0, minWidth: "100%", marginTop: 1 }}>
						<TextField 
							label="Unit Description" 
							variant="standard" 
							name="unit_desc"
							value={this.props.data.unit_desc}
							onChange={this.handleChange}
						/>
					</FormControl>

					<FormControl component="fieldset" fullWidth>
						<FormLabel style= {labelStyle} component="legend">Customer need input schedule</FormLabel>
						<RadioGroup row
							defaultValue="false"
							name="customer_need_schedule"
							value={this.props.data.customer_need_schedule}
							onChange={this.handleChange}
						>
							<FormControlLabel value="true" control={<Radio />} label="Yes" />
							<FormControlLabel value="false" control={<Radio />} label="No" />
						</RadioGroup>
					</FormControl>

					<Collapse in={(this.props.data.customer_need_schedule === "true")}>
						<FormControl component="fieldset" fullWidth>
							<FormLabel style= {labelStyle} component="legend">Schedule type</FormLabel>
							<RadioGroup row
								defaultValue="Daily"
								name="schedule_type"
								value={this.props.data.schedule_type}
								onChange={this.handleRadioChange}
							>
								<FormControlLabel value="Daily" control={<Radio />} label="Daily" />
								<FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
								<FormControlLabel value="Annual" control={<Radio />} label="Annual" />
								<FormControlLabel value="Unit" control={<Radio />} label="Unit" />
							</RadioGroup>
						</FormControl>
					</Collapse>

					<FormControl  sx={{ m: 0, minWidth: "10%", marginTop: 1 }}>
						<TextField 
							label="Publish duration" 
							variant="standard" 
							name="publish_duration"
							type="number"
							value={this.props.data.publish_duration}
							onChange={this.handleChange}
						/>
					</FormControl>
					
					<FormControl component="fieldset" fullWidth>
						<FormLabel style= {labelStyle} component="legend">Customer need upload file</FormLabel>
						<RadioGroup row
							defaultValue="false"
							value={this.props.data.customer_need_upload_file}
							name="customer_need_upload_file"
							onChange={this.handleRadioChange}
						>
							<FormControlLabel value="false" control={<Radio />} label="No" />
							<FormControlLabel value="true" control={<Radio />} label="Yes" />
						</RadioGroup>
					</FormControl>

					<FormControl component="fieldset" fullWidth>
						<FormLabel style= {labelStyle} component="legend">Content created by</FormLabel>
						<RadioGroup row
							defaultValue="customer"
							name="content_create_by"
							value={this.props.data.content_create_by}
							onChange={this.handleRadioChange}
						>
							<FormControlLabel value="customer" control={<Radio />} label="Customer" />
							<FormControlLabel value="publisher" control={<Radio />} label="Publisher" />
						</RadioGroup>
					</FormControl>

					<FormControl component="fieldset" fullWidth>
						<FormLabel style= {labelStyle} component="legend">Content file type</FormLabel>
						<RadioGroup row
							defaultValue="video"
							name="content_file_type"
							value={this.props.data.content_file_type}
							onChange={this.handleRadioChange}
						>
							<FormControlLabel value="audio" control={<Radio />} label="Audio" />
							<FormControlLabel value="video" control={<Radio />} label="Video" />
							<FormControlLabel value="image" control={<Radio />} label="Image" />
							<FormControlLabel value="other" control={<Radio />} label="Other" />
						</RadioGroup>
					</FormControl>
					
					<Collapse in={(this.props.data.content_file_type === "video" || this.props.data.content_file_type === "audio")}>
						<React.Fragment>
							<div className="input-label">Audio/Video Setting</div>
					
							<FormControl sx={{ width: '25ch' }} variant="outlined">
						
								<TextField
									label="Content duration (in second)"
									type="number"
									name="content_file_duration"
									value={this.props.data.content_file_duration}
									variant="standard"
									onChange={this.handleRadioChange}
									
								/>
							</FormControl>
						</React.Fragment>
					</Collapse>

					
					<Collapse in={(this.props.data.content_file_type === "video")}>
						<React.Fragment>
							<FormControl sx={{ width: '25ch', marginTop: 1 }} variant="outlined">
						
								<TextField
									label="Video Resolution Width (in pixel)"
									type="number"
									name="video_hres"
									value={this.props.data.video_hres}
									variant="standard"
									onChange={this.handleRadioChange}

									
								/>
							</FormControl>
							<FormControl sx={{ width: '25ch', marginTop: 1 }} variant="outlined">
								
								<TextField
									label="Video Resolution Hight (in pixel)"
									type="number"
									name="video_vres"
									value={this.props.data.video_vres}
									variant="standard"
									onChange={this.handleRadioChange}
									
								/>
							</FormControl>
						</React.Fragment>
					</Collapse>
					
					
				</Box>
			</Container>
		)
	}
}
