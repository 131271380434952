import React, { useState, useEffect } from 'react'
import AuthToolbar from '../Layout/AuthToolbar'
import Pagination from '@mui/material/Pagination';
import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';
import '../Pagination/Pagination.css';
import './Products.css'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ProductsData from './ProductsData'

export default function ProductsContent(props) {


	const [breadcumb,setBreadcumb] = useState([
		{
			item: 'Home',
			path: '/'
		},
		{
			item: 'Products',
			path: ''
		},
		
	])

	const [Page,setPage] = useState({
		totalPage: 1,
		activePage: 1,
		perPage: 5,
	})

	const [filterProduct,setFilterProduct] = useState({
		field: 'user_products.product',
		operator: 'LIKE',
		value: ''
	})

	const [filterPublisher,setFilterPublisher] = useState({
		field: 'users.fullname',
		operator: 'LIKE',
		value: ''
	})

	const [filterChannel,setFilterChannel] = useState({
		field: 'inventories.inventory',
		operator: 'LIKE',
		value: ''
	})

	const [filterCategory,setFilterCategory] = useState({
		field: 'inventory_categories.category',
		operator: 'LIKE',
		value: ''
	})

	const [products,setProducts] = useState([])

	const handlePaginationChange = async (event,value) =>{
		reloadContentData(value)
	}

	const reloadContentData = async (page) => {
		const requestBody = {
			page: page,
 			rows: Page.perPage,
			filter: [
				{
					field: filterProduct.field,
					operator: filterProduct.operator,
					value: `%${filterProduct.value}%`
				},
				{
					field: filterPublisher.field,
					operator: filterPublisher.operator,
					value: `%${filterPublisher.value}%`
				},
				{
					field: filterChannel.field,
					operator: filterChannel.operator,
					value: `%${filterChannel.value}%`
				},
				{
					field: filterCategory.field,
					operator: filterCategory.operator,
					value: `%${filterCategory.value}%`
				},
			],

			order: [
				{
					field: "user_products.updated_at",
					sort: "desc"
				}
			]
		}
		const params = {
			reqBody: requestBody,
			method: API.USER_PRODUCT_GET_ALL.type,
			url: API.USER_PRODUCT_GET_ALL.url,
			access: 'auth' 
		}

		const response = await Api(params)
		console.log('response',response)
		if(response){

			setPage({
				totalPage: response.data.lastPage,
				activePage: response.data.page,
				perPage: response.data.perPage,
			})

			setProducts(response.data.data)

			
			
		}
	}

	useEffect(()=>{
		console.log(`prop.breadcumb`,props.breadcumb)
		if(props.breadcumb){
			setBreadcumb(props.breadcumb)
		}
		reloadContentData(Page.activePage)
	},[])

	const handleFilter = (event) => {
		

		switch (event.target.name) {
			case `user_products.product`:
				setFilterProduct({
					...filterProduct,
					value: event.target.value
				})
				
				break;
		
			case `users.fullname`:
				setFilterPublisher({
					...filterPublisher,
					value: event.target.value
				})
				break;
			
			case `inventories.inventory`:
				setFilterChannel({
					...filterChannel,
					value: event.target.value
				})
				break;

			case `inventory_categories.category`:
				setFilterCategory({
					...filterCategory,
					value: event.target.value
				})
				break;

			default:
				break;
		}
	}

	useEffect(() => {
		reloadContentData(Page.activePage)
	}, [filterProduct,filterPublisher,filterChannel,filterCategory])

	const handleCreateProduct = () => {
		window.location.href='/products/create'
	}
	return (
		<React.Fragment>
			<AuthToolbar 
				title={props.title || 'Products'}
				breadcumb = {breadcumb}
			/>

			<Box
				className="container-xxl"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
					m: 0,
					width: "100%",

					},
				}}
			>
				<Paper 
					elevation={3} 
					sx = {{
						p: 5
					}}
					className="col-xl-12 papper"
				>

					<div className="product-page-title-container">
						<div className="item-product-page">
							<div className="main-title">
								{props.title || `Daftar Product`}
							</div>
							{/* <div className="status-container">
								<div className="status-item">Semua Product</div>
								<div className="status-item">Aktif</div>
								<div className="status-item">Non Aktif</div>
							</div> */}
						</div>
						<div className="item-product-page v-center">
							{props.newButton?(<button className="btn btn-primary" onClick={handleCreateProduct}>Tambah Product</button>):``}
							{/* <button className="btn btn-primary" onClick={handleCreateProduct}>Tambah Product</button> */}
						</div>
					</div>

					<div className="table-container">
					<Table 
							sx={{ minWidth: 650,width:"100%",fontSize:"20px",marginTop: 3, marginBottom: 3 }} 
							aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>
										<div className="cell-title">Product</div>
										<Paper
											component="form"
											sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
										>
											<IconButton sx={{ p: '10px' }} aria-label="menu">
												<SearchIcon />
											</IconButton>
											<InputBase
												sx={{ ml: 1, flex: 1 }}
												placeholder="Product"
												name="user_products.product"
												inputProps={{ 'aria-label': 'search product' }}
												value={filterProduct.value}
												onChange={handleFilter}
											/>
							
											<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
											
										</Paper>
									</TableCell>
									<TableCell>
										<div className="cell-title">Harga</div>
										<Paper
											component="form"
											sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
										>
											<IconButton sx={{ p: '10px' }} aria-label="menu">
												<SearchIcon />
											</IconButton>
											<InputBase
												sx={{ ml: 1, flex: 1 }}
												placeholder="Harga"
												name="user_products.price"
												inputProps={{ 'aria-label': 'search product price' }}
												disabled={true}
											/>
							
											<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
											
										</Paper>
									</TableCell>
									<TableCell>
										<div className="cell-title">Publisher</div>
										<Paper
											component="form"
											sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
										>
											<IconButton sx={{ p: '10px' }} aria-label="menu">
												<SearchIcon />
											</IconButton>
											<InputBase
												sx={{ ml: 1, flex: 1 }}
												placeholder="Publisher"
												name="users.fullname"
												inputProps={{ 'aria-label': 'search publisher' }}
												value={filterPublisher.value}
												onChange={handleFilter}
											/>
							
											<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
											
										</Paper>
									</TableCell>
									<TableCell>
										<div className="cell-title">Channel</div>
										<Paper
											component="form"
											sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
										>
											<IconButton sx={{ p: '10px' }} aria-label="menu">
												<SearchIcon />
											</IconButton>
											<InputBase
												sx={{ ml: 1, flex: 1 }}
												placeholder="Channel"
												name="inventories.inventory"
												inputProps={{ 'aria-label': 'search channel' }}
												value={filterChannel.value}
												onChange={handleFilter}
											/>
							
											<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
											
										</Paper>
									</TableCell>

									<TableCell>
										<div className="cell-title">Kategori</div>
										<Paper
											component="form"
											sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
										>
											<IconButton sx={{ p: '10px' }} aria-label="menu">
												<SearchIcon />
											</IconButton>
											<InputBase
												sx={{ ml: 1, flex: 1 }}
												placeholder="Kategori"
												name="inventory_categories.category"
												inputProps={{ 'aria-label': 'search category' }}
												value={filterCategory.value}
												onChange={handleFilter}
											/>
							
											<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
											
										</Paper>
									</TableCell> 
									
									<TableCell sx={{width:"120px"}}><div className="cell-title">Actions</div></TableCell>

								</TableRow>
								
								
							</TableHead>

							<TableBody>	
								{products.map((data,i)=>(
									<ProductsData
										key={i}
										data={data}
										onViewItem = {(id)=>{
											window.location.href = '/products/view/'+id
										}}

										onCreateCampaign = {(id)=>{
											window.location.href = '/campaigns/create/'+id
										}}
										editButton = {props.editButton}
										viewButton={props.viewButton}
										createCampaign={props.createCampaign}
										
									/>
								))}
							</TableBody>
						</Table>
					</div>

					<Pagination count={Page.totalPage}  variant="outlined" shape="rounded" onChange={handlePaginationChange} />
				</Paper>
			</Box>

		</React.Fragment>
	)
}
