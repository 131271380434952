import React, { useState, useEffect } from 'react';
import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';
import AuthToolbar from '../Layout/AuthToolbar'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Autocomplete from '@mui/material/Autocomplete';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import {dataURLtoFile} from '../Helper/Helper';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useForm } from "react-hook-form";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { Dropzone, FileItem } from "@dropzone-ui/react";


import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';


const steps = ['Pilih Inventori', 'Cari publisher', 'Create Campaign'];

export default function CreateCampaignContent(props) {

	const breadcumb = [
		{
			item: 'Home',
			path: '/'
		},
		{
			item: 'Product',
			path: '/product/'+props.id
		},
		{
			item: 'Create Campaign',
			path: ''
		}
	]

	const [files, setFiles] = useState([]);
	const [contents, setContent] = useState([]);
  	
	const updateFiles = (incommingFiles) => {
    	setFiles(incommingFiles);
  	};
	
	const updateContents = (incommingFiles) => {
    	setContent(incommingFiles);
  	};

	const { register, handleSubmit, reset, formState: { errors } } = useForm();
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState({});
  	

	const [inventories,set_inventories] = useState([])
	const [partners,set_partners] = useState([])
	const [product,set_product] = useState()
	const [pricebooks,set_pricebooks] = useState([])
	const [image_product,set_image_product] = useState(null)
	const [location,set_location] = useState(``)
	const [price, set_price] = useState(0)
	const [setting, set_setting] = useState(null)

	const [unit,set_unit] = useState(``)

	const [date, set_date] = React.useState(new Date());


	useEffect(() => {
		console.log(files)	
		
	}, [files]
	)
	useEffect(() => {
		getInventories()
		getPartners()
		getProduct()
	}, [])

	const getProduct = async () => {
		const params = {
			reqBody: {
				user_product_id: props.id
			},
			method: API.USER_PRODUCT_GET.type,
			url: API.USER_PRODUCT_GET.url,
			access: 'auth' 
		}

        const response = await Api(params)
		console.log('product',response.data)
		if(response.status){
			set_product(response.data)
			set_pricebooks(response.data.pricebook)
			set_setting(response.data.user_product_setting)
			if(response.data.user_inventory_item.item_files.length > 0){
				set_image_product(response.data.user_inventory_item.item_files[0].file)
			}

			if(response.data.user_inventory_item.user_inventory.inventory.search_base === 'Villages'){
				set_location(`${response.data.user_inventory_item.ms_village.ms_village_name}, kec. ${response.data.user_inventory_item.ms_district.ms_district_name}, ${response.data.user_inventory_item.ms_city.ms_city_name}, ${response.data.user_inventory_item.ms_province.ms_province_name}`)
			}else if(response.data.user_inventory_item.user_inventory.inventory.search_base === 'City'){
				set_location(`${response.data.user_inventory_item.ms_city.ms_city_name}, ${response.data.user_inventory_item.ms_province.ms_province_name}`)
			}else{
				set_location(``)
			}

			if(response.data.discount_price !== null){
				set_price(response.data.discount_price)
			}else{
				set_price(response.data.price)
			}
			switch (response.data.user_product_setting.schedule_type) {
				case "Daily":
					set_unit(`hari`)
					break;
				case "Monthly":
					set_unit(`bulan`)
					break;
				case "Annual":
					set_unit(`tahun`)
					break;
				case "Unit":
					set_unit(response.data.user_product_setting.unit)
					break;
	
		
				default:
					break;
			}
		}
	}

	const getPartners = async () => {
		const params = {
			reqBody: [],
			method: API.PARTNER_GET_ALL.type,
			url: API.PARTNER_GET_ALL.url,
			access: 'auth' 
		}

        const response = await Api(params)
		console.log('partner',response.data)
		if(response.status){
			set_partners(response.data)
		}
	}
	const getInventories = async () => {
		const params = {
			reqBody: [],
			method: API.INVENTORIES_GET_ALL.type,
			url: API.INVENTORIES_GET_ALL.url,
			access: 'auth' 
		}

        const response = await Api(params)
		// console.log(response)
		if(response.status){
			set_inventories(response.data)
		}
	}

	const onSubmit = async (data) => {

	}

	
	return (
		<React.Fragment>
			<AuthToolbar 
				title='Create Campaign'
				breadcumb = {breadcumb}
			/>

			
			<Box
				className="container-xxl"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
					m: 0,
					width: "100%",

					},
				}}
			>
				<Paper 
					elevation={3} 
					sx = {{
						p: 5
					}}
					className="col-xl-12 papper"
				>
					

					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form-title">
							General Information 
						</div>
						<div className="form-content-2-column">
							<div className="item-product">
								<Card >
      								<CardMedia
										component="img"
										image={image_product}
										alt="product"
									/>
      								<CardContent>
										<div className="l2">{product?.user_inventory_item?.item_name || ``} ({product?.user_inventory_item?.user_inventory?.inventory?.inventory || ``})</div>
										<div className="l3">{product?.product || ``}</div>
										<div className="l1">{location}</div>

										<div className="p">Rp. {price}</div>

										<div className="setting-container">
											<div className='setting-label'>Satuan</div>
											<div className='setting-value'>{product?.user_product_setting?.unit || ``}</div>
											<div className='setting-label'>Keterangan Satuan</div>
											<div className='setting-value'>{product?.user_product_setting?.unit_desc || ``}</div>
											<div className='setting-label'>Jenis Penjadwalan</div>
											<div className='setting-value'>{product?.user_product_setting?.schedule_type || ``}</div>
											<div className='setting-label'>Durasi Jadwal</div>
											<div className='setting-value'>{product?.user_product_setting?.publish_duration || ``} {unit}</div>
											<div className='setting-label'>Jenis konten</div>
											<div className='setting-value'>{product?.user_product_setting?.content_file_type || ``}</div>
											<div className='setting-label'>Durasi konten</div>
											<div className='setting-value'>{product?.user_product_setting?.content_file_duration || ``} detik</div>
											<div className='setting-label'>Penjelasan produk</div>
											<div className='setting-value'>{product?.description || ``}</div>
										</div>


      								</CardContent>
      								<CardActions>
      								</CardActions>
    							</Card>
							</div>
							<div className="item-order">
								<FormControl fullWidth>
									<InputLabel id="Partner" sx={{marginLeft: -2}}>Partner</InputLabel>
									<Select
										variant ="standard"
										label ="Pilih partner ..."
										labelId = "label-partner"
										name = "partner_id" 
										{...register("partner_id")}

									>
										<MenuItem  value={``}> ... Tanpa Partner ...</MenuItem>
										{partners.map((data,i)=>(
											<MenuItem key={i} value={data.partner_id}>{data.partner}</MenuItem>
										))}

									</Select>
								</FormControl>

								<FormControl fullWidth>
									<TextField type="text" 
										label="Partner Company ID"
										variant="standard" 
										// value={asset.item_name}
										// onChange={handleAssetChange}
										{...register("partner_company_id",{required: true})}
										name="partner_company_id"
									/>
								</FormControl>

								<FormControl fullWidth>
									<TextField type="text" 
										label="Nama Project"
										variant="standard" 
										// value={asset.item_name}
										// onChange={handleAssetChange}
										{...register("project_name",{required: true})}
										name="project_name"
									/>
								</FormControl>

								<FormControl fullWidth>
									<TextField type="text" 
										label="Owner Brand"
										variant="standard" 
										// value={asset.item_name}
										// onChange={handleAssetChange}
										{...register("owner_brand",{required: true})}
										name="owner_brand"
									/>
								</FormControl>

								<FormControl fullWidth>
									<TextField type="text" 
										label="Owner email"
										variant="standard" 
										// value={asset.item_name}
										// onChange={handleAssetChange}
										{...register("owner_email",{required: true})}
										name="owner_email"
									/>
								</FormControl>

								<FormControl fullWidth>
									<TextField type="text" 
										label="Owner Contact"
										variant="standard" 
										// value={asset.item_name}
										// onChange={handleAssetChange}
										{...register("owner_contact",{required: true})}
										name="owner_contact"
									/>
								</FormControl>

								<FormControl fullWidth>
									<TextField type="text" 
										label="Order Deskripsi"
										variant="standard" 
										// value={asset.item_name}
										// onChange={handleAssetChange}
										{...register("order_desc",{required: true})}
										name="order_desc"
									/>
								</FormControl>

								<FormControl >
									<TextField type="number" 
										label="Jumlah"
										variant="standard" 
										// value={asset.item_name}
										// onChange={handleAssetChange}
										{...register("quantity",{
											required: true,
											min: 1,
											onChange: (e) =>{
												if(e.target.value < 1) e.target.value = 1
											}
										})}
										name="quantity"
									/>
								</FormControl>
							</div>
						</div>

						<div className="form-content">
							<div className="form-title">
								Campaign Information
							</div>
							<div>
								<div className="campaign-info-title">Penjadwalan</div>
								{setting?.customer_need_schedule === 'true'?(
									<React.Fragment>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
										<StaticDatePicker
											displayStaticWrapperAs="desktop"
											// openTo="year"
											value={date}
											onChange={(newValue) => {
											set_date(newValue);
											}}
											renderInput={(params) => <TextField {...params} />}
									/>
									</LocalizationProvider>
									</React.Fragment>
									
								):(
									<React.Fragment>
										<div className="campaign-info-desc">Tidak diperlukan penjadwalan</div>
									</React.Fragment>
								)}
								
							</div>
							<div>
								<div className="campaign-info-title">Upload data pendukung</div>
								{setting?.customer_need_upload_file === 'true'?(
									<Dropzone 
										onChange={updateFiles} value={files}
										maxFiles={3}
									>

										{files.map((file) => (
											<FileItem {...file} preview />
										))}
									</Dropzone>
								):(
									<React.Fragment>
										<div className="campaign-info-desc">Tidak diperlukan data pendukung</div>
										
									</React.Fragment>
								)}
								
							</div>
							<div>
							<div className="campaign-info-title">Upload Konten</div>
								{setting?.content_create_by === 'customer'? (
									<Dropzone 
										onChange={updateContents} value={contents}
										maxFiles={1}
									>

										{contents.map((file) => (
											<FileItem {...file} preview />
										))}
									</Dropzone>
								):(
									<React.Fragment>
										<div className="campaign-info-desc">Tidak diperlukan upload konten (kontent dibuat oleh publisher)</div>
									</React.Fragment>
								)}
								
							</div>
							
							
						</div>
						<Button 
							type="submit"
							fullWidth
							variant="contained"
						>
							Buat Campaign
						</Button>

					</form>
					
				</Paper>
				
				

				

			</Box>
				

				
			

		</React.Fragment>
	)
}
