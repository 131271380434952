/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import AuthToolbar from '../Layout/AuthToolbar'

import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
 

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Gmap from '../Gmap/Gmap';


import ImageGallery from 'react-image-gallery';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import Pricebook from '../Pricebook/Pricebook';



export default function ViewProductContent(props) {
	const breadcumb = [
		{
			item: 'Home',
			path: '/'
		},
		{
			item: 'Products',
			path: '/products'
		},
		{
			item: `Detail Product`,
			path: ''
		},
		
	]

	const [open,setOpen] = useState(false);

	const [product,setProduct] = useState({
		user_inventory_item: {
			item_name: ``,
			lat: 0,
			long: 0,
			user_inventory: {
				inventory: {
					inventory: ``

				},
				user: {
					fullname: ``
				}
			},
			item_profiles: [],
			ms_province: {
				ms_province_name: ``
			},
			ms_city: {
				ms_city_name: ``
			},
			ms_district: {
				ms_district_name: ``
			},
			ms_village: {
				ms_village_name: ``
			},
			
		},
		user_product_setting: {
			unit: ``,
			unit_desc: ``,
			customer_need_schedule: `false`,
			schedule_type: `Daily`,
			customer_need_upload_file: `true`,
			content_create_by: `customer`,
			content_file_type: `video`,
			content_file_duration: ``,
			video_hres: ``,
			video_vres:``,
			publish_duration:``
		}
	}) 
		
	const [item_files,set_item_files] = useState([])
	const [location, set_location] = useState(``)
	const [label, set_label] = useState(`Location`)
	const [tab_value,set_tab_value] = useState(`1`)
	const [partners,set_partners] = useState([])
	const [lat,set_lat] = useState(``)
	const [long,set_long] = useState(``)

	const getPartner = async () => {
		const requestBody = {
			
		}
		const params = {
			reqBody: requestBody,
			method: API.PARTNER_GET_ALL.type,
			url: API.PARTNER_GET_ALL.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			set_partners(response.data)
		}
	}

	useEffect(() => {
		for (const i of partners) {
			i['value'] = i.partner_id
			i['label'] = i.partner
		}
	}, [partners])

	const getProduct = async() => {
		const requestBody = {
			user_product_id : props.id
		}

		

		const params = {
			reqBody: requestBody,
			method: API.USER_PRODUCT_GET.type,
			url: API.USER_PRODUCT_GET.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			console.log('response.data',response.data)
			setProduct(response.data)
			set_item_files(response.data.user_inventory_item.item_files)
			set_lat(response.data.user_inventory_item.lat)
			set_long(response.data.user_inventory_item.long)
			switch (response.data.user_inventory_item.user_inventory.inventory.search_base) {
				case 'Villages':
					set_location(`${response.data.user_inventory_item.ms_village.ms_village_name}, Kec. ${response.data.user_inventory_item.ms_district.ms_district_name}, ${response.data.user_inventory_item.ms_city.ms_city_name}, ${response.data.user_inventory_item.ms_province.ms_province_name}`)
					set_label('Location')
					break;
			
				case 'City':
					set_location(`${response.data.user_inventory_item.ms_city.ms_city_name}, ${response.data.user_inventory_item.ms_province.ms_province_name}`)
					set_label('Location')
					break;
				case 'Social Media Account':
					set_location(`${product.user_inventory_item.social_account}, follower: ${product.user_inventory_item.follower}`)
					set_label('Media Social')
				break;

				default:
					break;
			}
			
			
		}
	} 

	useEffect( async () => {
		// load asset

		getPartner()
		getProduct()


	}, [])

	useEffect(() => {
		for (const  i of item_files) {
			i['original'] = i.file
			i['thumbnail'] = i.file
			i['description'] = i.file_desc
		}
		console.log('item_files',item_files)
	}, [item_files])

	const handleTabChange = (event, newValue) => {
		set_tab_value(newValue)
	}

	const handleCreatePricebook = async (pricebook) => {
		
		
		const requestBody = {
			user_product_id: pricebook.user_product_id,
			partner_id: pricebook.partner_id,
			partner_company_id: pricebook.partner_company_id,
			partner_company_name: pricebook.partner_company_name,
			price: pricebook.price
		}
		const params = {
			reqBody: requestBody,
			method: API.PRICEBOOK_CREATE.type,
			url: API.PRICEBOOK_CREATE.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			getProduct()
			setOpen(false)
		}

		
	}

	const handleDeletePricebook = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
  			cancelButtonText: 'No, cancel!',
		}).then(async (result)=>{
			if(result.isConfirmed){
				console.log('delete',id)
				const requestBody = {
					user_product_pricebook_id: id,
				}
				const params = {
					reqBody: requestBody,
					method: API.PRICEBOOK_DELETE.type,
					url: API.PRICEBOOK_DELETE.url,
					access: 'auth' 
				}
		
				const response = await Api(params)
				if(response.status){
					getProduct()
				}
			}
		})
	}
	
	return (
		<React.Fragment>
			<AuthToolbar 
				title= {`Detail Product`}
				breadcumb = {breadcumb}
			/>

			<Box
				className="container-xxl"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
					m: 0,
					width: "100%",

					},
				}}
			>
				
				<Paper 
					elevation={3} 
					sx = {{
						p: 5
					}}
					className="col-xl-12 papper"
				>
					<form >
						<div className="form-title" 
							// style={{padding:"40px 40px 10px 40px"}}
						>
							Detail Product
						</div>

						<div className="form-content">
							<div className="product-container">
								<ImageGallery items={item_files}/>
								<div className="product-container-item">
									<div className="title">{product.product}</div>
									<div className="price">Rp. {Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(product.price)}</div>
									<div className="discount-price">price after discount, Rp. {Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(product.discount_price)}</div>
									<div className="item-container">
										<div className="item-label">Publisher</div>
										<div className="item-value">{product.user_inventory_item.user_inventory.user.fullname}</div>
										<div className="item-label">Channel</div>
										<div className="item-value">{product.user_inventory_item.user_inventory.inventory.inventory}</div>
										<div className="item-label">Asset</div>
										<div className="item-value">{product.user_inventory_item.item_name}</div>
										<div className="item-label">{label}</div>
										<div className="item-value">{location}</div>
									</div>
								</div>

							</div>

			
							<Box sx={{ width: '100%', typography: 'body1' }}>
								<TabContext value={tab_value}>
									<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
										<TabList onChange={handleTabChange} aria-label="lab API tabs example">
											<Tab label="Desckripsi" value="1" />
											<Tab label="Pricebook" value="2" />
											<Tab label="Availability" value="3" />
										</TabList>
									</Box>
									<TabPanel className="tab-panel" value="1">
										<div className="panel-descripsi-container">
											<div className="panel-desc">
												{product.description}
											</div>
											<div className="panel-map">
												<div className="form-google-map">
													<Gmap
														lat={lat}
														long={long}
														marker = {product.user_inventory_item.item_name}
													/>
												</div>
											</div>
										</div>
										<div className="tab-panel-title">
											INVENTORY PROFILE
										</div>
										<div className="panel-profile-container">
											{product.user_inventory_item.item_profiles.map((data,i)=>(
												<div key={i} className="item-profile">
													<div className="i-label">{data.profile_label}</div>
													<div className="i-value">{data.profile_value}</div>
												</div>
											))}
											
										</div>
										<div className="tab-panel-title">
											KETENTUAN
										</div>
										<div className="panel-profile-container">
											<div className="item-profile">
												<div className="i-label">Satuan Penjualan</div>
												<div className="i-value">{product.user_product_setting.unit}</div>
											</div>
											<div className="item-profile">
												<div className="i-label">Deskripsi satuan</div>
												<div className="i-value">{product.user_product_setting.unit_desc}</div>
											</div>
											<div className="item-profile">
												<div className="i-label">Customer input jadwal</div>
												<div className="i-value">{product.user_product_setting.customer_need_schedule}</div>
											</div>
											<div className="item-profile">
												<div className="i-label">Jenis Penjadwalan</div>
												<div className="i-value">{product.user_product_setting.schedule_type}</div>
											</div>
											<div className="item-profile">
												<div className="i-label">Masa tayang</div>
												<div className="i-value">{product.user_product_setting.publish_duration} {product.user_product_setting.schedule_type}</div>
											</div>
											<div className="item-profile">
												<div className="i-label">Customer upload file</div>
												<div className="i-value">{product.user_product_setting.customer_need_upload_file}</div>
											</div>
											<div className="item-profile">
												<div className="i-label">Konten dibuat oleh</div>
												<div className="i-value">{product.user_product_setting.content_create_by}</div>
											</div>
											<div className="item-profile">
												<div className="i-label">resolusi video</div>
												<div className="i-value">{product.user_product_setting.video_hres} x {product.user_product_setting.video_vres} pixel</div>
											</div>
											<div className="item-profile">
												<div className="i-label">Durasi konten</div>
												<div className="i-value">{product.user_product_setting.content_file_duration} detik</div>
											</div>

										</div>

									</TabPanel>
									<TabPanel className="tab-panel" value="2">
										<Pricebook 
											data={product.pricebook}
											partners = {partners}
											product = {product}
											onCreatePricebook = {(value)=>handleCreatePricebook(value)}
											onDelete = {(value)=>handleDeletePricebook(value)}
										/>
									</TabPanel>
									<TabPanel className="tab-panel" value="3">Availability</TabPanel>
								</TabContext>
							</Box>
							

							

							

							


							

							

							

							

							

							

							
						</div>
						<div className="form-action">
							<button type="button" style={{marginRight: 10}} className="btn btn-light" onClick= {()=>window.history.back()}>Close</button>
							
						</div>
					</form>
				</Paper>
			</Box>
		</React.Fragment>

	)
}
