
import React from 'react';
import GoogleMapReact from 'google-map-react';

export default function Gmap (props) {

	const renderMarkers = (map, maps) => {
		let marker = new maps.Marker({
	 		position: { 
				lat: props.lat, 
				lng: props.long 
			},
	 		map,
	 		title: props.marker
	 	});
	 	return marker;
	};

	

	return (
		<div style={{ 
			height: "calc((100vw) * 0.15)",
			// height: '50vh', 
			width: '100%' }}>

			
		<GoogleMapReact
			bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API }}
			yesIWantToUseGoogleMapApiInternals={true}
			center={{
				lat: props.lat,
				lng: props.long
			}}
			zoom={17}
			onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
		>
			{/* // marking */}
      </GoogleMapReact>
	  </div>
	);
   };

