import React, { Component } from 'react';
import UploadFile from '../Inventories/UploadFile';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

const imageStyle = {
	display: "grid",
	gridTemplateColumns: "auto auto auto auto",
	gridColumnGap: "10px",
    gridRrowGap: "10px",
	marginTop: "20px",
	marginBottom: "20px"
}


export default class ProductFile extends Component {

	componentDidMount(){
		// console.log('componentDidMount')
		// console.log('this.props.data')
		// console.log(this.props.data)
	}

	handleImage = (value,index) => {
		const a = this.props.data
		a[index]['image_change']=value

		this.props.onChange(a)
	}

	handleDescChange = (event) => {
		
		const a = this.props.data
		a[parseInt(event.target.name)].file_desc=event.target.value
		this.props.onChange(a)
	}

	render() {
		return (
			<Container >
				<Box sx={{ bgcolor: '#e8f3f9', padding: 1, marginTop: 1, marginBottom: 1, borderRadius: 1 }}>
					<div className="block-title">Files images</div>

					<div style={imageStyle}>
						<div className="block-upload">
							<UploadFile 
								onFileChange={(value,index)=>this.handleImage(value,0)}
								defaultFile={this.props.data[0].file}
								maxResolutionWidth =  {300}
								maxResolutionHeight = {300}
								width={200}
								height={200}
								
							/>
							{/* <FormControl fullWidth>
								<TextField 
									label="Image Description" 
									variant="standard" 
									name={`0`}
									value={this.props.data[0].file_desc}
									onChange={this.handleDescChange}/>
							</FormControl> */}
						</div>

						<div className="block-upload">
							<UploadFile 
								onFileChange={(value,index)=>this.handleImage(value,1)}
								defaultFile={this.props.data[1].file}
								maxResolutionWidth =  {300}
								maxResolutionHeight = {300}
								width={200}
								height={200}
								
							/>
							{/* <FormControl fullWidth>
								<TextField 
									label="Image Description" 
									variant="standard" 
									name={`1`}
									value={this.props.data[1].file_desc}
									onChange={this.handleDescChange}/>
							</FormControl> */}
							{/* <FormControl fullWidth>
								<TextField id="outlined-basic"  label="Image Description" variant="standard"name={1} onChange={this.handleDescChange}/>
							</FormControl> */}
						</div>

						<div className="block-upload">
							<UploadFile 
								onFileChange={(value,index)=>this.handleImage(value,2)}
								defaultFile={this.props.data[2].file}
								maxResolutionWidth =  {300}
								maxResolutionHeight = {300}
								width={200}
								height={200}
								
							/>
							{/* <FormControl fullWidth>
								<TextField 
									label="Image Description" 
									variant="standard" 
									name={`2`}
									value={this.props.data[2].file_desc}
									onChange={this.handleDescChange}/>
							</FormControl> */}
							{/* <FormControl fullWidth>
								<TextField id="outlined-basic"  label="Image Description" variant="standard" name={2} onChange={this.handleDescChange}/>
							</FormControl> */}
						</div>

						<div className="block-upload">
							<UploadFile 
								onFileChange={(value,index)=>this.handleImage(value,3)}
								defaultFile={this.props.data[3].file}
								maxResolutionWidth =  {300}
								maxResolutionHeight = {300}
								width={200}
								height={200}
								
							/>
							{/* <FormControl fullWidth>
								<TextField 
									label="Image Description" 
									variant="standard" 
									name={`3`}
									value={this.props.data[3].file_desc}
									onChange={this.handleDescChange}/>
							</FormControl> */}
							{/* <FormControl fullWidth>
								<TextField id="outlined-basic"  label="Image Description" variant="standard" name={3} onChange={this.handleDescChange}/>
							</FormControl> */}
						</div>
					</div>

				</Box>
			</Container>
		)
	}
}
