import React, { useState, useContext,useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../App";
import {API} from '../../API/api';
import Footer from '../Footer';
// import {ConsoleLog} from '../Helper/Helper'

const qs = require("querystring");

const api = process.env.REACT_APP_API_BASE_URL+API.AUTH_REGISTER.url;

export default function Register(props) {
	const history = useHistory();
	const { state } = useContext(AuthContext);
	let path;
	const kres = '#'
	if(state.isAuth){
		if(props.redirect){
			path = props.redirect
		}else{
			path = `dashboard`
		}
		history.push(path)
	}

	const initialState = {
		fullname: "",
		email: "",
		password: "",
		cpassword: "",
		is_admin: "false",
		isSubmitting: false,
		errorMessage: null,
		submit: true,
		TOC: false
	};

	const [data, setData] = useState(initialState);

	useEffect(()=>{

		if((data.password !== '') && (data.password === data.cpassword ) && data.TOC && (data.fullname !== '') && (data.email !== '')){
			data.submit=true
		}else{
			data.submit=false
		}
		// ConsoleLog(data)
	},[data])

	const handleInputChange = (event) => {
		setData({
			...data,
			[event.target.name]: event.target.value,
		});
	};

	const handleConfirmPassword = (event) =>{
		setData({
			...data,
			[event.target.name]: event.target.value,
		});

		
	}

	const handleTOC = (event) => {
		setData({
			...data,
			TOC: event.target.checked
		})
	}

	const handleFormSubmit = (event) => {
		event.preventDefault();

		setData({
			...data,
			submit: true,
			errorMessage: null,
		});

		const requestBody = {
			fullname: data.fullname,
			email: data.email,
			password: data.password,
			is_admin: data.is_admin,

		};
		// console.log(data)
		axios
			.post(api, qs.stringify(requestBody))
			.then((res) => {
				if (res.data.status === true) {

					if(props.redirect){
						path = props.redirect
					}else{
						path = `registered`
					}
    				history.push(path)

				} else {
					setData({
						...data,
						submit: false,
						errorMessage: res.data.message,
					});
				}
				throw res;
			})
			.catch((e) => {
				if (e.message) {
					setData({
						...data,
						isSubmsubmit: false,
						errorMessage: e.message,
					});
				}
			});
	};

	return (
		<div id="kt_body" className="bg-body">
			<div className="d-flex flex-column flex-root">
				<div
					className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
					style={{bacgroundImage: `url(assets/media/illustrations/sigma-1/14.png)`}}
				>
					<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
						<a href="/" className="mb-12">
							<img
								alt="Logo"
								src="assets/media/logos/logo-1.svg"
								className="h-40px"
							/>
						</a>
						<div className="w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
							<form
								className="form w-100"
								noValidate="novalidate"
								id="kt_sign_up_form"
							>
								<div className="mb-10 text-center">
									<h1 className="text-dark mb-3">
										Create an Account
									</h1>
									<div className="text-gray-400 fw-bold fs-4">
										Already have an account?
										<a
											href="/login"
											className="link-primary fw-bolder"
										>
											Sign in here
										</a>
									</div>
								</div>
								{/* <button
									type="button"
									className="btn btn-light-primary fw-bolder w-100 mb-10"
								>
									<img
										alt="Logo"
										src="assets/media/svg/brand-logos/google-icon.svg"
										className="h-20px me-3"
									/>
									Sign in with Google
								</button> */}
								{/* <div className="d-flex align-items-center mb-10">
									<div className="border-bottom border-gray-300 mw-50 w-100"></div>
									<span className="fw-bold text-gray-400 fs-7 mx-2">
										OR
									</span>
									<div className="border-bottom border-gray-300 mw-50 w-100"></div>
								</div> */}
								<div className="row fv-row mb-7">
									<div className="col-xl-12">
										<label className="form-label fw-bolder text-dark fs-6">
											Full Name
										</label>
										<input
											className="form-control form-control-lg form-control-solid"
											type="text"
											placeholder=""
											name="fullname"
											autoComplete="off"
											onChange={handleInputChange}
										/>
									</div>

								</div>
								<div className="fv-row mb-7">
									<label className="form-label fw-bolder text-dark fs-6">
										Email
									</label>
									<input
										className="form-control form-control-lg form-control-solid"
										type="email"
										placeholder=""
										name="email"
										autoComplete="off"
										onChange={handleInputChange}
									/>
								</div>
								<div
									className="mb-10 fv-row"
									data-kt-password-meter="true"
								>
									<div className="mb-1">
										<label className="form-label fw-bolder text-dark fs-6">
											Password
										</label>
										<div className="position-relative mb-3">
											<input
												className="form-control form-control-lg form-control-solid"
												type="password"
												placeholder=""
												name="password"
												autoComplete="off"
												onChange={handleInputChange}
											/>
											<span
												className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
												data-kt-password-meter-control="visibility"
											>
												<i className="bi bi-eye-slash fs-2"></i>
												<i className="bi bi-eye fs-2 d-none"></i>
											</span>
										</div>
										<div
											className="d-flex align-items-center mb-3"
											data-kt-password-meter-control="highlight"
										>
											<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
											<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
											<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
											<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
										</div>
									</div>
									<div className="text-muted">
										Use 8 or more characters with a mix of
										letters, numbers &amp; symbols.
									</div>
								</div>
								<div className="fv-row mb-5">
									<label className="form-label fw-bolder text-dark fs-6">
										Confirm Password
									</label>
									<input
										className="form-control form-control-lg form-control-solid"
										type="password"
										placeholder=""
										name="cpassword"
										autoComplete="off"
										onChange={handleConfirmPassword}
									/>
								</div>
								{((data.password !== data.cpassword) && data.password !== '')?(
									<div className="alert alert-danger" id="not-match">
									<div className="d-flex flex-column">
										<span>password not match</span>
									</div>
								</div>
								):(<div/>)
									
								
								}
								
								
								<div className="fv-row mb-10">
									<label className="form-check form-check-custom form-check-solid form-check-inline">
										<input
											className="form-check-input"
											type="checkbox"
											name="toc"
											value="1"
											onChange={handleTOC}
										/>
										<span className="form-check-label fw-bold text-gray-700 fs-6">
											I Agree
											<a
												href={kres}
												className="ms-1 link-primary"
											>
												Terms and conditions
											</a>
											.
										</span>
									</label>
								</div>
								<div className="text-center">
									<button
										type="button"
										id="kt_sign_up_submit"
										className="btn btn-lg btn-primary"
										disabled= {data.submit}
										onClick={handleFormSubmit}
									>
										<span className="indicator-label">
											Submit
										</span>
									</button>
								</div>
							</form>
							<br/>
							{data.errorMessage && (
								<div className="alert alert-danger">
									<div className="d-flex flex-column">
										<span>{data.errorMessage}</span>
									</div>
								</div>

							)}
						</div>
					</div>
					
					<Footer/>
				</div>
			</div>
			<script src="assets/js/custom/authentication/sign-up/general.js"></script>
		</div>
		
	);
}
