import React, { Component } from "react";

export default class ResetedPage extends Component {
  	render() {
		return (
			<div id="kt_body" className="bg-body">
				<div className="d-flex flex-column flex-root">
					<div className="d-flex flex-column flex-center flex-column-fluid p-10">
						<img
							src="assets/media/illustrations/dozzy-1/7.png"
							alt=""
							class="mw-100 mb-10 h-lg-450px"
						/>
						<h1 className="fw-bold mb-10" style={{ color: "#A3A3C7" }}>
							You have successfully request reset password, please see your email for complate your action
						</h1>
						<a href="/" className="btn btn-primary">
							Return Home
						</a>
					</div>
				</div>
			</div>
		);
  	}
}
