import React from 'react';
import AuthToolbar from '../Layout/AuthToolbar'

const ModuleContent = () => {
    const breadcumb = [
		{
			item: 'Home',
			path: '/'
		},
		{
			item: 'Settings',
			path: ''
		},
        {
			item: 'Modules',
			path: ''
		}
    ]
    return (
        <React.Fragment>
            <AuthToolbar 
                title='Modules'
                breadcumb = {breadcumb}
            />
        </React.Fragment>
    );
}

export default ModuleContent;