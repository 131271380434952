import React from 'react'
// import AuthFooter from '../Layout/AuthFooter'
import AuthHeader from '../Layout/AuthHeader'
import ProductsContent from '../Products/ProductsContent'
import ScrollTop from '../Landing/ScrollTop'

export default function BrowseProduct() {
	return (
		<div id="kt_body"  className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled" style={{backgroundImage: `url(../../assets/media/patterns/header-bg.jpg)`,backgroundRepeat  : 'no-repeat'}}>
			{/* <!--begin::Main--> */}
			<div className="d-flex flex-column flex-root">
				{/* <!--begin::Page--> */}
				<div className="page d-flex flex-row flex-column-fluid">
					{/* <!--begin::Wrapper--> */}
					<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
						<AuthHeader/>
						
						<ProductsContent 
							header={"Pilih product"}
							newButton={false}
							editButton={false}
							viewButton={true}
							createCampaign = {true}
							title = {"Pilih product"}
							breadcumb ={
								[
									{
										item: 'Home',
										path: '/'
									},
									{
										item: 'Campaign',
										path: ''
									},
									{
										item: 'Select Product',
										path: ''
							
									}
								]
							}


						/> 
						{/* <AuthFooter/> */}
					</div>
					{/* <!--end::Wrapper--> */}
				</div>
				{/* <!--end::Page--> */}
			</div>
			
			{/* <!--end::Modals--> */} 
			<ScrollTop/> 
			{/* <!--end::Main--> */} 
			<script>var hostUrl = "assets/";</script>
		</div>
	)
}
