import React, { Component } from 'react'
import AuthToolbar from '../Layout/AuthToolbar'
import Pagination from '@mui/material/Pagination';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import Stack from '@mui/material/Stack';

import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';

import '../Pagination/Pagination.css';
import FilterCompany from './FilterCompany';
import CompanyModal from './CompanyModal';
import CompanyData from './CompanyData';

const breadcumb = [
	{
		item: 'Home',
		path: '/'
	},
	{
		item: 'Settings',
		path: ''
	},
	{
		item: 'Companies',
		path: ''
	}
]

export default class CompanyContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Pagination : {
				totalPage: 1,
				activePage: 1,
				perPage: 5,

			},
			Companies: [],
			Company: {
				company_id: '',
				company: '',
				email: '',
				contact: '',
				address: '',
				free: 0,

			},
			order: [],
			filter: [
				{
					field: 'company',
					operator: 'LIKE',
					value: '%'
				},
				{
					field: 'email',
					operator: 'LIKE',
					value: '%'
				}
			],
			showCompanyModal: false,
			companyActionMode : 'NEW',
		}
		
	}

	componentDidMount() {
		// get companies
	
		this.reloadContentData()
	}

	reloadContentData = async () => {

		const requestBody = {
			page: this.state.Pagination.activePage,
			rows: this.state.Pagination.perPage,
			filter: this.state.filter,
		}
		const params = {
			reqBody: requestBody,
			method: API.COMPANY_GETS.type,
			url: API.COMPANY_GETS.url,
			access: 'auth' 
		}

		const response = await Api(params)
		// console.log(response)
		if(response.status){

			
			this.setState({
				Pagination : {
					totalPage: response.data.lastPage,
					activePage: response.data.page,
					perPage: response.data.perPage,
	
				},
				Companies : response.data.data
			},()=>{
				// console.log(this.state)
			})
		}


	}

	handlePaginationChange = async (event,value) =>{
		// console.log(value)
		let old_pagination = this.state.Pagination;
		old_pagination.activePage= value;
		await this.setState({
			Pagination: old_pagination
		},()=>{
			// console.log(this.state)
			this.reloadContentData()
		})

		// this.reloadContentData()
	}

	handleFilter = (filter) => {
		// console.log('filter')
		// console.log(filter)
		this.setState({
			filter: [
				{
					field: 'company',
					operator: 'LIKE',
					value: (filter.company==='')?'%':`%${filter.company}%`
				},
				{
					field: 'email',
					operator: 'LIKE',
					value: (filter.email==='')?'%':`%${filter.email}%`
				},
				
				

			]
		},()=>{
			// console.log('after state ---- filter')
			// console.log(this.state)
			this.reloadContentData()
		})
	}

	handleNewCompany = async () =>{
		this.setState({
			Company: {
				company_id: '',
				company: '',
				email: '',
				contact: '',
				address: '',
				free: 0,

			},
			showCompanyModal: true,
			companyActionMode: 'NEW'
		})
	}

	handleCloseCompanyModal = () =>{
		this.setState({
			showCompanyModal: false,
		})
	}

	handleChangeCompany = (event) => {
		// console.log(event.target.name +' = '+ event.target.value)
		const a = this.state.Company;
		a[event.target.name]=event.target.value
		this.setState({
			Company: a
		},()=>{
			// console.log(this.state.Company)
		})

	}

	handleCompanySave = async () => {
		let requestBody;
		let params;
		let response;
		
		switch (this.state.companyActionMode) {
			case 'NEW':
				requestBody = {
					email: this.state.Company.email,
					company: this.state.Company.company,
					contact: this.state.Company.contact,
					address: this.state.Company.address,
				};
				params = {
					reqBody: requestBody,
					method: API.COMPANY_CREATE.type,
					url: API.COMPANY_CREATE.url,
					access: 'auth' 
				}
				

				break;
			
			case 'EDIT':
				requestBody = {
					company_id: this.state.Company.company_id,
					email: this.state.Company.email,
					company: this.state.Company.company,
					contact: this.state.Company.contact,
					address: this.state.Company.address,
				};
				params = {
					reqBody: requestBody,
					method: API.COMPANY_UPDATE.type,
					url: API.COMPANY_UPDATE.url,
					access: 'auth' 
				}
				break;
			
			
		
			default:
				break;
		}

		response = await Api(params)
		if(response.status){
			this.setState({
				showCompanyModal: false
			})
			this.reloadContentData()
			
			
		}
	}

	handleDelete = async (id) => {
		Swal.fire({
			title: 'Delete Company',
			text: 'Do you want to continue',
			icon: 'error',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Delete',
			confirmButtonColor: '#fc0000'
		}).then(async (result)=>{
			if(result.isConfirmed){

				const requestBody = {
					company_id: id,
				};
		
				const params = {
					reqBody: requestBody,
					method: API.COMPANY_DELETE.type,
					url: API.COMPANY_DELETE.url,
					access: 'auth' 
				}
		
				const response = await Api(params)
				if(response.status){
					
					this.reloadContentData()
				}

				


			}
		})
	}

	handleEditCompany = async (id) => {
		let requestBody
		let params
		let response
		requestBody = {
			company_id: id
		};
		params = {
			reqBody: requestBody,
			method: API.COMPANY_GET.type,
			url: API.COMPANY_GET.url,
			access: 'auth' 
		}
		response = await Api(params)

		if(response.status){
			this.setState({
				Company: response.data,
				showCompanyModal: true,
				companyActionMode: 'EDIT'
			})
		}
	}

	

	render() {
		return (
			<React.Fragment>
				<AuthToolbar 
					title='Companies'
					breadcumb = {breadcumb}
				/>
				<div className="container-xxl d-flex flex-stack flex-wrap" style={{marginBottom: 20}}>
					<div className="d-flex flex-column me-3">
						<div className="d-flex align-items-center py-3 py-md-1">
							<div className='me-4'>
								<button type="button" className="btn btn-primary btn-sm btn-light" style={{marginRight:10}} 
									onClick={()=>this.handleNewCompany()}
								>
									Create New Company
								</button>
							</div> 
						</div>
					</div>
					<div className="d-flex align-items-center py-3 py-md-1">
						<div className="d-flex align-items-center py-3 py-md-1">
							<div className='me-4'>
								
								<FilterCompany onUpdateFilter={(value) => this.handleFilter(value) }/>
							</div>

						</div>		
					</div>
				</div>

				<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
					<div className="content flex-row-fluid" id="kt_content">
						<div className="row gy-5 g-xl-8">
							<div className="col-xl-12">
								
								{this.state.Companies.map((data,i)=>(
									<CompanyData 
										key={data.company_id}
										data={data}
										edit={()=>this.handleEditCompany(data.company_id)}
										delete={()=>this.handleDelete(data.company_id)}

									/>	
								))}
								<Pagination count={this.state.Pagination.totalPage}  variant="outlined" shape="rounded" onChange={this.handlePaginationChange} />
									
								
							</div>
						</div>
					</div>
				</div>

				<CompanyModal 
					company={this.state.Company}
					open= {this.state.showCompanyModal}
					closeModal = {this.handleCloseCompanyModal}
					companies={this.state.companies}
					onChangeCompany={this.handleChangeCompany}
					onSaveBtn={this.handleCompanySave}
					modalMode={this.state.companyActionMode}
				/>

			</React.Fragment>
		)
	}
}


