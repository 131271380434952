import React, { Component } from 'react';
import AuthToolbar from '../Layout/AuthToolbar'
import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';

import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';

import '../Pagination/Pagination.css';
import UserData from './UserData';
import FilterUser from './FilterUser';
import UserModal from './UserModal';


// const qs = require("querystring");

const breadcumb = [
	{
		item: 'Home',
		path: '/'
	},
	{
		item: 'Settings',
		path: ''
	},
	{
		item: 'Users',
		path: ''
	}
]

export default class UserContent extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			Pagination : {
				totalPage: 1,
				activePage: 1,
				perPage: 5,

			},
			Users: [],
			User: {
				user_id: '',
				fullname: '',
				email: '',
				phone_number: '',
				is_admin: 'false',
				status: 'NOT ACTIVE',
				company_id: '',
				group_id: '',
				ms_province_id: '',
				ms_city_id: '',
				password: '',
				retype_password: '',

			},
			order: [],
			filter: [
				{
					field: 'is_admin',
					operator: 'LIKE',
					value: '%'
				},
				{
					field: 'email',
					operator: 'LIKE',
					value: '%'
				},
				{
					field: 'status',
					operator: 'LIKE',
					value: '%'
				}
			],
			showUserModal: false,
			showPasswordModal: false,
			userActionMode : 'NEW',
			companies: []
		}
		
	}

	componentDidMount() {
		// get companies
		this.getCompanies()

		this.reloadContentData()
	}
    
	getCompanies = async () => {
		const params = {
			reqBody: null,
			method: API.COMPANY_GET_ALL.type,
			url: API.COMPANY_GET_ALL.url,
			access: 'auth' 
		}

		const response = await Api(params)
		// console.log(response)
		if(response.status){

			
			this.setState({
				companies : response.data
			},()=>{
				// console.log(this.state)
			})
		}


	}

	reloadContentData = async () => {
		// console.log('this.state.filter')
		// console.log(this.state.filter)
		const requestBody = {
			page: this.state.Pagination.activePage,
			rows: this.state.Pagination.perPage,
			filter: this.state.filter,
			// order: this.state.order,
		};
		

		const params = {
			reqBody: requestBody,
			method: API.USER_GETS.type,
			url: API.USER_GETS.url,
			access: 'auth' 
		}

		const response = await Api(params)
		// console.log(response)
		if(response.status){

			
			this.setState({
				Pagination : {
					totalPage: response.data.lastPage,
					activePage: response.data.page,
					perPage: response.data.perPage,
	
				},
				Users: response.data.data
			},()=>{
				// console.log(this.state)
			})
		}
		

        
	}


	handlePaginationChange = async (event,value) =>{
		// console.log(value)
		let old_pagination = this.state.Pagination;
		old_pagination.activePage= value;
		await this.setState({
			Pagination: old_pagination
		},()=>{
			// console.log(this.state)
			this.reloadContentData()
		})

		// this.reloadContentData()
	}

	handleFilter = (filter) => {
		// console.log('filter')
		// console.log(filter)
		this.setState({
			filter: [
				{
					field: 'email',
					operator: 'LIKE',
					value: (filter.email==='')?'%':`%${filter.email}%`
				},
				{
					field: 'is_admin',
					operator: 'LIKE',
					value: filter.member_type
				},
				{
					field: 'status',
					operator: 'LIKE',
					value: (filter.status==='')?'%':filter.status
				},

			]
		},()=>{
			// console.log('after state ---- filter')
			// console.log(this.state)
			this.reloadContentData()
		})
	}

	handleCloseUserModal = () => {
		this.setState({
			showUserModal: false
		})
	}

	handleNewUser = async () =>{
		this.setState({
			User: {
				user_id: '',
				fullname: '',
				email: '',
				phone_number: '',
				is_admin: 'false',
				status: 'NOT ACTIVE',
				company_id: '',
				group_id: '',
				ms_province_id: '',
				ms_city_id: '',
				password: '',
				retype_password:''

			},
			showUserModal: true,
			userActionMode: 'NEW'
		})
	}

	handleChangeUser = async (event) => {
		// console.log(event.target.name +' = '+ event.target.value)
		let oldStateStatus = this.state.User;
		oldStateStatus[event.target.name]=event.target.value
		this.setState({
			User: oldStateStatus
		})

	}

	handleUserSave = async () => {
		let requestBody;
		let params;
		let response;
		// console.log('SAVE '+ this.state.userActionMode)
		
		switch (this.state.userActionMode) {
			case 'NEW':
				// console.log(this.state.User)
				// if(this.state.User.password !== this.state.User.repeat_password || this.state.User.password === ''){
				// 	Swal.fire({
				// 		icon: 'error',
				// 		text: 'Password not same or blank',
				// 	})
				// 	return true;
				// } 
				
				requestBody = {
					email: this.state.User.email,
					fullname: this.state.User.fullname,
					is_admin: this.state.User.is_admin,
					company_id: this.state.User.company_id,
					password: this.state.User.password,
					retype_password: this.state.User.retype_password,
					phone_number: this.state.User.phone_number
					// order: this.state.order,
				};
				params = {
					reqBody: requestBody,
					method: API.USER_CREATE.type,
					url: API.USER_CREATE.url,
					access: 'auth' 
				}
				

				break;
			
			case 'EDIT':
				requestBody = {
					user_id: this.state.User.user_id,
					fullname: this.state.User.fullname,
					company_id: this.state.User.company_id,
					phone_number: this.state.User.phone_number
					// order: this.state.order,
				};
				params = {
					reqBody: requestBody,
					method: API.USER_UPDATE.type,
					url: API.USER_UPDATE.url,
					access: 'auth' 
				}
				break;
			
			case 'PASSWORD':
				requestBody = {
					user_id: this.state.User.user_id,
					
					password: this.state.User.password,
					retype_password: this.state.User.retype_password
					// order: this.state.order,
				};
				params = {
					reqBody: requestBody,
					method: API.USER_UPDATE.type,
					url: API.USER_UPDATE.url,
					access: 'auth' 
				}
				break;
		
			default:
				break;
		}

		response = await Api(params)
		if(response.status){
			this.setState({
				showUserModal: false
			})
			this.reloadContentData()
			
			
		}

		
		

		

		
		// // console.log(response)
		

	}

	handleEditUser = async(id)=>{
		let requestBody
		let params
		let response
		requestBody = {
			user_id: id
		};
		params = {
			reqBody: requestBody,
			method: API.USER_GET.type,
			url: API.USER_GET.url,
			access: 'auth' 
		}
		response = await Api(params)

		if(response.status){
			this.setState({
				User: {
					user_id: response.data.user_id,
					fullname: response.data.fullname,
					email: response.data.email,
					phone_number: response.data.phone_number,
					is_admin: response.data.is_admin,
					status: response.data.status,
					company_id: response.data.company_id,
					group_id: response.data.group_id,
					ms_province_id: response.data.ms_province_id,
					ms_city_id: response.data.ms_city_id,
					password: '',
					retype_password:''
				},
				showUserModal: true,
				userActionMode: 'EDIT'
			})
		}
	}

	handleChangePassword = (val) =>{
		const data = this.state.User
		data.user_id = val;
		this.setState({
			User: data,
			userActionMode: 'PASSWORD',
			showUserModal: true,
		})
	}

	render() {

		
		return (
			<React.Fragment>
				<AuthToolbar 
					title='Users'
					breadcumb = {breadcumb}
				/>
				
				<div className="container-xxl d-flex flex-stack flex-wrap" style={{marginBottom: 20}}>
					<div className="d-flex flex-column me-3">
						<div className="d-flex align-items-center py-3 py-md-1">
							<div className='me-4'>
								<button type="button" className="btn btn-primary btn-sm btn-light" style={{marginRight:10}} 
									onClick={()=>this.handleNewUser()}
								>
									Create New User
								</button>
							</div> 
						</div>
					</div>
					<div className="d-flex align-items-center py-3 py-md-1">
						<div className="d-flex align-items-center py-3 py-md-1">
							<div className='me-4'>
								
								<FilterUser onUpdateFilter={(value) => this.handleFilter(value) }/>
							</div>

						</div>		
					</div>
				</div>

				

				<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
					<div className="content flex-row-fluid" id="kt_content">
						<div className="row gy-5 g-xl-8">
							<div className="col-xl-12">
								{/* Content */}
								{
									this.state.Users.map((data,i)=> {
										return ( 
											<UserData key={data.user_id}
												data={data}
												editUser={()=>this.handleEditUser(data.user_id)}
												changePassword={()=>this.handleChangePassword(data.user_id)}
											/>
										)
									})
								}
								
								<Pagination count={this.state.Pagination.totalPage}  variant="outlined" shape="rounded" onChange={this.handlePaginationChange} />
									
								
							</div>
						</div>
					</div>
				</div>





				<UserModal 
					user={this.state.User}
					open= {this.state.showUserModal}
					closeUserModal = {this.handleCloseUserModal}
					companies={this.state.companies}
					onChangeUser={this.handleChangeUser}
					onSaveBtn={this.handleUserSave}
					modalMode={this.state.userActionMode}
				/>
				
			</React.Fragment>
		)
	}
}

