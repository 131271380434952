import React from 'react';
import AuthToolbar from '../Layout/AuthToolbar'

const ApprovalContent = () => {
    const breadcumb = [
		{
			item: 'Home',
			path: '/'
		},
		{
			item: 'My Inventories',
			path: ''
		}
    ]
    return (
        <React.Fragment>
            <AuthToolbar 
                title='My Inventories'
                breadcumb = {breadcumb}
            />
        </React.Fragment>
    );
}

export default ApprovalContent;