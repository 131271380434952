import { useReducer, createContext } from "react";
import { BrowserRouter} from "react-router-dom";
import Router from "./Router";
// import theme from './Theme'
// import { ThemeProvider } from '@material-ui/core/styles';
import './Global.css'
import SecureLS from 'secure-ls';
var ls = new SecureLS({ encodingType: 'aes' })

// Context
export const AuthContext = createContext();
    
// Initial state
const initialState = {
	isAuth: false,
	user: null,
	token: null,
};  


const reducer = (state, action) => {
	switch (action.type) {
		case "LOGIN":


			// localStorage.setItem("authUser", JSON.stringify(action.payload));
			ls.set("authUser", JSON.stringify(action.payload));

			// const a = ls.get("authUser");

			return {
				...state,
				isAuth: true,
				authUser: action.payload,
			};

		case "LOGOUT":
			localStorage.clear();
			return {
				...state,
				isAuth: false,
				authUser: null,
			};

		default:
			return state;
	}
};

function App() {
	const [state, dispatch] = useReducer(reducer, initialState);

	const saved = localStorage.getItem("authUser");

	if(saved){
		state.isAuth = true;
		state.authUser = saved;
	}

	return (
		// <ThemeProvider theme={theme}>
			<BrowserRouter>
				<AuthContext.Provider
						value={{
							state,
							dispatch,
						}}
					>
					<Router/>
				</AuthContext.Provider>
			</BrowserRouter>
		// </ThemeProvider>
	);
}

export default App;
