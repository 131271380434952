import React from 'react'
import './ChannelModal.css'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


export default function ChannelModal(props) {
	return (
		<Dialog
			fullWidth={true}
			maxWidth = 'md'
			open={props.open}
			onClose={props.onClose}
			
		>
			<DialogTitle>
				Tambah Channel
			</DialogTitle>
			<DialogContent>
				<FormControl component="fieldset">
  					<RadioGroup
						aria-label="gender"
						onChange={props.inventoryChange}
						defaultValue="inven"
						name="inventory_id"
					>
						{props.data.map((dt,i)=>(
							(dt.user_inventories.length === 0)?(
								<FormControlLabel key={i} value={dt.inventory_id} control={<Radio />} label={dt.inventory} />
							):(``)
						))}
    					
    					
  					</RadioGroup>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<button type="button" className="btn btn-light" onClick= {props.closeChannelModal}>Close</button>
				<button type="button" className="btn btn-primary" onClick={props.onChannelAdd} disabled={(props.inventory===null)?true:false}>Tambahkan</button>
			</DialogActions>
			
		</Dialog>
	)
}
