import React from 'react';
import ImageUploading from 'react-images-uploading';
import './UploadFile.css';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import DeleteIcon from '@mui/icons-material/Delete';



export default function UploadFile(props) {
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;
  const maxResWidth = (props.maxResolutionWidth)?props.maxResolutionWidth:"100";
  const maxResHeight = (props.maxResolutionHeight)?props.maxResolutionHeight:"100";
  const gridWidth = (props.gridWidth)?props.gridWidth:"200px";

   const defaultImg = "../../../../assets/icon/add_pict.png";
	// const defaultImg = AddPhotoAlternateIcon;

  const onChange = async (imageList, addUpdateIndex) => {
    // data for submit
    console.log('imageList',imageList);
    setImages(imageList);
	(imageList.length > 0)?props.onFileChange(imageList[0]):props.onFileChange(null)
	
  };

  

  return (
    <div className="upload-file-container">
		<ImageUploading
			value={images}
			onChange={onChange}
			maxNumber={maxNumber}
			dataURLKey="data_url"
			resolutionType="less"
			resolutionWidth={maxResWidth}
			resolutionHeight={maxResHeight}
      >


        {({
			imageList,
			onImageUpload,
			// onImageRemoveAll,
			onImageUpdate,
			onImageRemove,
			isDragging,
			dragProps,
			errors
        	}) => (

          	// write your building UI
          	<div className="upload__image-wrapper">
            	{/* <button
              		style={isDragging ? { color: 'red' } : undefined}
              		onClick={onImageUpload}
              		{...dragProps}
            	>
              	Click or Drop here
            	</button> */}
            	&nbsp;
            	{/* <button onClick={onImageRemoveAll} disabled={(imageList.length===0)?true:false}>Remove all images</button> */}
				<Card
					sx= {{textAlign:"center"}}
					{...dragProps}
				>
				{(imageList.length>0)?(
					
					imageList.map((image, index) => (
						<div key={index} className="image-item">
							<CardMedia
								component="img"
								src={image['data_url']}
								sx= {{
									height: props.height,
									textAlign: "center"
								}}
								

							/>
							<CardActions >
								
								<Button onClick={() => onImageUpdate(index)}><ImageSearchIcon/></Button>
								<Button onClick={() => onImageRemove(index)}><DeleteIcon/></Button>
							</CardActions >
						</div>
					))
				):(
					<div  className="image-item">
						
						<CardMedia
							component="img"
							src={(!props.defaultFile)?(defaultImg):(props.defaultFile)}
							{...dragProps}
							sx={(!props.defaultFile)?(
								{
									
									width: "50px",
									height: "50px",
									maxWidth: props.gridWidth,
									margin:"auto",
								}
							):(
								{
									width: props.width,
									height: props.height,
									maxWidth: props.gridWidth,
									margin:"auto",
								}
							)}
							onClick={onImageUpload}
						/>

							<CardActions >
								
								
								<Button  disabled={true}><ImageSearchIcon/></Button>
								<Button  disabled={true}><DeleteIcon /></Button>
							</CardActions >

					</div>
				)}
				</Card>

				
         	</div>
        )}



      	</ImageUploading>
    </div>
  );
}