import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../App";
import {API} from '../../API/api';
// import {ConsoleLog} from '../Helper/Helper'

import Footer from '../Footer'

const qs = require("querystring");


const api = process.env.REACT_APP_API_BASE_URL+API.AUTH_RESET.url;

export default function Reset(props) {
	const history = useHistory();
	const { state } = useContext(AuthContext);
	let path;

	if(state.isAuth){
		if(props.redirect){
			path = props.redirect
		}else{
			path = `dashboard`
		}
		history.push(path)
	}

	const initialState = {
		email: "",
		isSubmitting: false,
		errorMessage: null,
	};

	const [data, setData] = useState(initialState);

	useEffect(()=>{
		// ConsoleLog(data)
	},[data])


	const handleInputChange = (event) => {
		setData({
			...data,
			[event.target.name]: event.target.value,
		});
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();

		setData({
			...data,
			isSubmitting: true,
			errorMessage: null,
		});

		const requestBody = {
			email: data.email,
		};

		axios
			.post(api, qs.stringify(requestBody))
			.then((res) => {
				// ConsoleLog(res.data)
				if (res.data.status === true) {

					if(props.redirect){
						path = props.redirect
					}else{
						path = `/reseted`
					}
    				history.push(path)

				} else {
					setData({
						...data,
						isSubmitting: false,
						errorMessage: res.data.message,
					});
				}
				throw res;
			})
			.catch((e) => {
				if (e.message) {
					setData({
						...data,
						isSubmitting: false,
						errorMessage: e.message,
					});
				}
			});
	};

  	return (
		<div id="kt_body" className="bg-body">
			<div className="d-flex flex-column flex-root">
				<div
					className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
					style={{
						backgroundImage: `url(assets/media/illustrations/sigma-1/14.png) `,
						backgroundPosition: 'center',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						width: '100vw',
						height: '100%'
					}}
				>
					<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
						<a href="../../demo2/dist/index.html" className="mb-12">
							<img
								alt="Logo"
								src="assets/media/logos/logo-1.svg"
								className="h-40px"
							/>
						</a>
						<div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
							<form
								className="form w-100"
								noValidate="novalidate"
								id="kt_password_reset_form"
							>
								<div className="text-center mb-10">
									<h1 className="text-dark mb-3">Forgot Password ?</h1>
									<div className="text-gray-400 fw-bold fs-4">
										Enter your email to reset your password.
									</div>
								</div>
								<div className="fv-row mb-10">
									<label className="form-label fw-bolder text-gray-900 fs-6">
										Email
									</label>
									<input
										className="form-control form-control-solid"
										type="email"
										placeholder=""
										name="email"
										autoComplete="off"
										onChange={handleInputChange}
									/>
								</div>
								<div className="d-flex flex-wrap justify-content-center pb-lg-0">
									<button
										type="button"
										id="kt_password_reset_submit"
										className="btn btn-lg btn-primary fw-bolder me-4"
										onClick={handleFormSubmit}
										disabled = {data.isSubmitting}
									>
										<span className="indicator-label">Submit</span>
										<span className="indicator-progress">
											Please wait...
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									</button>
									<a
										href="/"
										className="btn btn-lg btn-light-primary fw-bolder"
									>
										Cancel
									</a>
								</div>
							</form>

							<br/>
							{data.errorMessage && (
								<div className="alert alert-danger">
									<div className="d-flex flex-column">
										<span>{data.errorMessage}</span>
									</div>
								</div>

							)}
						</div>
					</div>

					<Footer/>
					
				</div>
			</div>
			<script>var hostUrl = "assets/";</script>
			<script src="assets/js/custom/authentication/password-reset/password-reset.js"></script>
		</div>
  	);
}
