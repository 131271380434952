import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

export default function ProfileModal(props) {
	return (
		<React.Fragment>
			<Dialog
				fullWidth={false}
				maxWidth = 'lg'
				open={props.open}
				
			>
				<DialogTitle>Profile</DialogTitle>
				<DialogContent >
					<Container>
						<FormControl sx={{ m: 0, minWidth: 120 }}>
							<Select
								value={props.data.profile_key}
								onChange={props.onChange}
								variant="standard"
								name="profile_key"
							>
								{props.inventory_profiles.map((data,i)=>(
									<MenuItem key={i} value={data.profile}>{data.profile_name}</MenuItem>
								))}
								
							</Select>
						</FormControl>
						<FormControl fullWidth>
							<TextField 
								label="Profile value"
								variant="standard" 
								name="profile_value"
								value={props.data.profile_value}
								onChange={props.onChange}
							/>
						</FormControl>
					</Container>

				</DialogContent>
				<DialogActions>
					<Button  variant="outlined" onClick={props.onSave}>Save</Button>
					<Button onClick={props.onClose}>Close</Button>
				</DialogActions>
			</Dialog>

			
		</React.Fragment>
		
	)
}
