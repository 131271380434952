import React from 'react'
import defaultImg from '../../assets/media/icons/duotune/coding/cod001.svg'

export default function CategoryData(props) {
	const handleDeleteBtn = (id) => {
		props.onDeleteCategory(id)
	}

	const handleEditBtn = (id) => {
		props.onEditCategory(id)
	}

	
	return (
		<div className="card shadow-sm" style={{marginBottom: 5}}>
			<div className="card-header">
				<h3 className="card-title">
					<img src={(!props.data.icon)?defaultImg:props.data.icon} alt="icon"/>
					 {props.data.category}</h3>
				<div className="card-toolbar">
					
					<button className="btn btn-icon btn-danger btn-sm btn-light" style={{marginRight:10}} onClick={()=>handleDeleteBtn(props.data.inventory_category_id)}><i className="fas fa-minus" ></i></button>
					<button className="btn btn-icon btn-success btn-sm btn-light" onClick={()=>handleEditBtn(props.data.inventory_category_id)}><i className="fas fa-edit"></i></button>
				</div>
			</div>
		</div>
	)
}
