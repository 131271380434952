import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../App";
import {API} from '../../API/api';
import Footer from '../Footer';
// import {ConsoleLog} from '../Helper/Helper'


const qs = require("querystring");


const api = process.env.REACT_APP_API_BASE_URL+API.AUTH_LOGIN.url;

// ConsoleLog(api)
export default function Login(props) {
	const history = useHistory();
	const { state, dispatch } = useContext(AuthContext);
	let path;

	

	const initialState = {
		email: "test",
		password: "123456",
		isSubmitting: false,
		errorMessage: null,
	};

	const [data, setData] = useState(initialState);

	

	useEffect(()=>{
		// ConsoleLog(data)
	},[data])


	const handleInputChange = (event) => {
		setData({
			...data,
			[event.target.name]: event.target.value,
		});
		// ConsoleLog(data)
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();

		setData({
			...data,
			isSubmitting: true,
			errorMessage: null,
		});

		const requestBody = {
			email: data.email,
			password: data.password,
		};
		// ConsoleLog(data)
		axios
			.post(api, qs.stringify(requestBody))
			.then((res) => {
				if (res.data.status === true) {
					dispatch({
						type: "LOGIN",
						payload: res.data.data,
					});


					if(props.redirect){
						path = props.redirect
					}else{
						path = `dashboard`
					}
    				history.push(path)

				} else {
					setData({
						...data,
						isSubmitting: false,
						errorMessage: res.data.message,
					});
				}
				throw res;
			})
			.catch((e) => {
				if (e.message) {
					setData({
						...data,
						isSubmitting: false,
						errorMessage: e.message,
					});
				}
			});
	};

	if(state.isAuth){
		if(props.redirect){
			path = props.redirect
		}else{
			path = `/dashboard`
		}
		
		window.location.replace(process.env.REACT_APP_BASE_URL+path);

		// return <Redirect to={path}  />
		// history.push(path)
	}

	return (
		<div id="kt_body" className="bg-body">



			<div className="d-flex flex-column flex-root">
				<div
					className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
					style={{
						backgroundImage: `url(../../assets/media/illustrations/sigma-1/14.png) `,
						backgroundPosition: 'center',
						// backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						width: '100vw',
						height: '100%'
					}}
				>
					<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
						<a href="/" className="mb-12">
							<img
								alt="Logo"
								src="../../assets/media/logos/mediacartz_logo.png"
								className="h-40px"
							/>
						</a>
						<div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
							<form
								className="form w-100"
								noValidate="noValidate"
								id="kt_sign_in_form"
								action="#"
							>
								<div className="text-center mb-10">
									<h1 className="text-dark mb-3">
										Sign In to Mediacartz Publisher
									</h1>
									<div className="text-gray-400 fw-bold fs-4">
										New Here?
										<a
											href="/register"
											className="link-primary fw-bolder"
										>
											{" "}
											Create an Account
										</a>
									</div>
								</div>
								<div className="fv-row mb-10">
									<label className="form-label fs-6 fw-bolder text-dark">
										Email
									</label>
									<input
										className="form-control form-control-lg form-control-solid"
										type="text"
										name="email"
										autoComplete="off"
										value={data.email}
										onChange={handleInputChange}
									/>
								</div>
								<div className="fv-row mb-10">
									<div className="d-flex flex-stack mb-2">
										<label className="form-label fw-bolder text-dark fs-6 mb-0">
											Password
										</label>
										<a
											href="/reset"
											className="link-primary fs-6 fw-bolder"
										>
											Forgot Password ?
										</a>
									</div>
									<input
										className="form-control form-control-lg form-control-solid"
										type="password"
										name="password"
										autoComplete="off"
										onChange={handleInputChange}
									/>
								</div>
								<div className="text-center">
									<button
										type="submit"
										id="kt_sign_in_submit"
										className="btn btn-lg btn-primary w-100 mb-5"
										onClick={handleFormSubmit}
										disabled={data.isSubmitting}
									>
										<span className="indicator-label">
											Continue
										</span>
										<span className="indicator-progress">
											Please wait...
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									</button>

									{data.errorMessage && (
										<div className="alert alert-danger">
											<div className="d-flex flex-column">
												<span>{data.errorMessage}</span>
											</div>
										</div>

									)}

									{/* <div className="text-center text-muted text-uppercase fw-bolder mb-5">
										or
									</div> */}
									{/* <a
										href={kres}
										className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
									>
										<img
											alt="Logo"
											src="assets/media/svg/brand-logos/google-icon.svg"
											className="h-20px me-3"
										/>
										Continue with Google
									</a> */}
									{/* <a
										href={kres}
										className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
									>
										<img
											alt="Logo"
											src="assets/media/svg/brand-logos/facebook-4.svg"
											className="h-20px me-3"
										/>
										Continue with Facebook
									</a> */}
									{/* <a
										href={kres}
										className="btn btn-flex flex-center btn-light btn-lg w-100"
									>
										<img
											alt="Logo"
											src="assets/media/svg/brand-logos/apple-black.svg"
											className="h-20px me-3"
										/>
										Continue with Apple
									</a> */}
								</div>
							</form>
						</div>
					</div>
					
					<Footer/>
				</div>
			</div>
			<script>var hostUrl = "assets/";</script>
			<script src="../../assets/plugins/global/plugins.bundle.js"></script>
			<script src="../../assets/js/scripts.bundle.js"></script>

		</div>
	);
}
