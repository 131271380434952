import React, {useContext} from 'react'
import Layout from './Layout/Layout';
import { AuthContext } from "../App";
import { useHistory } from "react-router-dom";

export default function DashboardComp() {
	const {state} = useContext(AuthContext);
	const history = useHistory();
	// alert(state.isAuth)
	if(!state.isAuth){

		history.push('/login')
	}

	return (
		<div>
			<Layout/>
		</div>
	)
}
