export default function ValidateProduct(data) {
	let out = {
		validate: true,
		message: ""
	}

	if(data.user_inventory_item_id === "" || data.user_inventory_item_id === null ){
		out = {
			validate: false,
			message: "Please choose channel"
		}
		return out
	}

	if(data.inventory_category_id === "" || data.inventory_category_id === null ){
		
		out = {
			validate: false,
			message: "Please choose category"
		}
		return out
	}


	if(data.product === "" || data.product === null ){
		out = {
			validate: false,
			message: "Product name is blank"
		}
		return out
	}

	if(data.description === "" || data.description === null ){
		out = {
			validate: false,
			message: "Product description is blank"
		}
		return out
	}

	if(data.price === 0 || data.price === "" || data.price === null ){
		out = {
			validate: false,
			message: "Please set product price"
		}
		return out
	}
	
	if(data.discount_price === 0 || data.discount_price === "" || data.discount_price === null ){
		
		data.discount_price = null
	}

	return out
	
}
