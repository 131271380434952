/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import AuthToolbar from '../Layout/AuthToolbar'

import {API} from '../../API/api';
import {Api} from '../../API/ApiServer';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import './UploadFiles.css'


import Swal from 'sweetalert2/dist/sweetalert2.js'

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import Gmap from '../Gmap/Gmap';
import {member} from '../Auth/localStorage'


function Media(props){
	return (
		<div className='img-container'>
			{(props.data.map((data,i) => (
				<Card key={i} sx={{ maxWidth: 345 }}>
					<CardMedia
						
						component="img"
						image={data.file}
					/>
					
			  </Card>
			)))}

			<Card sx={{ maxWidth: 345 }}>
				<CardMedia
					component="iframe"
					image={props.video_link}
					
				/>
			
			</Card>
			
		</div>
	)
}

function Profile(props){
	return (
		<div className="profile-container">
			{(props.data.map((data,i)=>(
				<div key={i} className="sub-item">
					<div className="profile-item-label">{data.profile_label}</div>
					<div className="profile-item-value">{data.profile_value}</div>
				</div>
			)))}
		</div>
	)
}




export default function ViewAssetContent(props) {

	const breadcumb = [
		{
			item: 'Home',
			path: '/'
		},
		{
			item: 'Asset Inventory',
			path: '/asset'
		},
		{
			item: `View Asset Inventory`,
			path: ''
		},
		
	]



	const [asset,setAsset] = useState({
		user_inventory_item_id: "",
		user_inventory_id: "",
		item_name: "",
		status: "waiting",
		ms_province_id: "",
		ms_city_id: "",
		ms_district_id: "",
		ms_village_id: "",
		lat: "",
		long: "",
		social_account: "",
		follower: "",
		social_image: "",
		video_link: "",
		multiproduct: "false",
		user_inventory: {
			user: {
				fullname: ""
			},
			inventory: {
				inventory: ""
			}
			
		},
		profiles: [],
		ms_province: {
			ms_province_name: ""
		},
		ms_city: {
			ms_city_name: ""
		},
		ms_district: {
			ms_district_name: ""
		},
		ms_village: {
			ms_village_name: ""
		}
	})
	const [itemFiles,setItemFiles] = useState([])

	useEffect(async () => {




		const requestBody = {
			user_inventory_item_id : props.id
		}

		

		const params = {
			reqBody: requestBody,
			method: API.USER_INVENTORY_ITEMS_GET.type,
			url: API.USER_INVENTORY_ITEMS_GET.url,
			access: 'auth' 
		}

		const response = await Api(params)
		if(response.status){
			setAsset(response.data)
			setItemFiles(response.data.item_files)
			console.log('data',response.data)
			// setUserInventory(response.data.user_inventory)
			
			
			
		}
	}, [])

	const handleStatus = () => {

	}

	const handleApprove = async () => {
		Swal.fire({
			title: 'Apakah yakin akan menyetujui asset ?',
			showCancelButton: true,
			confirmButtonText: 'Approve',
			showLoaderOnConfirm: true,
			allowOutsideClick: () => !Swal.isLoading()
		}).then( async (result) => {
			if(result.isConfirmed){
				await ActionStatus('validate','system validate')
			}
		})
	}

	const handleReject = async () => {
		Swal.fire({
				title: 'Apakah benar akan mereject inventory',
				input: 'text',
				inputAttributes: {
			  	autocapitalize: 'off'
			},
			showCancelButton: true,
			confirmButtonText: 'Reject',
			showLoaderOnConfirm: true,
			preConfirm: (login) => {
			 
			},
			allowOutsideClick: () => !Swal.isLoading()
		  	}).then( async(result) => {
				console.log(result)
				if (result.isConfirmed) {
					await ActionStatus('invalid',result.value)
				}
			}
		)
	}

	const ActionStatus = async (status,comment) => {
		const requestBody = {
			user_inventory_item_id : props.id,
			status: status,
			comment: comment
		}

		const params = {
			reqBody: requestBody,
			method: API.USER_INVENTORY_ITEMS_APPROVAL.type,
			url: API.USER_INVENTORY_ITEMS_APPROVAL.url,
			access: 'auth' 
		}

		const response = await Api(params)

		if(response.status){
			window.location.href = `/asset`
		}

	}

	return (
		<React.Fragment>
			<AuthToolbar 
				title= {`View Asset Inventory`}
				breadcumb = {breadcumb}
			/>

			<Box
				className="container-xxl"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
					m: 0,
					width: "100%",

					},
				}}
			>
				
				<Paper 
					elevation={3} 
					sx = {{
						p: 5
					}}
					className="col-xl-12 papper"
				>
					<div className="form-title">
						Detail asset
					</div>

					<div className="form-content">
						<Media 
							data = {itemFiles}
							video_link = {asset.video_link}
						/>

							
							
						

						

						<div className="form-detail-info">

							<div className="form-ctrl">
								<div className="info-label">Publisher</div>
								<div className="info-value">{asset.user_inventory.user.fullname}</div>
							</div>

							<div className="form-ctrl">
								<div className="info-label">Inventory name</div>
								<div className="info-value">{asset.item_name}</div>
							</div>

							<div className="form-ctrl">
								<div className="info-label">Channel</div>
								<div className="info-value">{asset.user_inventory.inventory.inventory}</div>
							</div>

							<div className="form-ctrl">
								<div className="info-label">Address</div>
								<div className="info-value">{`${asset.ms_village.ms_village_name}, kec. ${asset.ms_district.ms_district_name}, ${asset.ms_city.ms_city_name}, ${asset.ms_province.ms_province_name}`}</div>
							</div>

							<div className="form-ctrl">
								<div className="info-label">Status</div>
								<div className="info-value">{asset.status}</div>
							</div>

							<div className="form-sub-title">
								INVENTORY PROFILE
							</div>

							<Profile
								data = {asset.profiles}
							/>

							<div className="form-google-map">
								<Gmap
									lat={asset.lat}
									long={asset.long}
									marker = {asset.item_name}
								/>
							</div>
						</div>



					</div>

					<div className="form-action">
						<button type="button" className="btn btn-primary btn-space" onClick = {handleStatus}>Status</button>
						{(asset.status === 'waiting' && (member() ==='admin' || member() ==='superadmin')?(
							<React.Fragment>
								<button type="button" className="btn btn-danger btn-space" onClick = {handleReject}>Reject</button>
								<button type="button" className="btn btn-success btn-space" onClick = {handleApprove}>Approve</button>
							</React.Fragment>
						):(``))}
						
					</div>

				</Paper>
			</Box>
		</React.Fragment>
	)
}
