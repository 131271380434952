import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import "./fonts/style.css"
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import "./index.css"
// import './assets/plugins/global/plugins.bundle.css'
// import "bootstrap/dist/css/bootstrap.min.css";
// import './assets/plugins/global/plugins.bundle.js'
// import './assets/js/scripts.bundle.js'

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
