import React, { Component } from "react";

export default class My404 extends Component {
  render() {
    return (
      <div id="kt_body" className="bg-body">
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-column flex-center flex-column-fluid p-10">
            <img
              src="../../assets/media/illustrations/sigma-1/18.png"
              alt=""
              className="mw-100 mb-10 h-lg-450px"
            />
            <h1 className="fw-bold mb-10" style={{ color: "#A3A3C7" }}>
              Seems there is nothing here
            </h1>
            <a href="/" className="btn btn-primary">
              Return Home
            </a>
          </div>
        </div>
      </div>
    );
  }
}
