import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import DropdownMenu from './DropdownMenu'

export default class PublisherData extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
		}

		
		
	}

	componentDidMount() {
		// console.log(this.state)
	}


	handleUserInventory = (action,id) =>{
		// alert(inventoryID +' --- userID: '+inventoryID)
		action =(typeof action !== 'object')?action:null
		this.props.action(action,id)
		
	}
	render() {
		return (
			<React.Fragment>
				<TableRow>
					<TableCell>{this.props.data.fullname}</TableCell>
					<TableCell>{this.props.data.email}</TableCell>
					<TableCell>{this.props.data.phone_number}</TableCell>
					<TableCell><div className={(this.props.data.status==='ACTIVE' || this.props.data.status==='VERIFY EMAIL')?`active-status`:`not-active-status`}>{this.props.data.status}</div></TableCell>
					<TableCell>
					{
						this.props.data.user_inventories.map((data,i) => (
							<React.Fragment key={i}>
								<DropdownMenu
									style={{rightMargin:"10px"}}
									unix_id={'kshjk'}
									btnTitle={data.inventory.inventory }
									menuList={
										[
											{
												id:'channel',
												action:'Lihat Daftar ' + data.inventory.asset_label
											},
											
										]}
									key_id={'id'}
									key_label={'action'}
									size="small"
									onClick={(val)=>this.handleUserInventory(val,data.user_inventory_id)}
								/>
									

							</React.Fragment>
						))
					}
					</TableCell>
					<TableCell>
					<IconButton 
							aria-label="edit" 
							size="small"
							onClick={()=>this.props.onAddChannel(this.props.data.user_id)}
						>
        					<ToggleOnIcon fontSize="inherit" />
      					</IconButton>
						<IconButton 
							aria-label="edit" 
							size="small"
							onClick={()=>this.props.onEditPublisher(this.props.data.user_id)}
						>
        					<EditIcon fontSize="inherit" />
      					</IconButton>
						 
					</TableCell>

				</TableRow>

			</React.Fragment>
		)
	}
}
