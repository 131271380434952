import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import {titleCase} from '../Helper/Helper'
import ItemProfileData from './ItemProfileData'


const style = {
	marginRight: 3,
	marginBottom: 2,
	
}



export default class ChannelData extends Component {
	
	handleDeleteProfile = (id) => {
		this.props.onDeleteProfile(id)
	}
	render() {
		return (
			<React.Fragment>
				{this.props.data.map((data,i)=>(
					<Card  key={i}
						variant="outlined"
						sx={style}
					>
						{console.log(data)}
						<CardContent className="channel-container">
							<div className="channel-data">
								<div className="text-title">{data.item_name}</div>
								{(data.user_inventory.inventory.search_base === 'Villages')?(
									<div className="text-grey">
										Location: {titleCase(data.ms_village.ms_village_name)}, Kec. {data.ms_district.ms_district_name}, {data.ms_city.ms_city_name}, {data.ms_province.ms_province_name}
									</div>
									
								):(data.user_inventory.inventory.search_base === 'City')?(
									<div className="text-grey">
										Location: {data.ms_city.ms_city_name}, {data.ms_province.ms_province_name}
									</div>

								):(
									<React.Fragment>
										<div className="text-black">Soscial Account: {data.social_account}</div>
										<div className="text-grey">
											Follower: {data.follower}
										</div>
										
										<CardMedia
											component="img"
											sx = {{width: 150,height:280}}
											image={data.social_image}
											alt="Social image"
										/>
									</React.Fragment>
								)}


							</div>
							<div className="channel-profile">
								<div className="text-label">Profile:</div>
								{data.item_profiles.map((data,i)=>(
									<ItemProfileData key={i} data={data} onDelete={(value)=>this.handleDeleteProfile(value)}/>
								))}
							</div>

							<div className="channel-control">
								<Button variant="contained" sx={{width:"100%",marginBottom: 1}} onClick={()=>this.props.onEditBtn(data.user_inventory_item_id)}>Edit</Button>
								<Button variant="contained" sx={{width:"100%",marginBottom: 1}} onClick={()=>this.props.onDeleteBtn(data.user_inventory_item_id)}>Delete</Button>
								<Button variant="contained" sx={{width:"100%",marginBottom: 1}} onClick={()=>this.props.onAddProfile(data.user_inventory_item_id)}>Tambah Profile</Button>
								<Chip 
									size="small" 
									sx={{borderRadius:1,width:"100%",marginBottom: 1}} 
									color={(data.status === 'Waiting')?`warning`:(data.status==='Validate')?`success`:`error`} 
									label={data.status}
									clickable = {(data.status === 'Waiting')?true:false}
									onClick = {()=>this.props.onClickStatus(data.user_inventory_item_id,(data.status === 'Waiting')?true:false)}
									
									

								/>
								
							</div>

						</CardContent>
						
					</Card>
				))}
				
				
			</React.Fragment>
		)
	}
}
