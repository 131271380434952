
import React, { Component, useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


import './UserModal.css';

export default function UserModal2(props) {

	
	return (
		<Dialog
			fullWidth={true}
			maxWidth = 'md'
			open={props.open}
			onClose={props.onClose}
			
		>
				<DialogTitle>
					{(props.title)?props.title:`User`}
				</DialogTitle>

				<DialogContent>
					
					
					
					<FormControl fullWidth>
						<TextField type="text" 
							label="Email"
							variant="standard" 
							value={props.user.email}
							name="email"
							disabled={(props.modalMode === 'EDIT')}
							onChange={props.onChangeUser}
						/>
					</FormControl>
						

					<FormControl fullWidth>
						<TextField type="text" 
							label="Fullname"
							variant="standard" 
							value={props.user.fullname}
							onChange={props.onChangeUser}
							name="fullname"
						/>
					</FormControl>

					
					<FormControl fullWidth>
						<TextField type="tel" 
							label="Phone Number"
							variant="standard" 
							value={props.user.phone_number}
							onChange={props.onChangeUser}
							name="phone_number"
						/>
					</FormControl>
					
					{/* <FormControl fullWidth>
						<TextField type="text" 
							label="MemberType"
							variant="standard" 
							onChange={props.onChangeUser}
							value={((props.user.is_admin==='true')?`Adminstrator`:`Publisher`)}
							disabled = {true}
							name="is_admin"
						/>
					</FormControl> */}
					
					{(props.modalMode==='EDIT')?(
						<FormControl fullWidth>
							<label> Status</label>
							<Select
								value={props.user.status || ''}
								name="status"
								variant="standard"
								label="Status"
								onChange={props.onChangeUser}
							>
								<MenuItem value="ACTIVE" >ACTIVE</MenuItem>
								<MenuItem value="NOT ACTIVE">NOT ACTIVE</MenuItem>
								<MenuItem value="VERIFY EMAIL"  >VERIFY EMAIL</MenuItem>
								<MenuItem value="SUSPEND">SUSPEND</MenuItem>
							</Select>
						</FormControl>
							
					):(``)}

					<FormControl fullWidth>
						<label>Admin Group</label>
						<Select
							value={props.user.company_id || ''} 
							name="company_id"
							variant="standard"
							label="Company"
							onChange={props.onChangeUser}
						>
							{props.companies.map((dt,i)=>(
									<MenuItem key={i} value={dt.company_id} >{dt.company}</MenuItem>
								))
							}
						</Select>

					</FormControl>
					

					

					{(props.modalMode === 'NEW' || props.modalMode === 'PASSWORD')?(
						<React.Fragment>
							<FormControl fullWidth>
								<TextField type="password"
									label="Password"
									variant="standard" 
									value={props.user.password}
									name="password"
									onChange={props.onChangeUser}
								/>
							</FormControl>

							<FormControl fullWidth>
								<TextField type="password"
									label="Repeat Password"
									variant="standard" 
									value={props.user.retype_password}
									name="retype_password"
									onChange={props.onChangeUser}
								/>
							</FormControl>
						</React.Fragment>
					):(``)}
					
				</DialogContent>

				<DialogActions>
					<button type="button" className="btn btn-light" onClick= {props.closeUserModal}>Close</button>
					<button type="button" className="btn btn-primary" onClick={props.onSaveBtn}>Save changes</button>
				</DialogActions>
		</Dialog>
	)
}




class UserModal4 extends Component {

	constructor(props) {
		super(props);
		this.state = {
			admin_checked: true,
			publisher_checked: false,
			status: 'NOT ACTIVE'
		}

	}

	


	handleChangeAdmin = () => {

	}

	handleChangePublisher = () => {

	}

	handleUserStatus = () => {
		
	}

	render() {
		return (
			<React.Fragment>

				<Dialog
					fullWidth={true}
					maxWidth = 'md'
					open={this.props.open}
					onClose={this.props.onClose}
					
				>
					<DialogTitle>
						{(this.props.title)?this.props.title:`User`}fdfgdfgdfg
					</DialogTitle>
					<DialogContent>

						<FormControl fullWidth>
							<TextField type="text" 
								label="Email"
								variant="standard" 
								value={this.props.user.email}
								onChange={this.props.onChangeUser}
								name="email"
								disabled={(this.props.modalMode === 'EDIT')}
							/>
						</FormControl>

						
						<FormControl fullWidth>
							<TextField type="text" 
								label="Phone Number"
								variant="standard" 
								value={this.props.user.phone_number || ''}
								onChange={this.props.onChangeUser}
							/>
						</FormControl>

						<FormControl fullWidth>
							<TextField type="text" 
								label="MemberType"
								variant="standard" 
								value={((this.props.user.is_admin==='true')?`Adminstrator`:`Publisher`)}
								disabled = {true}
							/>
						</FormControl>
						
						{(this.props.modalMode==='EDIT')?(
							<FormControl fullWidth>
								<label> Status</label>
								<Select
									value={this.props.user.status || ''}
									onChange={this.props.onChangeUser} 
									name="status"
									variant="standard"
									label="Status"
								>
									<MenuItem value="ACTIVE" >ACTIVE</MenuItem>
									<MenuItem value="NOT ACTIVE">NOT ACTIVE</MenuItem>
									<MenuItem value="VERIFY EMAIL"  >VERIFY EMAIL</MenuItem>
									<MenuItem value="SUSPEND">SUSPEND</MenuItem>
								</Select>

							</FormControl>
								
						):``}

						<FormControl fullWidth>
							<label> Status</label>
							<Select
								value={this.props.user.company_id || ''} 
								onChange={this.props.onChangeUser} 
								name="company_id"
								variant="standard"
								label="Company"
							>
								{this.props.companies.map((dt,i)=>(
										<MenuItem key={i} value={dt.company_id} >{dt.company}</MenuItem>
									))
								}
							</Select>

						</FormControl>


						{/* <div className="mb-10">
							<label className="form-label fw-bold">Company:</label>
							<select className="form-select" value={this.props.user.company_id || ''} onChange={this.props.onChangeUser} name="company_id">
								<option value="" ></option>
								{this.props.companies.map((dt,i)=>(
										<React.Fragment key={i}>
											
											<option  value ={dt.company_id}>{dt.company}</option>
											
										</React.Fragment>
									))
								}
							</select>
							
						</div> */}
											




						{(this.props.modalMode === 'NEW' || this.props.modalMode === 'PASSWORD')?(
							<React.Fragment>
								<FormControl fullWidth>
									<TextField type="password"
										label="Password"
										variant="standard" 
										value={this.props.user.password}
										onChange={this.props.onChangeUser}
										name="password"
									/>
								</FormControl>

								<FormControl fullWidth>
									<TextField type="password"
										label="Password"
										variant="standard" 
										value={this.props.user.retype_password}
										onChange={this.props.onChangeUser}
										name="retype_password"
									/>
								</FormControl>
							</React.Fragment>
						):(``)}
					</DialogContent>
					<DialogActions>
						<button type="button" className="btn btn-light" onClick= {this.props.closeUserModal}>Close</button>
						<button type="button" className="btn btn-primary" onClick = {this.props.onSaveBtn}>Save changes</button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		)
	}
}
