import React, { Component } from 'react';
import Modal from '@mui/material/Modal';
// import { Input } from 'reactstrap';
export class CRUD extends Component {

	render() {
		return (
			<React.Fragment>
				
				<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				>
					
						<div className="modal-dialog modal-dialog-centered mw-650px">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Inventory</h5>

									
									<div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
										<span className="svg-icon svg-icon-2x"></span>
									</div>
									
								</div>
								<form className="form w-100" noValidate="noValidate" action="#" >
									<div className="modal-body">
										<div className="mb-5 fv-row fv-plugins-icon-container">
																		
											<label className="fs-5 fw-bold mb-2">Inventory</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.inventory.inventory}
												onChange={this.props.changeInventory}
												name="inventory"
												/>
										</div>
										<div className="mb-5 fv-row fv-plugins-icon-container">
											<label className="fs-5 fw-bold mb-2">Description</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.inventory.inventory_app}
												onChange={this.props.changeInventory}
												name="inventory_app"
											/>

										</div>

										<div className="mb-5 fv-row fv-plugins-icon-container">
											<label className="fs-5 fw-bold mb-2">Asset label</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.inventory.asset_label}
												onChange={this.props.changeInventory}
												name="asset_label"
											/>

										</div>

										<div className="mb-5 fv-row fv-plugins-icon-container">
											<label className="fs-5 fw-bold mb-2">Search Base</label>
											
											<select 
												name="search_base"
												className="form-select" 
												aria-label="Select base"
												onChange={this.props.changeInventory}
												value={this.props.search_base}
												defaultValue={this.props.inventory.search_base}
												
											>
												{
													this.props.search_list.map((dt,i)=>(
														<React.Fragment key={i}>
															
															<option  value ={dt}>{dt}</option>
															
														</React.Fragment>
													))
												}
											</select>

										</div>



									</div>

									<div className="modal-footer">
										<button type="button" className="btn btn-light" onClick= {this.props.closeInventoryModal}>Close</button>
										<button type="button" className="btn btn-primary" onClick = {this.props.saveInventory}>Save changes</button>
									</div>
								</form>
							</div>
						</div>





				</Modal>
			</React.Fragment>
			

			
		)
	}
}




