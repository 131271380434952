import React from 'react'

export default function ProfileData(props) {
	const handleDeleteBtn = (id) => {
		props.onDeleteProfile(id)
	}

	const handleEditBtn = (id) => {
		props.onEditProfile(id)
	}
	return (
		<div className="card shadow-sm" style={{marginBottom: 5}}>
			<div className="card-header">
				
				<h3 className="card-title">
					<span className="card-label fw-bolder text-dark">{props.data.profile} ({props.data.profile_name})</span>
					<span className="text-muted mt-1 fw-bold fs-7">{props.data.profile_desc}</span>
				</h3>
				<div className="card-toolbar">
					<button className="btn btn-icon btn-danger btn-sm btn-light" style={{marginRight:10}} onClick={()=>handleDeleteBtn(props.data.inventory_profile_id)}><i className="fas fa-minus" ></i></button>
					<button className="btn btn-icon btn-success btn-sm btn-light" onClick={()=>handleEditBtn(props.data.inventory_profile_id)}><i className="fas fa-edit"></i></button>
				</div>
			</div>
		</div>
	)
}
