import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

export default class ItemProfileData extends Component {
	render() {
		return (
			<div>
				<span className="text-grey">{this.props.data.profile_label}:</span>
				<span className="text-black"> {this.props.data.profile_value}</span>
				<IconButton aria-label="delete" onClick={()=>this.props.onDelete(this.props.data.item_profile_id)}>
        			<DeleteIcon />
      			</IconButton>
			</div>
		)
	}
}
