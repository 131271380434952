import React, { Component } from 'react';
import Modal from '@mui/material/Modal';
import UploadFile from '../Inventories/UploadFile';

export default class ChannelModal extends Component {

	state = {
		dataFile : null,
		file: null,
		validate: 'block'
	}

	handleFileChange = (file64) => {
		// if(file64===undefined) {
		// 	file64 = null
		// }

		this.setState({
			dataFile: file64
		},()=>{
			this.props.onChangeImage({
				changeImage: this.state.dataFile
			})
		})
			
		// this.props.onInventoryCategoryChange({changeIcon:file64})
	}

	
	Sosmed = () => {
		return (
			<React.Fragment>
				<div className="mb-5 fv-row fv-plugins-icon-container">
																
					<label className="fs-5 fw-bold mb-2">Social media Account</label>
					<input 
						type="text" 
						className="form-control" 
						value={this.props.data.social_account}
						onChange={this.props.onChange}
						name="social_account"
					/>

					
				</div>

				<div className="mb-5 fv-row fv-plugins-icon-container">
																
					<label className="fs-5 fw-bold mb-2">Follower</label>
					<input 
						type="number" 
						className="form-control" 
						value={this.props.data.follower}
						onChange={this.props.onChange}
						name="follower"
					/>

					
				</div>

				

				<div className="mb-5 fv-row fv-plugins-icon-container">
					<label className="fs-5 fw-bold mb-2">Social Image: (Resolution rasio: 150x280)</label><br/>
					<img style={{width: 150,height:280, marginLeft: 10}}src={(this.props.data.social_image === "" || this.props.data.social_image === null)?`../../assets/media/icons/duotune/abstract/abs038.svg`:this.props.data.social_image} alt='icon'/>
					<UploadFile 
						onFileChange={(value) => this.handleFileChange(value)}
						width = {150}
						height = {280}

					/>
				</div>
			</React.Fragment>
		)
	}

	Location = () => {
		return (
			<React.Fragment>
				<div className="mb-10">
					<label className="form-label fw-bold">Province:</label>
					<select className="form-select" value={this.props.data.ms_province_id} onChange={this.props.onChange} name="ms_province_id">
					<option  value =""></option>
						{this.props.provinces.map((dt,i)=>(
								
								<React.Fragment key={i}>
									
									<option  value ={dt.ms_province_id}>{dt.ms_province_name}</option>
									
								</React.Fragment>
							))
						}
					</select>
					
				</div>
				<div className="mb-10">
					<label className="form-label fw-bold">City:</label>
					<select className="form-select" value={this.props.data.ms_city_id} onChange={this.props.onChange} name="ms_city_id">
						<option  value =""></option>

						{this.props.cities.map((dt,i)=>(
							
							<React.Fragment key={i}>
								
								<option  value ={dt.ms_city_id}>{dt.ms_city_name}</option>
								
							</React.Fragment>
							))
						}
					</select>
					
				</div>

				{(this.props.inventory.search_base === 'Villages')?(
				<React.Fragment>
					<div className="mb-10">
						<label className="form-label fw-bold">District:</label>
						<select className="form-select" value={this.props.data.ms_district_id} onChange={this.props.onChange} name="ms_district_id">
							<option  value =""></option>

							{this.props.districts.map((dt,i)=>(
								
								<React.Fragment key={i}>
									
									<option  value ={dt.ms_district_id}>{dt.ms_district_name}</option>
									
								</React.Fragment>
								))
							}
						</select>
						
					</div>

					<div className="mb-10">
						<label className="form-label fw-bold">Village:</label>
						<select className="form-select" value={this.props.data.ms_village_id} onChange={this.props.onChange} name="ms_village_id">
							<option  value =""></option>

							{this.props.villages.map((dt,i)=>(
								
								<React.Fragment key={i}>
									
									<option  value ={dt.ms_village_id}>{dt.ms_village_name}</option>
									
								</React.Fragment>
								))
							}
						</select>

					</div>
				</React.Fragment>
				
				):(``)}


			</React.Fragment>
		)
	}
	render() {
		return (
			<React.Fragment>
				<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				>
					<div className="modal-dialog modal-dialog-centered mw-650px">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Channel </h5>

									
									<div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
										<span className="svg-icon svg-icon-2x"></span>
									</div>
									
								</div>
								<div className="form w-100">
									<div className="modal-body">
										<div className="mb-5 fv-row fv-plugins-icon-container">
																
											<label className="fs-5 fw-bold mb-2">Channel</label>
											<input 
												type="text" 
												className="form-control" 
												value={this.props.data.item_name}
												onChange={this.props.onChange}
												name="item_name"
												/>
										</div>

										{(this.props.inventory.search_base==='Social Media Account')?(
											this.Sosmed()
										):(
											this.Location()
										)}
										

										
										
										
									</div>

									<div className="modal-footer">
										<button type="button" className="btn btn-light" onClick= {this.props.closeModal}>Close</button>
										<button type="button" className="btn btn-primary" onClick = {this.props.onSaveBtn}>Save changes</button>
									</div>
								</div>
							</div>
						</div>
				</Modal>
			</React.Fragment>
		)
	}
}
