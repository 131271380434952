
export const API = {
	AUTH_LOGIN: {
		url : 'auth',
		type: 'post'
	},
	AUTH_REGISTER: {
		url : 'auth/register',
		type: 'post'
	},
	AUTH_RESET: {
		url : 'auth/forgot',
		type: 'post'
	},
	AUTH_CHANGEPASSWORD: {
		url : 'auth/newpass',
		type: 'post'
	},

	PROVINCE: {
		url : 'provinces',
		type: 'get'

	},
	CITY: {
		url : 'cities',
		type: 'get'

	},

	DISTRICT: {
		url : 'districts',
		type: 'get'

	},

	VILLAGE: {
		url : 'villages',
		type: 'get'

	},


	INVENTORIES_USER: {
		url : 'admin/inventories/user',
		type: 'get'

	},

	

	INVENTORIES_GET_ALL: {
		url : 'admin/inventories/gets',
		type: 'get'
	},
	INVENTORIES_GETS: {
		url : 'admin/inventories',
		type: 'get'
	},
	INVENTORIES_GET: {
		url : 'admin/inventories/get',
		type: 'get'
	},
	INVENTORIES_DELETE: {
		url : 'admin/inventories',
		type: 'delete'
	},

	INVENTORIES_CREATE: {
		url : 'admin/inventories',
		type: 'post'
	},
	INVENTORIES_UPDATE: {
		url : 'admin/inventories',
		type: 'put'
	},
	INVETORY_CATEGORIES_CREATE: {
		url : 'admin/inventory_categories',
		type: 'post'
	},
	INVETORY_CATEGORIES_UPDATE: {
		url : 'admin/inventory_categories',
		type: 'put'
	},
	INVETORY_CATEGORIES_DELETE: {
		url : 'admin/inventory_categories',
		type: 'delete'
	},
	INVETORY_CATEGORIES_GET: {
		url : 'admin/inventory_categories/get',
		type: 'get'
	},
	
	INVETORY_PROFILE_CREATE: {
		url : 'admin/inventory_profiles',
		type: 'post'
	},
	INVETORY_PROFILE_UPDATE: {
		url : 'admin/inventory_profiles',
		type: 'put'
	},
	INVETORY_PROFILE_DELETE: {
		url : 'admin/inventory_profiles',
		type: 'delete'
	},
	INVETORY_PROFILE_GET: {
		url : 'admin/inventory_profiles/get',
		type: 'get'
	},

	INVETORY_PROFILE_ALL: {
		url : 'admin/inventory_profiles/all',
		type: 'get'
	},

	USER_GETS: {
		url : 'admin/users',
		type: 'get'
	},
	USER_PUBLISHER: {
		url : 'admin/users/publisher',
		type: 'get'
	},

	USER_GET: {
		url : 'admin/users/get',
		type: 'get'
	},
	USER_CREATE: {
		url : 'admin/users',
		type: 'post'
	},
	USER_UPDATE: {
		url : 'admin/users',
		type: 'put'
	},
	USER_GROUP: {
		url : 'admin/users/group',
		type: 'post'
	},
	COMPANY_GET_ALL: {
		url : 'admin/companies/all',
		type: 'get'
	},

	COMPANY_GETS: {
		url : 'admin/companies',
		type: 'get'
	},
	COMPANY_GET: {
		url : 'admin/companies/get',
		type: 'get'
	},
	COMPANY_CREATE: {
		url : 'admin/companies',
		type: 'post'
	},
	COMPANY_UPDATE: {
		url : 'admin/companies',
		type: 'put'
	},
	COMPANY_DELETE: {
		url : 'admin/companies',
		type: 'delete'
	},

	PARTNER_GET_ALL: {
		url : 'admin/partners/all',
		type: 'get'
	},

	PARTNER_GETS: {
		url : 'admin/partners',
		type: 'get'
	},
	PARTNER_GET: {
		url : 'admin/partners/get',
		type: 'get'
	},
	PARTNER_CREATE: {
		url : 'admin/partners',
		type: 'post'
	},
	PARTNER_UPDATE: {
		url : 'admin/partners',
		type: 'put'
	},
	PARTNER_DELETE: {
		url : 'admin/partners',
		type: 'delete'
	},

	USER_INVENTORY_GETS: {
		url : 'admin/user_inventories',
		type: 'get'
	},

	USER_INVENTORY_GET: {
		url : 'admin/user_inventories/get',
		type: 'get'
	},

	USER_INVENTORY_GET_ALL: {
		url : 'admin/user_inventories/all',
		type: 'get'
	},
	USER_INVENTORY_CREATE: {
		url : 'admin/user_inventories',
		type: 'post'
	},
	USER_INVENTORY_DELETE: {
		url : 'admin/user_inventories',
		type: 'delete'
	},

	USER_INVENTORY_ITEMS_GETS: {
		url : 'admin/user_inventory_items',
		type: 'get'
	},

	USER_INVENTORY_ITEMS_ALL: {
		url : 'admin/user_inventory_items/all',
		type: 'get'
	},

	USER_INVENTORY_ITEMS_GET: {
		url : 'admin/user_inventory_items/get',
		type: 'get'
	},
	USER_INVENTORY_ITEMS_CREATE: {
		url : 'admin/user_inventory_items',
		type: 'post'
	},
	USER_INVENTORY_ITEMS_UPDATE: {
		url : 'admin/user_inventory_items',
		type: 'put'
	},
	USER_INVENTORY_ITEMS_DELETE: {
		url : 'admin/user_inventory_items',
		type: 'delete'
	},
	USER_INVENTORY_ITEMS_APPROVAL: {
		url : 'admin/user_inventory_items/approval',
		type: 'put'
	},

	USER_PRODUCT_GETS: {
		url : 'admin/user_products',
		type: 'get'
	},

	USER_PRODUCT_GET_ALL: {
		url : 'admin/user_products/all',
		type: 'get'
	},
	
	USER_PRODUCT_GET: {
		url : 'admin/user_products/get',
		type: 'get'
	},

	USER_PRODUCT_CREATE: {
		url : 'admin/user_products',
		type: 'post'
	},

	USER_PRODUCT_UPDATE: {
		url : 'admin/user_products',
		type: 'put'
	},

	USER_PRODUCT_FILE_CREATE: {
		url : 'admin/user_product/files',
		type: 'post'
	},
	USER_PRODUCT_FILE_UPDATE: {
		url : 'admin/user_product/files',
		type: 'put'
	},

	ITEM_PROFILE_CREATE: {
		url : 'admin/item_profile',
		type: 'post'
	},

	ITEM_PROFILE_DELETE: {
		url : 'admin/item_profile',
		type: 'delete'
	},
	PRICEBOOKS: {
		url : 'admin/pricebook',
		type: 'get'
	},

	PRICEBOOK_CREATE: {
		url : 'admin/pricebook',
		type: 'post'
	},
	
	PRICEBOOK_DELETE: {
		url : 'admin/pricebook',
		type: 'delete'
	},

	CAMPAIGN_GET_ALL: {
		url: 'admin/campaigns',
		type: 'get'
	},

	CAMPAIGN_GET: {
		url: 'admin/campaigns/get',
		type: 'get'
	}
	

	

}

