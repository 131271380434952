import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import "./Product.css";
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterMoment';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import ProductFile from './ProductFile';
import ProductSetting from './ProductSetting';





export default class ProductModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			number: '',
			user_inventory_id: '',
			prod: "",
			inventory_categories: [],
			user_product: {
				user_product_id: '',
				user_inventory_item_id: null,
				inventory_category_id: '',
				product : '',
				description: '',
				price: 0,
				discount_price: 0,
				status: 'ACTIVE',
				url_video: ''
				
			},
			

			user_inventory_items : [],
			inventories: [],
			user_product_files: [
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},
				{
					user_product_file_id: '',
					user_product_id: '',
					file: '',
					type: 'IMAGE',
					file_desc: '',
					main: '0',
					image_change: null
				},

			],
			user_product_setting : {
				user_product_setting_id: '',
				user_product_id: '',
				unit: '',
				unit_desc: '',
				customer_need_schedule: 'false',
				schedule_type: 'Daily',
				customer_need_upload_file: 'true',
				content_create_by: 'customer',
				content_file_type: 'video',
				content_file_duration: '0',
				video_hres: '',
				video_vres: ''
			},
			expired_date: ""
		}
		// console.log('this.props.inventories',)
		// console.log(this.props.inventories)

	}

	componentDidMount(){
		// console.log('componentDidMount')
		// console.log('this.state.user_product_setting')
		// console.log(this.state.user_product_setting)
		// console.log('this.state.user_product_setting !== this.props.setting')
		// console.log(this.state.user_product_setting !== this.props.setting)

	}
	componentDidUpdate(prevProps){
		
		if(prevProps.inventories !== this.props.inventories){
			this.setState({
				inventories: this.props.inventories,
				inventory_id:this.props.inventories[0].inventory_id,
				user_inventory_items:this.props.inventories[0].user_inventory_items
			},()=>{
				// console.log('this.state.inventories')
				// console.log(this.state.inventories)
			})
		}

		if(prevProps.user_product !== this.props.data){
			console.log('prevProps.user_product !== this.props.data')
			this.setState({
				user_product: this.props.data
			},()=>{
				console.log('this.state.user_product')
				console.log(this.state.user_product)
			})
		}

	}


	handleDateChange = (value) => {
		console.log(value)
		this.setState({
			expired_date: value
		})
	}

	handleInventoryChange = (event) => {
		this.setState({
			number: event.target.value,
			user_inventory_items: this.state.inventories[event.target.value].user_inventory_items,
			inventory_categories: this.state.inventories[event.target.value].inventory.inventory_categories

		},()=>{
			// console.log('inventory_categories')
			// console.log(this.state.inventory_categories)
		})
	}

	handleItemChange = (event) => {
		// console.log(event)
		const a = this.state.user_product
		a[event.target.name] = event.target.value
		this.setState({
			user_product: a
		},()=>{
			// console.log('this.state.user_product')
			// console.log(this.state.user_product)

			this.props.onChangeProduct(this.state.user_product)
		})
	}

	handleInventoryCategoryChange = (event) => {
		const a = this.state.user_product
		a[event.target.name] = event.target.value
		this.setState({
			user_product : a
		})
	}

	handleStatus = (event) => {
		const a = this.state.user_product
		a['status'] = (event.target.checked)?'ACTIVE':'NOT ACTIVE'

		this.setState({
			user_product: a
		})
	}

	handleImageChange = (value) => {
		

		this.setState({
			user_product_files: value
		})
		this.props.onFileChange(this.state.user_product_files)
		
	}

	handleDate = (date) => {
		// console.log(date)
		const a = this.state.user_product
		a['expired_date'] = date.format('YYYY-MM-DD')
		this.setState({
			user_product: a
		},()=>{
			console.log('this.state.user_product on modal')
			console.log(this.state.user_product)
			this.props.onChangeProduct(this.state.user_product)
		})

		
	}

	render() {

		return (
			<Dialog
				fullWidth={true}
				maxWidth = 'lg'
				open={this.props.open}
				
			>
				<DialogTitle>Product#{this.props.user_product.user_product_id}</DialogTitle>
				<DialogContent >
					<Container >
						<Box sx={{ bgcolor: '#e8f3f9', padding: 1, marginTop: 1, marginBottom: 1, borderRadius: 1 }}>
							{(this.props.actionMode==='NEW')?(
								<React.Fragment>
									<div className='input-label'>Channel</div>
									<FormControl sx={{ m: 0, minWidth: 120 }}>
										<Select
											value={this.state.number}
											onChange={this.handleInventoryChange}
											variant="standard"
										>
											{this.state.inventories.map((data,i)=>(
												<MenuItem key={i} value={i}>{data.inventory.inventory}</MenuItem>
											))}
											
										</Select>
									</FormControl>

									<FormControl sx={{ m: 0, minWidth: 120 }}>
										<Select
											defaultValue = ''
											value={this.state.user_product.user_inventory_item_id}
											onChange={this.handleItemChange}
											name="user_inventory_item_id"
											variant="standard"
										>
											{this.state.user_inventory_items.map((data,i)=>(
												<MenuItem key={i} value={data.user_inventory_item_id}>{data.item_name}</MenuItem>
											))}
										</Select>
									</FormControl>
									
									<div className='input-label'>Category</div>

									<FormControl sx={{ m: 0, minWidth: 120 }}>
										<Select
											defaultValue = ''
											value={this.state.user_product.inventory_category_id}
											onChange={this.handleInventoryCategoryChange}
											name="inventory_category_id"
											variant="standard"
										>
											{this.state.inventory_categories.map((data,i)=>(
												<MenuItem key={data.inventory_category_id} value={data.inventory_category_id}>{data.category}</MenuItem>
											))}
										</Select>
									</FormControl>
								</React.Fragment>
							):(
								<React.Fragment>
									
								</React.Fragment>
							)}

							<FormControl fullWidth>
								<TextField type="text" 
									label="Product name"
									variant="standard" 
									name="product"
									value={this.state.user_product.product}
									onChange={this.handleItemChange}
								/>

							</FormControl>

							<FormControl fullWidth>
							<TextareaAutosize
								aria-label="empty textarea"
								placeholder="Description"
								name="description"
								style={{ marginTop: "10px",marginBottom: "10px",backgroundColor: '#e8f3f9' }}
								minRows={3}
								value={this.state.user_product.description}
								onChange={this.handleItemChange}

							/>
							</FormControl>
							
							<FormControl sx={{ width: '25ch' }} variant="outlined">
							
								<TextField
									label="Price"
									type="number"
									name="price"
									value={this.state.user_product.price}
									variant="standard"
									onChange={this.handleItemChange}
									
								/>
							</FormControl>

							<FormControl sx={{ width: '25ch' }} variant="outlined">
							
								<TextField
									label="Discount Price"
									type="number"
									name="discount_price"
									value={this.state.user_product.discount_price}
									variant="standard"
									onChange={this.handleItemChange}
									
								/>
							</FormControl>

							
							
							<FormControl fullWidth>
								<TextField 
									label="Video Product URL" 
									variant="standard" 
									name="url_video"
									value={this.state.user_product.url_video}
									onChange={this.handleItemChange}
								/>
							</FormControl>

							<FormControl>
								<FormControlLabel 
									control={
										<Switch 
											checked={(this.state.user_product.status === 'ACTIVE')?true:false}
											onChange={this.handleStatus}
										/>
									} 
									label="Active" />
							</FormControl>
						</Box>
					</Container>

					<ProductFile 
						data={this.props.files}
						onChange={(value)=>this.handleImageChange(value)
					}/>
					<ProductSetting 
						data={this.props.setting}
						onChange= {(value)=>this.props.onSettingChange(value)}
					/>
					
				</DialogContent>

				<DialogActions>
					<Button  variant="outlined" onClick={this.props.onSave}>Save</Button>
					<Button onClick={this.props.onClose}>Close</Button>
				</DialogActions>

			</Dialog>
		)
	}
}
