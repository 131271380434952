import React, { Component } from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export default class DropdownMenu extends Component {

	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
		}
	}

	open = () =>{
		// console.log()
		return Boolean(this.state.anchorEl)
	}

	handleClick = (event) => {
		// console.log(event.currentTarget)
		this.setState({
			anchorEl: event.currentTarget
		},()=>{
			// console.log(Boolean(this.state.anchorEl))
			
		})
	}

	handleClose = (val) =>{
		this.setState({
			anchorEl: null
		},()=>{
			this.props.onClick(val)
		})
	}

	render() {
		return (
			<div style={{marginRight: "2px",display:"inline"}}>
				<Button 
					
					aria-controls={this.props.unix_id}
					onClick={this.handleClick}
					size={this.props.size}
					variant="contained"
				>
					{this.props.btnTitle} <ArrowDropDownIcon />
				</Button> 

				<Menu
					id={this.props.unix_id}
					anchorEl={this.state.anchorEl}
					open={Boolean(this.state.anchorEl)}
					onClose={this.handleClose}
					MenuListProps={{
					'aria-labelledby': 'basic-button',
					}}
				>
					{this.props.menuList.map((inv)=>(
						<MenuItem key={inv[this.props.key_id]} onClick={()=>this.handleClose(inv[this.props.key_id])}>{inv[this.props.key_label]}</MenuItem>
					))}
					
					
				</Menu>

			</div>
		)
	}
}
