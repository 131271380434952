import React, { useState, useEffect  } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Select from 'react-select';
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.js';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Pagination from '@mui/material/Pagination';
import '../Pagination/Pagination.css';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';



async function getCompany(url) {
	try {
		const response = await axios.get(url);
	  	return response.data
	} catch (error) {
		Swal.fire({
			icon: 'error',
			text: error.message,
		})
		
	  	console.error(error);
	}
}



export default function PricebookForm(props) {
	const [option,setOption] = useState(props.partners)
	const [partner,setPartner] = useState(null)
	const [companies, setCompanies] = useState([])
	const [company,setCompany] = useState("")
	const [selectedCompany, setSelectedCompany] = useState(null)
	const [search,setSearch] = useState("")
	const [btnDisabled,setBtnDisabled] = useState(true)
	const [pageCount,setPageCount] = useState(1)
	const [page,setPage] = useState(1)
	const [pricebook,setPricebook] = useState({
		user_product_pricebook_id: '',
		user_product_id: '',
		partner_id: '',
		partner_company_id: '',
		partner_company_name: '',
		price: '',
		status: 'ACTIVE'
	})

	
	const handleInputChange = (value) => {
		setSearch({ value });
	};

	
	
	const handlePartnerChange = (value) => {
		setPartner(value)
		setPricebook({
			...pricebook,
			partner_id: value.partner_id
		})
	}

	const handleCompanyChange = (event) => {
		setCompany(event.target.value)
	}

	const handleSearchCompany = async () =>{
		
		const url = `${partner.company_search}=${company}&page=${page}&limit=5`;

		const result = await getCompany(url)
		if(result.status){
			console.log('result',result)
			setPageCount(result.last_page)
			setCompanies(result.data)
		}
	}

	const handlePaginationChange = (event,value) => {
		setPage(value)
	}

	const handleOnSelectCompany = (id,name) => {
		setPricebook({
			...pricebook,
			partner_company_id: id,
			partner_company_name: name
		})
	}

	const handlePricebookChange = (event) => {
		setPricebook({
			...pricebook,
			price: event.target.value
		})
	}

	const handleCreatePricebook = () => {

		if(pricebook.partner_id === "" || pricebook.partner_id === null){
			Swal.fire({
				icon: 'error',
				text: "Mohon di isi partner ",
			})

			return false
		}

		if(pricebook.partner_company_name === "" || pricebook.partner_company_name === null){
			Swal.fire({
				icon: 'error',
				text: "Mohon di pilih perusahaan yang akan di atur harga nya",
			})

			return false
		}

		if(pricebook.price === "" || pricebook.price === null){
			Swal.fire({
				icon: 'error',
				text: "Mohon di isi harga ",
			})

			return false
		}

		props.onCreate(pricebook)


	}

	useEffect(()=> {
		setOption(props.partners)

		setPricebook({
			...pricebook,
			user_product_id: props.product.user_product_id
		})
	},[])

	useEffect(()=>{
		console.log('pricebook',pricebook)
	},[pricebook])
	useEffect(()=>{
		if(partner !== null){
			handleSearchCompany();
		}
		
	},[page])

	useEffect(()=>{
		
		if(partner === null){
			setBtnDisabled(true)
		}else{
			console.log(partner)

			if(partner.company_search === null || partner.company_search === ''){
				setBtnDisabled(true)
			}else{
				setBtnDisabled(false)
			}

			
		}
		
	},[partner])

	useEffect(() => {
		console.log(search)
	},[search])

	useEffect(() => {
		console.log(company)
	},[company])

	return (
		<Dialog
			fullWidth={true}
			maxWidth = 'md'
			open={props.open}
				
		>


			<DialogTitle>PENGATURAN HARGA</DialogTitle>
			<DialogContent sx={{minHeight:200}}>

				<FormControl fullWidth>
					<Select
						options={props.partners}
						onChange = {handlePartnerChange}
					/>

				</FormControl>

				<FormControl fullWidth>
					<TextField type="text" 
						label="Company Name"
						variant="standard" 
						name="partner_company_name"
						value={company}
						onChange={handleCompanyChange}
					/>

					{/* <input type="text" name="" id=""value={this.state.va} /> */}
				</FormControl>
				<FormControl fullWidth>
					<Button
						onClick={handleSearchCompany}
					>
						Search Company
					</Button>
				</FormControl>

				<React.Fragment>
					{(companies.length>0)?(
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>#CompanyID</TableCell>
										<TableCell align="left">#Company Name</TableCell>
										<TableCell align="left">Action</TableCell>
										
									</TableRow>
								</TableHead>
								<TableBody>
								</TableBody>
								{companies.map((data,i)=>(
									<TableRow key={i}>
										<TableCell>{data.company_id}</TableCell>
										<TableCell>{data.company_name}</TableCell>
										<TableCell>
											<Button variant="contained" onClick={()=>handleOnSelectCompany(data.company_id,data.company_name)}>SELECT</Button>

										</TableCell>
										
									</TableRow>

								))}
							</Table>
							<Pagination sx={{marginTop:1,marginBottom:1}} count={pageCount}  variant="outlined" shape="rounded" onChange={handlePaginationChange} />
						</TableContainer>


						
					):(
						<React.Fragment>
							No Company
						</React.Fragment>
					)}
				</React.Fragment>

				<FormControl fullWidth sx={{marginTop:2}}>
					<TextField type="text" 
						label="Company Selected"
						variant="standard" 
						name="partner_company_name"
						value={pricebook.partner_company_name}
						
					/>

				</FormControl>

				<FormControl fullWidth sx={{marginTop:2}}>
					<TextField type="number" 
						label="Custome Price"
						variant="standard" 
						name="price"
						value={pricebook.price}
						onChange={handlePricebookChange}
						
					/>

				</FormControl>


			</DialogContent>
			<DialogActions>
				<Button  variant="contained" disabled={btnDisabled} onClick={handleCreatePricebook}>Create</Button>
				<Button  variant="contained" onClick={props.onClose}>Cancel</Button>
			</DialogActions>

		</Dialog>
	)
}
