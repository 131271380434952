import React from 'react'
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import IconButton from '@mui/material/IconButton';
import PreviewIcon from '@mui/icons-material/Preview';
import Tooltip from '@mui/material/Tooltip';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

export default function ProductsData(props) {
	return (
		<TableRow>
			<TableCell>
				<div className="cell-title">{props.data.product}</div>
				<div className="cell-desc">{props.data.user_inventory_item.item_name}</div>
				
				
			</TableCell>
			<TableCell sx={{textAlign:"right"}}>
				<div className="cell-normal">{Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(props.data.price)}</div>
			</TableCell>
			<TableCell>
			<div className="cell-normal">{props.data.user_inventory_item.user_inventory.user.fullname}</div>
				
			</TableCell>
			<TableCell>
				<div className="cell-normal">
					{props.data.user_inventory_item.user_inventory.inventory.inventory}
				</div>
			</TableCell>

			<TableCell>
				<div className="cell-normal">
					{props.data.inventory_category.category}
				</div>
			</TableCell>
			

			<TableCell>
				{props.editButton?(
					<Tooltip title="Edit">
						<IconButton 
							aria-label="edit" 
							size="small"
							onClick={()=> window.location.href=`products/edit/${props.data.user_product_id}`}
						>
					
							<EditIcon fontSize="inherit" />
						</IconButton>
					</Tooltip>
				):(``)}
				
				{props.viewButton?(
					<Tooltip title="Detail">
						<IconButton 
							aria-label="view" 
							size="small"
							onClick={()=>props.onViewItem(props.data.user_product_id)}
						>
							<PreviewIcon fontSize="inherit" />
						</IconButton>
					</Tooltip>
				):(``)}
				
				{props.createCampaign?(
					<Tooltip title="Buat Campaign">
						<IconButton 
							aria-label="Create" 
							size="small"
							onClick={()=>props.onCreateCampaign(props.data.user_product_id)}
						>
							<NoteAddIcon fontSize="inherit" />
						</IconButton>
					</Tooltip>
				):(``)}
				

			</TableCell>

		</TableRow>
	)
}
