import React from 'react'
import {userFullname} from '../Auth/localStorage'
import {member} from '../Auth/localStorage'

export default function AuthHeaderUser() {
	return (
		<div className="d-flex align-items-stretch flex-shrink-0">
			<div className="topbar d-flex align-items-stretch flex-shrink-0">
				{ /* <!--begin::User--> */}
				<div className="d-flex align-items-center me-n3 ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
					{ /* <!--begin::Menu wrapper--> */}
					<div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
						<img className="h-30px w-30px rounded" src="../../assets/media/avatars/150-25.jpg" alt="" />
					</div>
					{ /* <!--begin::Menu--> */}
					<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
						{ /* <!--begin::Menu item--> */}
						<div className="menu-item px-3">
							<div className="menu-content d-flex align-items-center px-3">
								{ /* <!--begin::Avatar--> */}
								<div className="symbol symbol-50px me-5">
									<img alt="Logo" src="../../assets/media/avatars/150-25.jpg" />
								</div>
								{ /* <!--end::Avatar--> */}
								{ /* <!--begin::Username--> */}
								<div className="d-flex flex-column">
									<div className="fw-bolder d-flex align-items-center fs-5">{userFullname()}
									<span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">{member()}</span></div>
									
								</div>
								{ /* <!--end::Username--> */}
							</div>
						</div>
						{ /* <!--end::Menu item--> */}
						{ /* <!--begin::Menu separator--> */}
						<div className="separator my-2"></div>
						{ /* <!--end::Menu separator--> */}
						{ /* <!--begin::Menu item--> */}
						<div className="menu-item px-5">
							<a href="/myprofile" className="menu-link px-5">My Profile</a>
						</div>
						{ /* <!--end::Menu item--> */}
						
						{ /* <!--begin::Menu separator--> */}
						<div className="separator my-2"></div>
						{ /* <!--end::Menu separator--> */}
						
						{ /* <!--begin::Menu item--> */}
						<div className="menu-item px-5">
							<a href="/logout" className="menu-link px-5">Sign Out</a>
						</div>
						{ /* <!--end::Menu item--> */}
						
					</div>
					{ /* <!--end::Menu--> */}
					{ /* <!--end::Menu wrapper--> */}
				</div>
				{ /* <!--end::User --> */}
			</div>
			{ /* <!--end::Toolbar wrapper--> */}
		</div>
	)
}
