import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {titleCase} from '../Helper/Helper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';



function imageProduct (data) {
	let imgUrl
	const m = data.find(x => x.main === "1")
	if(m){
		imgUrl = m.file
	}else{
		imgUrl = data[0].file

	}

	return imgUrl
}

function infoProduct(data){
	if(data.user_inventory_item.user_inventory.inventory.search_base === "Villages"){
		return [{
			label: "Location",
			value: `${titleCase(data.user_inventory_item.ms_village.ms_village_name)}, Kec. ${data.user_inventory_item.ms_district.ms_district_name}, ${data.user_inventory_item.ms_city.ms_city_name}, ${data.user_inventory_item.ms_province.ms_province_name}`,
			type: "label"
		}]
	}else if(data.user_inventory_item.user_inventory.inventory.search_base === "City"){
		return {
			label: "Location",
			value: `${data.user_inventory_item.ms_city.ms_city_name}, ${data.user_inventory_item.ms_province.ms_province_name}`,
			type: "label"
		}
	}else {
		return [{
			label: "Follower",
			value: data.user_inventory_item.ms_city.ms_city_name,
			type: "label"
		},{
			label: "Account",
			value: data.user_inventory_item.social_account,
			type: "label"
		},{
			label: "Social Image",
			value: data.user_inventory_item.social_image,
			type: "image",
			
		}]
	}
}

export default class ProductData extends Component {

	

	render() {
		return (
			<React.Fragment>
				{this.props.data.map((data,i)=>(
					<Card 
						key={i}
						sx={{
							paddingTop: 2,
							paddingLeft: 2,
							paddingBottom: 2,
							paddingRight: 2
						}}
					>
						<div className="product-container">
							<div className="product-image">
								<CardMedia
									component="img"
									height="194"
									width="100"
									image={(data.user_product_files.length == 0)?(``):(imageProduct(data.user_product_files))}
									alt="Product"
								/>
									
							</div>
							<div className="product-data">
								<div className="text-grey">{data.user_inventory_item.user_inventory.inventory.inventory}</div>
								<div className="text-grey">{data.user_inventory_item.item_name}</div>
								<div className="text-grey">{data.inventory_category.category}</div>
								<div className="text-grey">{data.user_inventory_item.user_inventory.inventory.inventory_category}</div>
								<div className="text-title">{data.product}</div>
								<div className="text-grey">{data.description}</div>
								<span className="text-grey">Standard price: </span>
								<span className="text-black"> {data.price.toLocaleString('id-ID', {style: 'currency',currency: 'IDR', minimumFractionDigits: 0,maximumFractionDigits: 0,})}</span>
								
								{ infoProduct(data).map((data,i)=>(
									(data.type === "label")?(
										<React.Fragment key={i}>
											<div>
											<span className="text-grey">{data.label}: </span>
											<span className="text-grey">{data.value}: </span>
											</div>
										</React.Fragment>
									):(
										<React.Fragment key={i}>
											
										</React.Fragment>
									)
								))}

								<FormGroup>
									<FormControlLabel 
										control={
											<Switch 
												defaultChecked={(data.status === 'ACTIVE')?true:false} 
												onChange={()=>this.props.onChangeStatus(data.user_product_id,this.checked)}
													
											/>
										} 
										label="Active" />
									
								</FormGroup>

							</div>
							<div className="contol">
								<Button variant="contained" sx={{width:"100%",marginBottom: 1}} onClick={()=>this.props.onEdit(data.user_product_id)}>Edit</Button>
								<Button variant="contained" sx={{width:"100%",marginBottom: 1}} onClick={()=>this.props.onView(data.user_product_id)}>View</Button>
								<Button variant="contained" sx={{width:"100%",marginBottom: 1}}>Price Management</Button>
							</div>

						</div>


					</Card>
				))}
			</React.Fragment>
		
		)
	}
}
